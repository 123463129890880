import React, { useState, useEffect, useContext } from "react";
import { useWallet } from "../hooks/useWallet";
import AppContext from "../context/AppContex";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { resolve } from "dns";

export default function ModalSubscriptions(props) {
  const [balance, setBalance] = useState("0");
  const [balanceRLC, setBalanceRLC] = useState("0");
  const [loading, setLoading] = useState(false);
  const [signerMethod, setSignerMethod] = useState("");
  const [packageSelect, setPackageSelect] = useState("");
  const [collpase, setCollapse] = useState("");
  const [packages, setPackages] = useState(
    props.packages ? props.packages : []
  );
  const [addressAuthor, setAddressAuthor] = useState(
    props.addressAuthor ? props.addressAuthor : []
  );

  const { account, user, keyAddress, message, postMessage } = useWallet();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const {
    getBalanceRLC,
    getBalance,
    approvedRLCSubscriptions,
    paymentSubscription,
  } = useContext(AppContext);

  useEffect(() => {
    getBalanceWallet();
  }, [account]);

  const getBalanceWallet = async () => {
    if (account !== "") {
      let balanceRLCTemp = await getBalanceRLC(account);
      let balanceMaticTemp = await getBalance(account);
      setBalance(balanceMaticTemp);
      setBalanceRLC(balanceRLCTemp);
    }
  };

  useEffect(() => {
    if (signerMethod === "Subscribe" && keyAddress) {
      console.log("Subscribe");
      haddleApproved(); //Status 0
      return;
    }
  }, [message]);

  const signerManual = (type) => {
    console.log(type);
    console.log(balanceRLC);
    console.log(balance);
    if (
      type.money === "RLC" &&
      parseFloat(type.price) > parseFloat(balanceRLC)
    ) {
      toast.error("Insufficient balance. 😶", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }
    if (
      type.money === "MATIC" &&
      parseFloat(type.price) > parseFloat(balance)
    ) {
      toast.error("Insufficient balance. 😶", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }
    setSignerMethod("Subscribe");
    setPackageSelect(type);
    postMessage("signBrowser");
  };

  const haddleApproved = async () => {
    const id = toast.loading("Approval in progress.  🤖");
    setLoading(true);
    await approvedRLCSubscriptions(account, keyAddress, packageSelect.price)
      .then((response) => {
        toast.update(id, {
          render: "Approval completed. ✅🙂 " + response.transactionHash,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        haddleBuy();
      })
      .catch((error) => {
        console.log(error);
        toast.update(id, {
          render: "Transaction failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 50000);
        setLoading(false);
      });
  };

  const haddleBuy = async () => {
    var trx = "";
    setLoading(true);
    const id = toast.loading("Subscribing. 😀");
    console.log(account);
    console.log(keyAddress);
    console.log(addressAuthor);
    console.log(packageSelect?.price);
    console.log(packageSelect?.money);
    trx = await paymentSubscription(
      account,
      keyAddress,
      addressAuthor,
      packageSelect?.price,
      packageSelect?.money
    )
      .then((response) => {
        // Activity List
        localStorage.removeItem("keyAddress");
        setSignerMethod("");
        console.log("transaccion completed");
        console.log(response);
        toast.update(id, {
          render: "You're now subscribed! 😎" + response.transactionHash,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setLoading(false);
        addSubscription(response.transactionHash);
        props?.handleClose();
      })
      .catch((error) => {
        console.log(error);
        toast.update(id, {
          render: "Transaction failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        /*const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);*/
        setLoading(false);
      });
  };

  const addSubscription = async (data) => {
    let formData = new FormData();
    formData.append("trx", data);
    formData.append("subscriptions_users", packageSelect?.market_owner_id);
    formData.append("network", process.env.REACT_APP_NETWORK_TYPE);
    formData.append("type", packageSelect?.plan);
    formData.append("date_subscriptions", "2022-10-30 15:47:11");
    await fetch(`${process.env.REACT_APP_API_BACKEND}/register-subscriptions`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div
        className={`modal fade show`}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Subscription Packages
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <hr className="solid" />
                <div className="row">
                  <div className="col-md-6">
                    <p>Balance MATIC</p>
                  </div>
                  <div className="col-md-6">
                    <p>{`${balance.slice(0, 20)}`}</p>
                  </div>
                </div>
                <hr className="solid" />
                <div className="row">
                  <div className="col-md-6">
                    <p>Balance RLC</p>
                  </div>
                  <div className="col-md-6">
                    <p>{`${balanceRLC.slice(0, 20)}`}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="accordion" id="accordionExample">
                  {packages.length > 0 &&
                    packages?.map((item, index) => (
                      <>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className={`${
                                collpase === item.plan ? "" : "collapsed"
                              } accordion-button`}
                              type="button"
                              onClick={() =>
                                setCollapse(
                                  collpase === item.plan ? "" : item.plan
                                )
                              }
                            >
                              {item.plan} - {item.price} {item.money}
                            </button>
                          </h2>
                          <div
                            className={`${
                              collpase === item.plan ? "show" : ""
                            } accordion-collapse collapse`}
                            style={{
                              display:
                                collpase === item.plan ? "block" : "none",
                            }}
                          >
                            <div class="accordion-body">
                              {item.description}
                              <button
                                type="button"
                                className="btn btn-secondary mt-2 mb-2 d-flex"
                                onClick={() => signerManual(item)}
                                key={index}
                              >
                                Buy Package
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
