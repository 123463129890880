import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import avatarDefault from "../assets/img/nft/vendor/avatar-sm.png";
import { toast } from "react-toastify";
import { useWallet } from "../hooks/useWallet";
export default function ModalFollows(props) {
  const { user } = useWallet();
  const [loading, setLoading] = useState(false);
  const [followState, setFollowState] = useState(false);
  const [follow, setfollow] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getFollows();
  }, [props?.data?.id]);

  const getFollows = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/followall/${props?.data?.id}`
    );
    url.searchParams.append("followers", props?.data?.type);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setfollow(response.follow);
        console.log(response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const haddleFollow = async (idFollow) => {
    const id = toast.loading("Please wait...");
    var dataForm = new FormData();
    dataForm.append("market_follow_id", idFollow);
    dataForm.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${process.env.REACT_APP_API_BACKEND}/follow`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        toast.update(id, {
          render: "Following! 😎",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        getFollows();
      })
      .catch((error) => {
        toast.update(id, {
          render: "Something went wrong try again ",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.log(error);
      });
  };

  const haddleUnfollow = async (idFollow) => {
    const id = toast.loading("Please wait...");
    var dataForm = new FormData();
    dataForm.append("market_follow_id", idFollow);
    dataForm.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${process.env.REACT_APP_API_BACKEND}/unfollow`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        toast.update(id, {
          render: "Unfollowed! 👽",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setFollowState(false);
        getFollows();
      })
      .catch((error) => {
        toast.update(id, {
          render: "Something went wrong try again ",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.log(error);
      });
  };

  const haddleRemove = async (idFollow) => {
    const id = toast.loading("Please wait...");
    var dataForm = new FormData();
    dataForm.append("market_follow_id", idFollow);
    dataForm.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${process.env.REACT_APP_API_BACKEND}/remove-follow`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        toast.update(id, {
          render: "Follower removed! ✅🙃",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setFollowState(false);
        getFollows();
        props?.handleClose();
      })
      .catch((error) => {
        toast.update(id, {
          render: "Something went wrong try again ",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.log(error);
      });
  };

  const URLAuthor = (id) => {
    props?.handleClose();
    navigate(`/author/${id}`);
  };
  return (
    <>
      <div
        className={`modal fade show `}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {props?.data?.type ? props?.data?.type : "Follow"}
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <ul className="list-group">
                    {follow.length > 0 && (
                      <>
                        {follow.map((item, index) => (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            <div className="d-inline-flex align-items-center position-relative">
                              <img
                                className="rounded-circle me-2"
                                width="32"
                                src={
                                  item?.owner?.avatar
                                    ? item?.owner?.avatar
                                    : avatarDefault
                                }
                                alt={item?.owner?.name}
                              />
                              <div className="fs-sm">
                                <span
                                  role="button"
                                  className="text-primary"
                                  onClick={() => URLAuthor(item?.owner?.id)}
                                >
                                  @{item?.owner?.username}
                                </span>
                                {user?.id === props?.data?.id &&
                                  props?.data?.type === "Followers" && (
                                    <>
                                      {" "}
                                      •{" "}
                                      <span
                                        role="button"
                                        className="text-accent"
                                        onClick={() =>
                                          item?.follow === true
                                            ? haddleUnfollow(item?.owner?.id)
                                            : haddleFollow(item?.owner?.id)
                                        }
                                      >
                                        {item?.follow === true
                                          ? "Unfollow"
                                          : "Follow"}
                                      </span>
                                    </>
                                  )}
                              </div>
                            </div>
                            {user?.id === props?.data?.id &&
                              props?.data?.type === "Following" && (
                                <button
                                  className={`btn me-2 ${
                                    item?.follow === true
                                      ? "btn-outline-danger"
                                      : "btn-outline-accent"
                                  } `}
                                  onClick={() =>
                                    item?.follow === true
                                      ? haddleUnfollow(item?.owner?.id)
                                      : haddleFollow(item?.owner?.id)
                                  }
                                >
                                  {item?.follow === true
                                    ? "Unfollow"
                                    : "Follow"}
                                </button>
                              )}
                            {user?.id !== props?.data?.id && (
                              <button
                                className={`btn me-2 ${
                                  item?.follow === true
                                    ? "btn-outline-danger"
                                    : "btn-outline-accent"
                                } `}
                                onClick={() =>
                                  item?.follow === true
                                    ? haddleUnfollow(item?.owner?.id)
                                    : haddleFollow(item?.owner?.id)
                                }
                              >
                                {item?.follow === true ? "Unfollow" : "Follow"}
                              </button>
                            )}
                            {user?.id === props?.data?.id &&
                              props?.data?.type === "Followers" && (
                                <button
                                  className={`btn me-2 btn-outline-accent `}
                                  onClick={() => haddleRemove(item?.owner?.id)}
                                >
                                  Remove
                                </button>
                              )}
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
