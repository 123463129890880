import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import { useWallet } from "../../hooks/useWallet";
import SidebarMenuAccount from "../../components/SidebarMenuAccount";
import HeaderMenuAccount from "../../components/HeaderMenuAccount";

export default function MySubscriptions() {
  const [tab, setTab] = useState("All");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsSold, setItemsSold] = useState([]);
  const [listToken, setListToken] = useState([]);
  const [activeSlide, setactiveSlide] = useState(false);
  const { hasNextPage, error, loadMore } = {};
  const { account, user } = useWallet();

  const getAllUsers = async () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/authors-subscriptions`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setItemsSold(response.data.items);
        setactiveSlide(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [user]);

  return (
    <>
      <main className="page-wrapper">
        <HeaderMenuAccount />
        <div className="container mb-5 pb-3">
          <div className="bg-light shadow-lg rounded-3 overflow-hidden">
            <div className="row">
              <SidebarMenuAccount />
              <section className="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                  <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-4">
                    <h1 className="h3 mb-sm-0 mb-2 pb-1 text-sm-start text-center">
                      My Subscriptions
                    </h1>
                  </div>
                  <div className="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 gx-3 mb-4">
                    {itemsSold.length > 0 &&
                      itemsSold?.map((item, index) => (
                        <div className="col mb-2" key={index}>
                          <article className="card h-100 border-0 shadow">
                            <div className="card-img-top position-relative overflow-hidden">
                              <Link
                                className="d-block"
                                to={`/author/${item.owner?.id}`}
                              >
                                <img
                                  src={
                                    item?.owner?.avatar
                                      ? item.owner?.avatar
                                      : avatarDefault
                                  }
                                  alt={item?.owner?.username}
                                />
                              </Link>
                            </div>
                            <div className="card-body">
                              <h3 className="product-title mb-2 fs-base">
                                <Link
                                  className="d-block"
                                  to={`/author/${item?.owner?.id}`}
                                >
                                  {item?.owner?.username}
                                </Link>
                              </h3>
                              <span className="fs-sm text-muted">Redliner</span>
                            </div>
                            <div className="card-footer mt-n1 py-0 border-0">
                              <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                <img
                                  className="me-2 rounded-circle"
                                  src={
                                    item?.owner?.avatar
                                      ? item?.owner?.avatar
                                      : avatarDefault
                                  }
                                  width="32"
                                  alt={item?.owner?.username}
                                />
                                <Link
                                  className="nav-link-style fs-sm stretched-link"
                                  to={`/author/${item?.owner?.id}`}
                                >
                                  @{item?.owner?.username}
                                </Link>
                              </div>
                            </div>
                          </article>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="row g-4 mb-4 pb-3"></div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
