import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
export default function ModalDisclaimer(props) {
  return (
    <>
      <div
        className={`modal fade show `}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ width: "370px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Sell
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                You're about to mint your NFT on the Polygon Public Ledger. Once
                you click "Yes" you can still modify the price, but you may not
                edit any other fields. Would you like to "Sell" now?
              </p>
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <button
                    className="btn btn-outline-accent w-100 mb-2"
                    onClick={() => props?.handleSigner()}
                  >
                    Yes
                  </button>
                </div>
                <div className="col-md-10 offset-md-1">
                  <button
                    className="btn btn-outline-secondary w-100"
                    onClick={() => props?.handleClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
