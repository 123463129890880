import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import { useWallet } from "../../hooks/useWallet";
import SidebarMenuAccount from "../../components/SidebarMenuAccount";
import HeaderMenuAccount from "../../components/HeaderMenuAccount";
import { tns } from "tiny-slider";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Daily from "../../assets/img/nft/packages/daily.jpg";
import Weekly from "../../assets/img/nft/packages/weekly.jpg";
import Monthly from "../../assets/img/nft/packages/monthly.jpg";
import Annual from "../../assets/img/nft/packages/annual.jpg";
import Vip from "../../assets/img/nft/packages/vip.jpg";
import ModalDelete from "../../components/ModalDelete";

export default function MyPackages() {
  const [tab, setTab] = useState("All");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsSold, setItemsSold] = useState([]);
  const [listToken, setListToken] = useState([]);
  const [activeSlide, setactiveSlide] = useState(false);
  const [showModalDelete, setshowModalDelete] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({
    type: "post",
    id: null,
  });
  const { hasNextPage, error, loadMore } = {};
  const { account, user } = useWallet();

  useEffect(() => {
    getMyPlan();
  }, [user]);

  const getMyPlan = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/my-plan-subscriptions/${user?.id}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data);
        setListToken(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const imagePlan = (id) => {
    let image = "";
    if (id === "Daily") {
      image = Daily;
    } else if (id === "Weekly") {
      image = Weekly;
    } else if (id === "Monthly") {
      image = Monthly;
    } else if (id === "Annual") {
      image = Annual;
    } else if (id === "VIP") {
      image = Vip;
    }
    return <img src={image} alt={id} />;
  };
  const handleDeletedContent = async () => {
    setLoading(true);
    if (deleteInfo.id === null) {
      return;
    }
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/plan-delete/${deleteInfo.id}`
    );
    fetch(`${url}`, {
      method: "delete",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setLoading(false);
        getMyPlan();
        setshowModalDelete(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const showDelete = (id) => {
    setDeleteInfo({
      type: "Plan",
      id: id,
    });
    setshowModalDelete(true);
  };

  const closeDelete = (id) => {
    setshowModalDelete(false);
  };
  return (
    <>
      {showModalDelete && (
        <ModalDelete
          show={showModalDelete}
          handleClose={closeDelete}
          handleDelete={handleDeletedContent}
          data={deleteInfo}
        />
      )}
      <main className="page-wrapper">
        <HeaderMenuAccount />
        <div className="container mb-5 pb-3">
          <div className="bg-light shadow-lg rounded-3 overflow-hidden">
            <div className="row">
              <SidebarMenuAccount />
              <section className="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                  <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-4">
                    <h1 className="h3 mb-sm-0 mb-2 pb-1 text-sm-start text-center">
                      My Subscription Packages
                    </h1>
                  </div>
                  <div className="d-flex flex-sm-row flex-column mb-4">
                    <Link className="btn btn-accent" to={"/feed/setting"}>
                      Create new Subscription
                    </Link>
                  </div>

                  <div className="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 gx-3 mb-4">
                    {listToken.length > 0 &&
                      listToken?.map((item, index) => (
                        <div className="col mb-2" key={index}>
                          <article className="card h-100 border-0 shadow">
                            <div className="card-img-top position-relative overflow-hidden">
                              {imagePlan(item?.plan)}
                            </div>
                            <div className="d-flex my-2 justify-content-end">
                              <button
                                className="btn-wishlist btn-sm position-absolute top-0 end-0"
                                type="button"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Deleted"
                                onClick={() => showDelete(item.id)}
                                style={{ margin: "12px" }}
                              >
                                <i
                                  className="ci-trash"
                                  style={{ color: "red" }}
                                ></i>
                              </button>
                              <Link
                                className="d-block text-truncate"
                                to={`/feed/setting?plan=${item.plan}`}
                              >
                                <button
                                  className="btn-wishlist btn-sm position-absolute top-0 "
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  title="Deleted"
                                  style={{ margin: "12px", right: "40px" }}
                                >
                                  <i
                                    className="ci-edit"
                                    style={{ color: "red" }}
                                  ></i>
                                </button>{" "}
                              </Link>
                            </div>
                            <div className="card-body">
                              <small>Edit</small>
                              <h3 className="product-title mb-2 fs-base">
                                <Link
                                  className="d-block text-truncate"
                                  to={`/feed/setting?plan=${item.plan}`}
                                >
                                  {item?.plan} - {item?.price} {item?.money} -{" "}
                                </Link>
                              </h3>
                            </div>
                          </article>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
