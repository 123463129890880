import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useWallet } from "../../hooks/useWallet";
import moment from "moment";

export default function NotificationsAll() {
  const { user } = useWallet();
  const [listNotifications, setListNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      getNotifications();
    }
  }, [user]);

  const getNotifications = () => {
    setLoading(true);
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/notifications/`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("limit", 1000);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setListNotifications(response?.data?.items);
        setTotalNotifications(response?.data?.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const changeNotifications = (id, link) => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/notifications/${id}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        getNotifications();
        navigate(link);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <main className="page-wrapper">
        <div className="bg-accent mb-4 py-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/feed">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    Notifications
                  </li>
                </ol>
              </nav>
            </div>
            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
              <h1 className="h3 text-light mb-0">Notifications</h1>
            </div>
          </div>
        </div>
        <section className="container mb-5 pt-3 pb-md-5 pb-1">
          <div className="row gy-5">
            <aside className="col-lg-3 col-md-4 position-relative"></aside>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <h2>Notifications</h2>
              <div className="mt-2  card border-0 shadow ">
                {listNotifications.length > 0 &&
                  listNotifications?.map((item, index) => (
                    <div className="col-md-12" key={index}>
                      <div
                        role="button"
                        className="dropdown-item align-items-center"
                        key={index}
                        onClick={() =>
                          changeNotifications(item?.id, item?.link)
                        }
                      >
                        <div className="d-inline-flex align-items-center">
                          <div>
                            <i
                              className={`${
                                item.view === "0"
                                  ? "colorPrimary"
                                  : "opacity-60"
                              }  me-2 `}
                            >
                              ●
                            </i>
                          </div>
                          <div>
                            <p className="nomargin">{item?.title}</p>
                            <p className="nomargin">{item?.description}</p>
                            <p className="nomargin">
                              {moment(item?.created).calendar()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <aside className="col-lg-3 col-md-4 position-relative"></aside>
          </div>
        </section>
      </main>
    </>
  );
}
