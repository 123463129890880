import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import AppContext from "./context/AppContex";
import useInitialState from "./hooks/useInitialState";
import Home from "./pages/Home";
import Setting from "./pages/Account/Settings";
import Create from "./pages/NFT/Create";
import Edit from "./pages/NFT/Edit";
import View from "./pages/NFT/View";
import ApprovedAll from "./pages/Account/ApprovedAll";
import Myitems from "./pages/Account/Myitems";
import Explorer from "./pages/Explorer/Explorer";
import CollectionExplorer from "./pages/Explorer/CollectionExplorer";
import Author from "./pages/Explorer/Author";
import FeedCreate from "./pages/Feed/FeedCreate";
import FeedSetting from "./pages/Feed/FeedSetting";
import FeedEdit from "./pages/Feed/FeedEdit";
import Feed from "./pages/Feed/Feed";
import Page from "./pages/Page";
import Collection from "./pages/NFT/Collection";
import CollectionEdit from "./pages/NFT/CollectionEdit";
import MyCollection from "./pages/Account/MyCollection";
import MySubscriptions from "./pages/Account/MySubscriptions";
import MyPackages from "./pages/Account/MyPackages";
import MyNotifications from "./pages/Account/MyNotifications";
import NotificationsAll from "./pages/Account/NotificationsAll";
import TermsAndConditions from "./pages/Pages/TermsAndConditions";
import PolicyAndPrivacy from "./pages/Pages/PolicyAndPrivacy";
import Myfavorite from "./pages/Account/MyFavorite";
import Support from "./pages/Pages/Support";
import FAQ from "./pages/Pages/FAQ";
import { AuthInterceptor } from "./AuthInterceptor";

import ScrollToTop from "./components/ScrollToTop";
function App() {
  const initialState = useInitialState();

  useEffect(() => {
    AuthInterceptor();
  }, []);

  return (
    <AppContext.Provider value={initialState}>
      <BrowserRouter>
        <Layout>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="explorer" element={<Explorer />} />
            <Route
              path="/explorer/collection/:slug"
              element={<CollectionExplorer />}
            />
            <Route
              path="account"
              element={
                <PrivateRoute>
                  <Setting />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/account/setting"
              element={
                <PrivateRoute>
                  <Setting />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/account/items"
              element={
                <PrivateRoute>
                  <Myitems />
                </PrivateRoute>
              }
            ></Route>

            <Route
              path="/account/collection/:tokenId"
              element={
                <PrivateRoute>
                  <CollectionEdit />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/account/collection"
              element={
                <PrivateRoute>
                  <MyCollection />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/account/subscriptions"
              element={
                <PrivateRoute>
                  <MySubscriptions />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/account/packages"
              element={
                <PrivateRoute>
                  <MyPackages />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/account/approved"
              element={
                <PrivateRoute>
                  <ApprovedAll />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/account/favorites"
              element={
                <PrivateRoute>
                  <Myfavorite />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/account/notifications"
              element={
                <PrivateRoute>
                  <MyNotifications />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/feed"
              element={
                <PrivateRoute>
                  <Feed />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/feed/notifications"
              element={
                <PrivateRoute>
                  <NotificationsAll />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/feed/setting"
              element={
                <PrivateRoute>
                  <FeedSetting />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/feed/create"
              element={
                <PrivateRoute>
                  <FeedCreate />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/feed/edit/:postId"
              element={
                <PrivateRoute>
                  <FeedEdit />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/nft/create"
              element={
                <PrivateRoute>
                  <Create />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/nft/edit/:tokenId"
              element={
                <PrivateRoute>
                  <Edit />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/nft/collection"
              element={
                <PrivateRoute>
                  <Collection />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/nft/view/:contract/:tokenId"
              element={<View />}
            ></Route>
            <Route path="/author/:authorId" element={<Author />}></Route>
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="policy-and-privacy" element={<PolicyAndPrivacy />} />
            <Route path="support" element={<Support />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="404" element={<Page />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </AppContext.Provider>
  );
}
function PrivateRoute({ children }: any) {
  const [state, setState] = useState("loading");
  useEffect(() => {
    (async function () {
      try {
        const accountAddress = window.localStorage.getItem("user");
        setState(accountAddress ? "loggedin" : "redirect");
      } catch {
        setState("redirect");
      }
    })();
  }, []);
  if (state === "loading") {
    return <div>Loading..</div>;
  }
  if (state === "redirect") {
    return <Navigate to="/" />;
  }
  return children;
}

export default App;
