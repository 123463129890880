import React, { useState, useEffect, useContext } from "react";
export default function Countdown(props) {
  const [show, setShow] = useState(true);
  const [textShow, setTextShow] = useState("Comming Soon");
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hour, setHour] = useState(0);
  const [days, setDays] = useState(0);
  const countDownDateStart = new Date(props?.dateStart).getTime();
  const countDownDateEnd = new Date(props?.dateEnd).getTime();

  useEffect(function () {
    const myInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDateEnd - now;
      setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHour(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(
    function () {
      const now = new Date().getTime();
      if (countDownDateStart > now) {
        setShow(false);
        setTextShow("Comming Soon");
      }
      if (countDownDateEnd < now) {
        setShow(false);
        setTextShow("Finalized");
      }
    },
    [countDownDateStart]
  );

  return (
    <>
      {props.model === "minimalist" ? (
        <div className="badge bg-dark m-3 fs-sm position-absolute top-0 start-0 zindex-5">
          <i className="ci-time me-1"></i>
          {show ? (
            <div
              className="countdown d-inline"
              data-countdown={props?.dateStart}
            >
              <span className="countdown-hours mb-0 me-0">
                <span className="countdown-value">{days}</span>
                <span className="countdown-label fs-lg">:</span>
              </span>
              <span className="countdown-hours mb-0 me-0">
                <span className="countdown-value">{hour}</span>
                <span className="countdown-label fs-lg">:</span>
              </span>
              <span className="countdown-minutes mb-0 me-0">
                <span className="countdown-value">{minutes}</span>
                <span className="countdown-label fs-lg">:</span>
              </span>
              <span className="countdown-seconds mb-0 me-0">
                <span className="countdown-value">{seconds}</span>
              </span>
            </div>
          ) : (
            <div className="countdown d-inline">{textShow}</div>
          )}
        </div>
      ) : (
        <div className="countdown h6 mb-0" data-countdown={props?.dateEnd}>
          {show ? (
            <>
              <div className="countdown-days bg-dark text-light rounded p-2">
                <span className="countdown-value">{days}</span>
              </div>
              <div className="countdown-hours bg-dark text-light rounded p-2">
                <span className="countdown-value">{hour}</span>
              </div>
              <div className="countdown-minutes bg-dark text-light rounded p-2">
                <span className="countdown-value">{minutes}</span>
              </div>
              <div className="countdown-seconds bg-dark text-light rounded p-2">
                <span className="countdown-value">{seconds}</span>
              </div>
            </>
          ) : (
            <div>{textShow}</div>
          )}
        </div>
      )}
    </>
  );
}
