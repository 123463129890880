import React from "react";

export default function ShareButton(props) {
  const share = async () => {
    const shareData = {
      title: props?.name ? props?.name : "RedLine - MarketPlace",
      url: window.location.href,
    };
    await navigator.share(shareData);
  };

  return (
    <>
      <button
        className="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2"
        type="button"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Share"
        onClick={() => share()}
      >
        <i className="ci-share-alt"></i>
      </button>
    </>
  );
}
