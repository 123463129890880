import React, { useState, useEffect } from "react";
import avatarDefault from "../assets/img/nft/vendor/avatar-sm.png";
import { Link, useParams } from "react-router-dom";
export default function ShowOwnerToken(props) {
  return (
    <>
      {props.owners?.length > 1 ? (
        <>
          <img
            className="me-2 rounded-circle"
            src={avatarDefault}
            width="32"
            alt="Avatar RedLine"
          />
          has {props.owners?.length} owners
        </>
      ) : (
        <>
          <img
            className="me-2 rounded-circle"
            src={
              props?.owners[0]?.avatar
                ? props?.owners[0]?.avatar
                : avatarDefault
            }
            width="32"
            alt={props?.owners[0]?.username}
          />
          <Link
            className="nav-link-style fs-sm stretched-link"
            to={`/author/${props?.owners[0]?.id}`}
          >
            @{props?.owners[0]?.username}
          </Link>
        </>
      )}
    </>
  );
}
