import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import avatarDefaultTwo from "../../assets/img/nft/vendor/avatar.png";
import avatarDefaultSquare from "../../assets/img/nft/vendor/avatar-square.jpg";
import { useWallet } from "../../hooks/useWallet";
import AppContext from "../../context/AppContex";
import { ToastContainer, toast } from "react-toastify";
import { useLocalStorage } from "../../hooks/useLocalState";
import "react-toastify/dist/ReactToastify.css";
import { useForm, useFieldArray } from "react-hook-form";

export default function Create() {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [typeFiles, setTypeFiles] = useState(undefined);
  const [listCollection, setListCollection] = useState([]);
  const [tab, setSelectTab] = useState(1);
  const [activeSlide] = useState(true);
  const [loading, setLoading] = useState(false);
  const [collectionRefresh, setCollectionRefresh] = useState(false);
  const [commercialUse, setCommercialUse] = useState(false);
  const [earningsMore, setEarningsMore] = useState(false);
  const [typeNFT, setTypeNFT] = useState("721");
  const { account, user, signin } = useWallet();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { fields, append, remove, } = useFieldArray({
    control,
    name: "metadata",
  });
  const watchAllFields = watch();

  useEffect(() => {
    if (user) {
      console.log(user);
      console.log("NFT " + user.approvedNFT);
      console.log("RLC " + user.approvedRLC);
      if (user.approvedNFT !== "1") {
        // window.location.replace("/account/approved");
      }
      getCollectionsUser();
    }
  }, [user]);

  const getCollectionsUser = () => {
    setLoading(true);
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/collection`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setListCollection(response.data.items);
        console.log(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const selectFile = async (event) => {
    setSelectedFiles(event.target.files[0]);
    setTypeFiles(event.target.files[0].type);
  };

  const onSubmit = async (data) => {
    console.log(data);
    if (!selectedFiles) {
      toast.error("Please select a file");
      return;
    }
    setLoading(true);
    const id = toast.loading("Creating NFT. 🤖");
    let uploadFile = new FormData();
    uploadFile.append("folder", "nft");
    uploadFile.append("address", account);
    uploadFile.append("file", selectedFiles);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/s3-upload`, {
      method: "POST",
      body: uploadFile,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Image uploaded to S3", response);
        return response;
      })
      .then((response) => {
        var formData = new FormData();
        formData.append("address", account);
        formData.append("multimedia", response.data);
        formData.append("type_multimedia", typeFiles);
        formData.append("category", data.category);
        formData.append("collection", data.collection);
        formData.append("name", data.name);
        formData.append("type", tab);
        formData.append("description", data.description);
        formData.append("royalties", data.royalties);
        formData.append("standard", data.supply > 1 ? "ERC-1155" : "ERC-721");
        formData.append("supply", data.supply);
        formData.append("supplyMax", data.supply);
        formData.append("commercialUse", commercialUse ? 1 : 0);
        if (tab === 1) {
          formData.append("price_fixed", data.price_fixed);
          formData.append("money", data.type_money_1);
        } else if (tab === 2) {
          formData.append("bid_starting_date", data.bid_starting_date);
          formData.append("bid_expiration_date", data.bid_expiration_date);
          formData.append("price_minium_bid", data.price_minimum_bid);
          formData.append("money", data.type_money_2);
        } else if (tab === 3) {
          formData.append(
            "price_membership_month",
            data.price_membership_month
          );
          formData.append(
            "payment_other_address",
            data.payment_other_address_membership ? 1 : 0
          );
          formData.append("payout_address", data.payout_address_membership);
        }
        formData.append("metadata", JSON.stringify(data.metadata));
        formData.append("network", process.env.REACT_APP_NETWORK_TYPE);
        fetch(`${process.env.REACT_APP_API_BACKEND}/ipfs`, {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            toast.update(id, {
              render: "NFT created! 😍",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            window.location.replace(
              `/nft/view/${process.env.REACT_APP_CONTRACT_COLLECTION}/${response.response}`
            );
          });
      })
      .catch((error) => {
        setLoading(false);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const refreshCollections = () => {
    toast.success("Updated collection! 😀");
    getCollectionsUser();
  }

  const changeTypeNFT = (erc) => {
    if (erc === "721") {
      setTypeNFT("721")
      setValue('supply', 1);
    } else {
      setTypeNFT("1155")
    }
  }

  const handlePriceChange = (e) => {
    let value = e.target.value;
    if (/^0\d+/.test(value)) {
      value = value.replace(/^0(\d+)/, '0.$1');
    }
    if (!isNaN(value) || value === '') {
      setValue('price_fixed', value);
    }
  };
  return (
    <>
      <main className="page-wrapper">
        <div className="bg-accent mb-4 py-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/feed">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-nowrap">
                    <Link to={"/explorer"}>NFT Marketplace</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    Create New NFT
                  </li>
                </ol>
              </nav>
            </div>
            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
              <h1 className="h3 text-light mb-0">Create New NFT</h1>
            </div>
          </div>
        </div>

        <section className="container mb-5 pt-3 pb-md-5 pb-1">
          <div className="row gy-5">
            <form
              className="needs-validation col-md-8"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <h2 className="h5 mb-2 pb-1">Upload file</h2>
                <p className="mb-4 fs-sm">
                  File types supported: PNG, JPG, GIF, WEBP, MP3 or MP4. Max
                  size: 100mb.
                </p>

                <div className="file-drop-area">
                  {selectedFiles ? (
                    <>
                      <img
                        src={URL.createObjectURL(selectedFiles)}
                        alt="Preview"
                        className="img-thumbnail"
                        width={200}
                        height={200}
                        style={{ display: "block", margin: "auto" }}
                      />
                    </>
                  ) : (
                    <>
                      <div className="file-drop-icon ci-cloud-upload"></div>
                      <span className="file-drop-message">
                        Drag and drop here to upload
                      </span>
                    </>
                  )}
                  <input
                    className="file-drop-input"
                    type="file"
                    onChange={selectFile}
                    id="file-upload"
                    accept="image/png, image/jpeg, image/gif, video/mp4, video/*, audio/* "
                    disabled={loading}
                  />

                  <label
                    htmlFor="file-upload"
                    className="file-drop-btn btn btn-outline-accent"
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    ) : (
                      <i className="ci-loading me-2"></i>
                    )}
                    Or select file
                  </label>
                </div>
              </div>

              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <h2 className="h5 mb-3">Details</h2>
                <div className="row gy-md-4 gy-3">
                  <div className="col-12">
                    <label className="form-label" htmlFor="details-title">
                      Title<span className="ms-1 text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="details-title"
                      type="Text"
                      placeholder="Item title"
                      {...register("name", { required: true })}
                      required
                    />
                    <div className="invalid-feedback">
                      Please, enter the title.
                    </div>
                    <span className="form-text">
                      Maximum 100 characters. No HTML or emoji allowed.
                    </span>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label" htmlFor="details-category">
                      Category
                    </label>
                    <select
                      className="form-select"
                      id="details-category"
                      defaultValue={"DEFAULT"}
                      {...register("category", { required: true })}
                    >
                      <option disabled>Choose category</option>

                      <option value="Art">Art</option>
                      <option value="Photography">Photography</option>
                      <option value="Music">Music</option>
                      <option value="Gaming">Gaming</option>
                      <option value="Sports">Sports</option>
                      <option value="Collections">Collections</option>
                      <option value="Utility">Utility</option>
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label" htmlFor="details-collection">
                      Add to collection{" "}
                      {!collectionRefresh ? (
                        <Link
                          to={"/nft/collection"}
                          target="_blank"
                          onClick={() => setCollectionRefresh(true)}
                        >
                          <span className="paragraph">
                            <ins>
                              New collection <i className="ci-add me-2"></i>{" "}
                            </ins>
                          </span>
                        </Link>
                      ) : (
                        <span
                          className="paragraph"
                          onClick={() => refreshCollections()}
                        >
                          <ins>
                            Refresh Collection <i className="ci-add me-2"></i>{" "}
                          </ins>
                        </span>
                      )}
                    </label>
                    <select
                      className="form-select"
                      id="details-collection"
                      defaultValue={"DEFAULT"}
                      {...register("collection", { required: true })}
                    >
                      <option disabled value="0">
                        Choose collection
                      </option>
                      <option value="0">Undefined</option>
                      {listCollection.length > 0 &&
                        listCollection?.map((item, index) => (
                          <option value={item?.id} key={index}>
                            {item?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label" htmlFor="details-collection">
                      Type NFT
                    </label>
                    <select
                      className="form-select"
                      id="details-collection"
                      defaultValue={"721"}
                      onChange={(e) => changeTypeNFT(e.target.value)}
                    >
                      <option value="721">ERC-721</option>
                      <option value="1155">ERC-1155</option>
                    </select>
                  </div>
                  <div className="col-6">
                    <label className="form-label" htmlFor="details-title">
                      Supply
                    </label>
                    <input
                      className="form-control"
                      id="details-title"
                      type="number"
                      defaultValue={1}
                      min={0}
                      max={10000}
                      placeholder="Supply"
                      {...register("supply")}
                      disabled={typeNFT === "721"}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label" htmlFor="details-title">
                      Creator earnings
                      <small
                        role="button"
                        className="ms-1 text-accent"
                        onClick={() => setEarningsMore(!earningsMore)}
                      >
                        More information
                      </small>
                    </label>
                    <div class="input-group">
                      <input
                        class="form-control"
                        id="price-fix-value"
                        type="number"
                        placeholder="Enter percentage"
                        defaultValue={0}
                        min={0}
                        max={10}
                        {...register("royalties")}
                      />
                      <div class="dropdown" data-bs-toggle="select">
                        <button
                          class="btn btn-outline-secondary rounded-0 rounded-end"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span class="dropdown-toggle-label">%</span>
                        </button>
                      </div>
                    </div>
                    {earningsMore && (
                      <span>
                        Earn a percentage of the sale price every time one of
                        your items is sold (Royalty).
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <label className="form-label" htmlFor="details-description">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="details-description"
                      rows="4"
                      placeholder="Enter a short description of your item"
                      {...register("description")}
                    ></textarea>
                    {/* <span className="form-text">0 of 500 characters used.</span> */}
                  </div>
                  <div class="col-sm-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        id="price-fix-wallet"
                        type="checkbox"
                        onChange={() => setCommercialUse(!commercialUse)}
                      />
                      <label
                        class="form-check-label fs-base"
                        for="price-fix-wallet"
                      >
                        <span class="me-2">Commercial Use</span>
                      </label>
                    </div>
                    {commercialUse && (
                      <small>
                        You can sell your NFT and still continue to License it
                        for commercial use, as long as you haven’t transferred
                        your copyright. The NFT collector is buying the
                        verified, authenticated artwork, while the commercial
                        client would be paying to use your image for their own
                        purposes, such as advertising and marketing materials.
                        Check the box if you are transferring your commercial
                        rights.
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <h2 className="h5 mb-4 pb-md-2">Price &amp; payment options</h2>
                <div className="mb-4" style={{ overflowX: "auto" }}>
                  <div
                    className="nav nav-tabs d-inline-flex ms-n1 mb-0 border-0 flex-nowrap text-nowrap"
                    role="tablist"
                  >
                    <div
                      className={`btn btn-outline-accent  position-relative ms-1 me-2 mb-2 ${tab === 1 ? "active" : ""
                        } `}
                      data-bs-target="#price-fix"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <i className="ci-lable me-2"></i>Fixed price
                      <label
                        className="position-absolute top-0 start-0 w-100 h-100"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          className="visually-hidden"
                          type="radio"
                          name="price"
                          onClick={() => setSelectTab(1)}
                        />
                      </label>
                    </div>
                    {/* 
                    <div
                      className={`btn btn-outline-accent  position-relative ms-1 me-2 mb-2 ${tab === 2 ? "active" : ""
                        } `}
                      data-bs-target="#price-open"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <i className="ci-auction me-2"></i>Open for bids
                      <label
                        className="position-absolute top-0 start-0 w-100 h-100"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          className="visually-hidden"
                          type="radio"
                          name="price"
                          onClick={() => setSelectTab(2)}
                          disabled
                        />
                      </label>
                    </div>
                   
                    <div
                      className={`btn btn-outline-accent position-relative ms-1 me-2 mb-2 ${
                        tab === 3 ? "active" : ""
                      } `}
                      data-bs-target="#price-time"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <i className="ci-user me-2"></i>Membership
                      <label
                        className="position-absolute top-0 start-0 w-100 h-100"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          className="visually-hidden"
                          type="radio"
                          name="price"
                          onClick={() => setSelectTab(3)}
                        />
                      </label>
                    </div>
                    */}
                  </div>
                </div>

                <div className="tab-content">
                  <div
                    className={`tab-pane fade mb-4 pb-2 ${tab === 1 ? "show active" : ""
                      } `}
                    id="price-fix"
                    role="tabpanel"
                  >
                    <div className="row gy-md-4 gy-3 mb-md-5 mb-4 pb-md-0 pb-2">
                      <div className="col-sm-6">
                        <label className="form-label" htmlFor="price-fix-value">
                          Price
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            id="price-fix-value"
                            type="text"
                            placeholder="Enter price"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            {...register("price_fixed")}
                            onChange={handlePriceChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label"
                          htmlFor="price-fix-payment"
                        >
                          Preferred payment type
                        </label>
                        <select
                          className="form-select"
                          id="price-fix-payment"
                          defaultValue={{ label: "MATIC", value: "MATIC" }}
                          {...register("type_money_1")}
                        >
                          <option>MATIC</option>
                          <option>RLC</option>
                          {/*<option value="USDC">USDC</option> */}
                        </select>
                      </div>
                      <div className="col-sm-12">
                        <label
                          className="form-label"
                          htmlFor="price-fix-payment"
                        >
                          <strong>Fees</strong>
                        </label>
                        <p>
                          Services Fee{" "}
                          {watchAllFields?.type_money_1 === "RLC"
                            ? "0.75"
                            : "1"}
                          % <i className="ci-copy ms-3 text-accent"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade mb-4 pb-2 ${tab === 2 ? "show active" : ""
                      } `}
                    id="price-open"
                    role="tabpanel"
                  >
                    <div className="row gy-md-4 gy-3 mb-md-5 mb-4 pb-md-0 pb-2">
                      <div className="col-6">
                        <label className="form-label" htmlFor="price-open-min">
                          Minimum bid
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            id="price-open-min"
                            type="text"
                            placeholder="Enter minimum bid"
                            {...register("price_minimum_bid")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label"
                          htmlFor="price-fix-payment"
                        >
                          Preferred payment type
                        </label>
                        <select
                          className="form-select"
                          id="price-fix-payment"
                          {...register("type_money_2")}
                        >
                          <option>RLC</option>
                          <option>MATIC</option>
                          <option>USDC</option>
                        </select>
                      </div>
                      <div className="col-sm-12">
                        <label
                          className="form-label"
                          htmlFor="price-fix-payment"
                        >
                          <strong>Fees</strong>
                        </label>
                        Services Fee{" "}
                        {watchAllFields?.type_money_2 === "RLC" ? "0.75" : "1"}%{" "}
                        <i className="ci-copy ms-3 text-accent"></i>
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label"
                          htmlFor="price-open-start"
                        >
                          Starting date
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control date-picker date-range rounded pe-5"
                            id="price-open-start"
                            type="datetime-local"
                            placeholder="Choose date and time"
                            data-datepicker-options='{"enableTime": true, "altInput": true, "altFormat": "F j, Y", "dateFormat": "Y-m-d"}'
                            data-linked-input="#price-open-end"
                            {...register("bid_starting_date")}
                          ></input>
                          {/*  <i className="ci-calendar position-absolute top-50 end-0 translate-middle-y me-3"></i> */}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label" htmlFor="price-open-end">
                          Expiration date
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control date-picker rounded pe-5"
                            id="price-open-end"
                            type="datetime-local"
                            placeholder="Choose date and time"
                            {...register("bid_expiration_date")}
                            data-datepicker-options='{"enableTime": true, "altInput": true, "altFormat": "F j, Y", "dateFormat": "Y-m-d"}'
                          />
                          {/*  <i className="ci-calendar position-absolute top-50 end-0 translate-middle-y me-3"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*
                  <div
                    className={`tab-pane fade mb-4 pb-2 ${
                      tab === 3 ? "show active" : ""
                    } `}
                    id="price-time"
                    role="tabpanel"
                  >
                    <div className="row gy-md-4 gy-3 mb-md-5 mb-4 pb-md-0 pb-2">
                      <div className="col-sm-6">
                        <label className="form-label" htmlFor="price-fix-value">
                          Price Month
                        </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            id="price-fix-value"
                            type="text"
                            placeholder="Enter price"
                            {...register("price_membership_month")}
                          />
                        </div>
                      </div>

                      {watchAllFields?.payment_other_address_membership && (
                        <div className="col-sm-6">
                          <label
                            className="form-label"
                            htmlFor="price-fix-payout"
                          >
                            Payout to address
                          </label>
                          <input
                            className="form-control"
                            id="price-fix-payout"
                            type="text"
                            placeholder="eg. 0x1dDB2C08s97...9Ec"
                            {...register("payout_address_membership")}
                          />
                        </div>
                      )}
                      <div className="col-sm-6">
                        <div className="form-check mt-sm-4 pt-sm-3">
                          <input
                            className="form-check-input"
                            id="price-fix-wallet"
                            type="checkbox"
                            {...register("payment_other_address_membership")}
                          />
                          <label
                            className="form-check-label fs-base"
                            htmlFor="price-fix-wallet"
                          >
                            <span className="me-2">
                              Use address currently connected to wallet
                            </span>
                            <i className="ci-wallet fs-lg mt-n1 align-middle text-muted"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>  */}
                  <div>
                    <h3 className="h5 mb-4">
                      Properties{" "}
                      <span className="fw-normal text-muted">(optional)</span>
                    </h3>
                    <p>
                      “Properties” are textual traits that are usually used to
                      describe elements or parts of the object or character or
                      utility in the NFTs asset.
                    </p>
                    {fields.map((item, index) => (
                      <>
                        <div className="row gy-md-4 gy-3 mb-3" key={index}>
                          <div className="col-sm-6">
                            <input
                              className="form-control"
                              type="text"
                              placeholder={
                                index > 0 ? "eg. Utulity" : "eg. Character"
                              }
                              {...register(`metadata.${index}.name`)}
                            />
                          </div>
                          <div className="col-sm-4">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Description"
                              {...register(`metadata.${index}.value`)}
                            />
                          </div>
                          <div className="col-sm-2">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="btn btn-outline-accent"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                    <button
                      type="button"
                      className="btn btn-outline-accent"
                      onClick={() => append()}
                    >
                      <i className="ci-add me-2"></i>
                      <ins>Add more</ins>
                    </button>
                  </div>
                </div>

                <div className="pt-2">
                  <button
                    className="btn btn-lg btn-accent d-block w-100"
                    type="submit"
                    disabled={loading}
                  >
                    Create item
                  </button>
                </div>
              </div>
            </form>

            <aside className="col-lg-3 offset-lg-1 col-md-4 position-relative">
              <div className="sticky-top" style={{ top: "100px" }}>
                <h4 className="h5 mb-3 text-md-start text-center">Preview</h4>

                <div
                  className="card border-0 shadow mx-auto"
                  style={{ maxWidth: "500px", pointerEvents: "none" }}
                >
                  <div className="card-img-top position-relative overflow-hidden">
                    <a className="d-block" href="nft-single-auction-live.html">
                      <img
                        src={
                          selectedFiles
                            ? URL.createObjectURL(selectedFiles)
                            : BgImg
                        }
                        alt="Product image"
                      />
                    </a>
                  </div>
                  <div className="card-body">
                    <h3 className="product-title mb-2 fs-base">
                      <p className="d-block text-truncate">
                        {watchAllFields?.name}
                      </p>
                    </h3>
                    <span className="fs-sm text-muted">Current bid:</span>
                    <div className="d-flex align-items-center flex-wrap">
                      <h4 className="mt-1 mb-0 fs-base text-darker">
                        {tab === 1 && watchAllFields?.price_fixed}
                        {tab === 2 && watchAllFields?.price_minimum_bid}
                        {tab === 3 &&
                          watchAllFields?.price_membership_month}{" "}
                        {tab === 1 && watchAllFields?.type_money_1}
                        {tab === 2 && watchAllFields?.type_money_2}
                      </h4>
                      {/* }
                      <span className="mt-1 ms-1 fs-xs text-muted">
                        (≈ $2,000.55)
                      </span>
                      */}
                    </div>
                  </div>
                  <div className="card-footer mt-n1 py-0 border-0">
                    <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                      <img
                        className="me-2 rounded-circle"
                        src={user?.avatar ? user.avatar : avatarDefaultTwo}
                        width="32"
                        alt="Avatar"
                      />
                      @
                      {user?.username
                        ? user.username
                        : "RedLine Blockchain User"}
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </main>
    </>
  );
}
