import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import Logo from "../assets/img/logo-red-nft-marketplace.png";
import ConnectButton from "./ConnectButton";
export default function Header(props) {
  const [isActive, setActive] = useState(false);
  const { pathname } = useLocation();
  const except = ["/", "/login", "/register"];

  // :: Sticky Header
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {
    const header = document.querySelector(".navbar");
    const scrollTop = window.scrollY;
    scrollTop >= 10
      ? header.classList.add("navFixedNFT")
      : header.classList.remove("navFixedNFT");
  };

  console.log(pathname);
  return (
    <>
      <header
        style={{ zIndex: "100" }}
        className={`
        navbar d-block navbar-sticky navbar-expand-lg navbar-light  w-100 
        
        ${
          except.includes(pathname ? pathname : "/") ? "position-absolute" : ""
        } 
        `}
      >
        <div className="container">
          <Link
            className="navbar-brand d-none d-sm-block me-4 order-lg-1"
            to="/feed"
          >
            <img src={Logo} width="142" alt="RED NFT Marketplace" />
          </Link>
          <Link className="navbar-brand d-sm-none me-2 order-lg-1" to="/feed">
            <img src={Logo} width="74" alt="RED NFT Marketplace" />
          </Link>
          <div className="navbar-toolbar d-flex align-items-center order-lg-3">
            <ConnectButton />
          </div>
          <div
            className="collapse navbar-collapse me-auto order-lg-2"
            id="navbarCollapse"
          >
            <div className="d-lg-none py-3">
              <div className="input-group">
                <i className="ci-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                <input
                  className="form-control rounded-start"
                  type="text"
                  placeholder="What do you need?"
                />
              </div>
            </div>
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link " to={"explorer"}>
                  <span className="explore-1">Explorer&nbsp; </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}
