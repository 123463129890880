import { ReactNode } from "react";
import Header from "./Header";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/vendor/tiny-slider/dist/tiny-slider.css";
import "../assets/vendor/simplebar/dist/simplebar.min.css";
import "../assets/css/theme.min.css";
import "../assets/css/nft.css";
import "bootstrap-icons/font/bootstrap-icons.css";
type Props = {
  children?: ReactNode;
};
export default function Layout({ children }: Props) {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <ToastContainer position="bottom-right" newestOnTop />
    </>
  );
}
