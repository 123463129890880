import React, { useState, useEffect, useContext } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
export default function Support() {

  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    const endpoint = "https://www.redcoin.ai/mail";
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: "Support in REDNFT",
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        subject: data.subject,
        message: data.description
      })
    };
    fetch(endpoint, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        toast.success("Your email has been sent successfully.");
        console.log(responseData);
        reset();
      })
      .catch(error => {
        console.error('Error al enviar el formulario:', error);
      });
  };
  return (
    <>
      <div className="pt-4">
        <section className="container col-md-6 col-md-offset-3">
          <div className="card py-5 border-0 shadow text-left">
            <div className="card-body py-md-4 py-3 px-4 text-left ">
              <h2>Need Help? Reach out to our support team</h2>
              <p>If you're experiencing any issues or have questions about our service, please fill out the form below. Our team will get back to you as soon as possible.</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="mb-3 row">
                    <label htmlFor="firstName" className="col-sm-2 col-form-label">First Name</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" id="firstName" {...register("firstName", { required: true })} />
                      {errors.firstName && <span className="text-danger">First Name is required</span>}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label htmlFor="lastName" className="col-sm-2 col-form-label">Last Name</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" id="lastName" {...register("lastName", { required: true })} />
                      {errors.lastName && <span className="text-danger">Last Name is required</span>}
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                  <div className="col-sm-10">
                    <input type="email" className="form-control" id="email" {...register("email", { required: true })} />
                    {errors.email && <span className="text-danger">Email is required</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="subject" className="col-sm-2 col-form-label">Subject</label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" id="subject" {...register("subject", { required: true })} />
                    {errors.subject && <span className="text-danger">Subject is required</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="description" className="col-sm-2 col-form-label">Description</label>
                  <div className="col-sm-10">
                    <textarea className="form-control" id="description" rows="3" {...register("description", { required: true })}></textarea>
                    {errors.description && <span className="text-danger">Description is required</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-sm-10 offset-sm-2">
                    <button type="submit" className="btn btn-primary" disabled={loading}>Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
