export default {
  usd: [],
  rlc: [],
  alert: { status: "info", message: "", visible: false },
  token: undefined,
  accounts: {
    id: undefined,
    avatar: undefined,
    name: "",
    username: "",
    address: "",
    email: "",
    followers: 0,
    following: 0,
    short: "",
    website: "",
    twitter: "",
    facebook: "",
    instagram: "",
    balance: 0,
  },
};
