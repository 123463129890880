import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
export default function ModalDelete(props) {
  return (
    <>
      <div
        className={`modal fade show `}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ width: "270px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete the {props?.data?.type ? props?.data?.type : "Item"}?
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <button
                    className="btn btn-outline-danger w-100 mb-2"
                    onClick={() => props?.handleDelete()}
                  >
                    Delete
                  </button>
                </div>
                <div className="col-md-10 offset-md-1">
                  <button
                    className="btn btn-outline-secondary w-100"
                    onClick={() => props?.handleClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
