import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BgImg from "../assets/img/nft/home/hero-bg.png";
import Wallet from "../assets/img/nft/features/wallet.svg";
import AddSVG from "../assets/img/nft/features/add.svg";
import Image from "../assets/img/nft/features/image.svg";
import ShoppingCart from "../assets/img/nft/features/shopping-cart.svg";
import Connecting from "../assets/img/nft/features/connecting.svg";
import Creating from "../assets/img/nft/features/creating.svg";
import Exploring from "../assets/img/nft/features/exploring.svg";
import Profile from "../assets/img/nft/features/profile.svg";
import Subscription from "../assets/img/nft/features/subscription.svg";
import MobielApp from "../assets/img/nft/mobile-app.png";
import RedLine from "../assets/img/json/redlinePolygon.json";
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import AWSLogo from "../assets/img/nft/sponsor/aws.jpeg";
import EthereumLogo from "../assets/img/nft/sponsor/ethereum.jpeg";
import GobiernoLogo from "../assets/img/nft/sponsor/gobierno.jpg";
import PolygonLogo from "../assets/img/nft/sponsor/polygon.jpeg";
import RedFiLogo from "../assets/img/nft/sponsor/redfi.jpeg";
import VeriffLogo from "../assets/img/nft/sponsor/veriff-redline.jpg";

export default function Home(props: any) {
  const [activeSlide] = useState(true);
  useEffect(() => {
    tns({
      container: ".tns-carousel-inner",
      items: 4,
      gutter: 24,
      slideBy: 1,
      autoplay: false,
      autoplayButtonOutput: false,
      autoplayTimeout: 5000,
      speed: 900,
      loop: true,
      mouseDrag: true,
      nav: true,
      navPosition: "bottom",
      controls: false,
      controlsText: [
        '<i className="bi bi-arrow-left"></i>',
        '<i className="bi bi-arrow-right"></i>',
      ],
      responsive: {
        320: {
          items: 1,
        },
        480: {
          items: 1.3,
        },
        576: {
          items: 1.5,
        },
        768: {
          items: 2.4,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3.5,
        },
        1400: {
          items: 4,
        },
      },
    });
  }, [activeSlide]);

  return (
    <>
      <section
        className="mb-lg-2 bg-faded-accent bg-size-cover"
        style={{
          paddingTop: "80px",
          backgroundImage: `url(${BgImg})`,
        }}
      >
        <div className="container py-4">
          <div className="row align-items-center justify-content-center gy-3 py-3 text-lg-start text-center">
            <div className="col-lg-5 col-md-8 col-sm-10">
              <h2 className="mb-4 pb-lg-2">
                REDNFT: Your Crypto Gateway to Subscriptions and NFTs
              </h2>
              <p className="mb-lg-5 mb-4 fs-lg">
                REDNFT Marketplace revolutionizes digital content creation and
                ownership. As a content subscription service and NFT
                Marketplace, REDNFT empowers creators, known as Redliners, to
                share their content exclusively, privately, or publicly through
                customizable subscription models. Creators have the freedom to
                mint their image, video, text, or audio creations into NFTs
                within our global marketplace, unlocking new avenues for
                monetization and appreciation. With the ability to organize
                content into various categories and assign them to custom
                catalogs, Redliners can curate unique and personalized
                experiences for their audience.
              </p>
              <p className="mb-lg-5 mb-4 fs-lg">
                Join REDNFT today to harness the power of crypto, subscriptions,
                and NFTs. Create, connect, and redefine the value of digital
                assets in the decentralized landscape.
              </p>
            </div>
            <div className="col-lg-6 col-md-8 offset-lg-1 col-sm-10">
              <div className="mb-4 mx-n2" id="register">
                <div>
                  <div className="px-2">
                    <Player
                      autoplay
                      loop
                      src={RedLine}
                      style={{ height: "100%", width: "100%" }}
                    >
                      <Controls
                        visible={false}
                        buttons={["play", "repeat", "frame", "debug"]}
                      />
                    </Player>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="container mb-lg-2">
          <div className="row m-5 text-center">
            <h2 className="card-title pricing-card-title">
              Unlock New Possibilities with{" "}
              <span style={{ color: "red" }}>RED</span>NFT
            </h2>
            <p>
              Discover the endless opportunities with REDNFT! Here's how you can
              leverage our platform to transform your digital experience:
            </p>
            <ul className="text-left">
              <li className="mb-3">
                <strong>Acquire Authentic and Unique Digitized Pieces:</strong>{" "}
                Immerse yourself in a world of one-of-a-kind digital artworks
                and collectibles. Each piece on REDNFT is meticulously crafted
                and authenticated, ensuring you own a truly unique digital
                asset.
              </li>
              <li className="mb-3">
                <strong>Raise Funds Through Subscriptions and NFTs:</strong>{" "}
                Utilize the power of blockchain to generate funds for your
                projects. Whether you're an artist, creator, or organization,
                our platform allows you to launch subscription models and NFTs
                to support your ventures.
              </li>
              <li className="mb-3">
                <strong>
                  Secure a Place in Unique Events or Be Part of a Community:
                </strong>{" "}
                Join exclusive events and become a valued member of a vibrant
                community. REDNFT connects you with like-minded individuals and
                offers access to special gatherings that celebrate digital
                innovation and creativity.
              </li>
              <li className="mb-3">
                <strong>
                  Collaborate Directly with the Social Causes That Matter the
                  Most:
                </strong>{" "}
                Make a meaningful impact by aligning with social causes that
                resonate with you. Our platform facilitates direct
                collaborations, allowing you to contribute to and promote
                important initiatives through your digital activities.
              </li>
              <li className="mb-3">
                <strong>
                  Receive Physical and Digital Items from Your Favorite
                  Creators:
                </strong>
                Enjoy the best of both worlds with tangible and virtual items
                from creators you admire. From limited-edition physical
                merchandise to exclusive digital content, REDNFT bridges the gap
                between the physical and digital realms.
              </li>
            </ul>
            <p>
              <strong>
                You Want More! Imagine and Start Creating. There Is No Limit
                Where Your Mind Can Take You.{" "}
              </strong>
              <br />
              At REDNFT, the only limit is your imagination. Begin your journey
              with us and explore the boundless potential of digital creativity.
              Let your ideas take flight and redefine what’s possible in the
              world of NFTs and digital assets. Start creating today!
            </p>
          </div>
        </div>
      </section>

      <section className="container mt-5">
        <div className="card py-5 border-0 shadow position-relative overflow-hidden">
          <span
            className="d-block w-100 h-100 position-absolute top-0 start-0 zindex-1 bg-accent opacity-15"
            style={{
              background:
                "conic-gradient(from 207.95deg at 50% 50%, #885CFF -57.31deg, #FF6B9B 44.14deg, #DC86FF 78.21deg, #885CFF 123.94deg, #DC86FF 186.36deg, #FF6B9B 249.2deg, #885CFF 302.69deg, #FF6B9B 404.14deg",
            }}
          ></span>
          <div className="card-body py-3 px-4 text-center">
            <h3 className="nomargin text-accent">Connect your RED WALLET</h3>
            <br />
            <p className="nomargin">
              Connect your RED WALLET now to be registered as a Redliner.
            </p>
            <p className="nomargin">
              You'll be able to list your NFTs and interact with others on our
              global marketplace.
            </p>
            <br />
            {/* 
            <div
              style={{ padding: "40% 0 0 0", position: "relative" }}
              id="video"
            >
              <iframe
                src="https://player.vimeo.com/video/753396338?h=0adb169496&color=d60000&title=0&byline=0&portrait=0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: "absolute",
                  width: "80%",
                  height: "100%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                title="REDNFT Marketplace Welcome Video"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
            */}
          </div>
        </div>
      </section>
      <section className="container py-lg-5 py-4">
        {/*
        <h2>Getting Started</h2>
        <p className="mb-4">
          Welcome to the REDNFT Marketplace, your gateway to the world of NFTs
          and crypto-based subscriptions. Follow these steps to begin your
          journey
        </p>
         */}
        <div className="tns-carousel mb-4">
          <div className="tns-carousel-inner">
            <div className="faq">
              <img className="mb-4" src={Wallet} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Download & KYC</h4>
              <p className="fs-sm text-muted">
                <a
                  href="https://redlinewallet.io/"
                  target="_blank"
                  className="link-accent"
                  rel="noreferrer"
                >
                  Download the RED WALLET
                </a>{" "}
                for your Android, iOS or Google Chrome device. Complete the KYC
                on your mobile device.{" "}
              </p>
            </div>
            <div className="faq">
              <img className="mb-4" src={AddSVG} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Install Chrome Extension</h4>
              <p>
                <a
                  href="https://chromewebstore.google.com/detail/red-wallet/ceakelacjcdhnjhafobfpfaobfblifho?hl=en-US&utm_source=ext_sidebar"
                  target="_blank"
                  className="link-accent"
                  rel="noreferrer"
                >
                  Download and setup your Google Chrome extension.
                </a>
                This is the only way to access the REDNFT Marketplace{" "}
              </p>
            </div>
            <div className="faq">
              <img className="mb-4" src={Connecting} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Connect</h4>
              <p>
                Connect your RED WALLET to the REDNFT Marketplace by clicking on
                the "Connect Wallet" button.
              </p>
            </div>
            <div className="faq">
              <img className="mb-4" src={ShoppingCart} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Start Earning</h4>
              <p>
                Now, you'll be able to showcase your NFTs and apply various
                subscription or purchase models to your posts and NFTs
              </p>
            </div>
            <div className="faq">
              <img className="mb-4" src={Exploring} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Need more info?</h4>
              <p>
                Click the link below to learn more about the{" "}
                <a
                  href="https://www.rednft.io/faq"
                  target="_blank"
                  className="link-accent"
                  rel="noreferrer"
                >
                  REDNFT Marketplace.
                </a>
              </p>
            </div>
            {/* 
            <div className="faq">
              <img className="mb-4" src={Creating} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Creating NFTs</h4>
              <ol>
                <li>Click on the "Create" button.</li>
                <li>Select "Create NFT".</li>
                <li>Fill details</li>
                <li>
                  Mint the NFT once all details are filled in. Your NFT will be
                  available for purchase.
                </li>
              </ol>
            </div>
            <div className="faq">
              <img className="mb-4" src={Subscription} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">
                Creating Subscription Packages
              </h4>
              <ol>
                <li>Click on the "Create" button</li>
                <li>Select "Create Subscription Package"</li>
                <li>
                  Configure your plan
                  <ol>
                    <li>
                      Daily Plan
                    </li>
                  </ol>
                </li>
                <li>Customize the plan based on your needs and publish it.</li>
              </ol>
            </div>
            <div className="faq">
              <img className="mb-4" src={Profile} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Profile Management</h4>
              <ol>
                <li>
                  Access Profile Settings: Update your name, username, email,
                  bio, social media, and website links.
                </li>
                <li>View Your Feed: See your posts and activities.</li>
                <li>
                  Manage NFTs and Collections: Organize your NFTs and
                  collections.
                </li>
                <li>
                  Manage Subscriptions: View your subscribed content and manage
                  your packages.
                </li>
                <li>
                  Favorites and Notifications: Keep track of your favorite NFTs
                  and notifications.
                </li>
              </ol>
            </div>
            <div className="faq">
              <img className="mb-4" src={Exploring} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">
                Exploring the Marketplace
              </h4>
              <ol>
                <li>My Feed: Shows your posts.</li>
                <li>
                  For You: Curated feeds of followed and subscribed content.
                </li>
                <li>Feed Explorer: Browse all public posts.</li>
              </ol>
            </div>
            <div className="faq">
              <img className="mb-4" src={Subscription} width="60" alt="Icon" />
              <h4 className="mb-2 fs-lg text-body">Subscription Models</h4>
              <ol>
                <li>
                  Subscribe to Redliners: View profiles, follow/unfollow, and
                  subscribe to paid content.
                </li>
                <li>
                  Access Posts and NFTs: See all public posts and NFTs. Paid
                  subscriptions are required for private posts.
                </li>
              </ol>
            </div>
            */}
          </div>
        </div>
      </section>

      <section className="container py-lg-5 py-4">
        <div className="card border-0 position-relative overflow-hidden">
          <span
            className="d-block w-100 h-100 position-absolute top-0 start-0 zindex-1 bg-accent opacity-15"
            style={{
              background:
                "conic-gradient(from 207.95deg at 50% 50%, #885CFF -57.31deg, #FF6B9B 44.14deg, #DC86FF 78.21deg, #885CFF 123.94deg, #DC86FF 186.36deg, #FF6B9B 249.2deg, #885CFF 302.69deg, #FF6B9B 404.14deg",
            }}
          ></span>

          <div className="card-body row py-0 px-sm-0 position-relative zindex-5">
            <div className="col-lg-9 col-sm-10 offset-sm-1 d-flex flex-md-row flex-column align-items-md-end text-md-start text-center">
              <div
                className="my-5 me-md-5 me-auto ms-md-0 ms-auto py-md-4"
                style={{ maxWidth: "495px" }}
              >
                <h3 className="h2 mb-3">
                  <span className="text-accent">The Best Wallet</span> for NFT
                  Enthusiasts & Content Creators!
                </h3>
                <p className="mb-sm-4 mb-3">
                  If you're an NFT enthusiast, or a content creator, or are
                  looking to get more familiar with NFTs, consider downloading
                  the RED WALLET! The RED WALLET is your key to REDNFT
                  Marketplace and Redline Blockchain ecosystem.
                </p>
                <div className="mx-n1">
                  <a
                    className="btn-market btn-extension mt-3 mx-1"
                    href="https://redlinewallet.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="btn-market-subtitle">RED WALLET</span>
                    <span className="btn-market-title"> Download here</span>
                  </a>
                </div>
              </div>
              <div className="flex-shrink-0 ms-md-auto">
                <img
                  src={MobielApp}
                  alt="Illustration"
                  style={{
                    borderTopLeftRadius: "28px",
                    borderTopRightRadius: "28px",
                    boxShadow: "16px 16px 24px -7px rgba(0, 0, 0, .3)",
                  }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container py-lg-5 py-4">
        <div className="row align-items-center justify-content-md-center">
          <p className="title display-6 text-center">
            Technology & Financial Partners
          </p>
          <div className="col-sm-6 col-lg-2">
            <img className="card-img-top" src={PolygonLogo} alt="Polygon" />
          </div>
          <div className="col-sm-6 col-lg-2">
            <img className="card-img-top" src={EthereumLogo} alt="Ethereum" />
          </div>
          <div className="col-lg-2 col-sm-6">
            <img className="card-img-top" src={RedFiLogo} alt="RedFi" />
          </div>
          <div className="col-lg-2 col-sm-6 ">
            <img
              className="card-img-top"
              src={AWSLogo}
              alt="Amazon Web Service"
            />
          </div>
          <div className="col-lg-2 col-sm-6 offset-sm-3 offset-xl-0 offset-lg-0 offset-md-0">
            <img className="card-img-top" src={GobiernoLogo} alt="Pro Panama" />
          </div>
          <div className="col-lg-2 col-sm-6 offset-sm-3 offset-xl-0 offset-lg-0 offset-md-0">
            <img className="card-img-top" src={VeriffLogo} alt="Veriff" />
          </div>
        </div>
      </section>
    </>
  );
}
