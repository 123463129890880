import { useState, useEffect } from "react";
import initialState from "../initialState";

export function useWallet() {
  const [account, setAccount] = useState("");
  const [user, setUser] = useState(initialState.user);
  const [token, setToken] = useState(initialState.token);
  const [keyAddress, setkeyAddress] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    setAccount(localStorage.getItem("accountAddress"));
    setUser(JSON.parse(localStorage.getItem("user")));
    setToken(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    //accountAddress
    window.addEventListener("storage", () => {
      setToken(localStorage.getItem("token"));
      setAccount(localStorage.getItem("accountAddress"));
      setkeyAddress(localStorage.getItem("keyAddress"));
    });
  }, []);

  useEffect(() => {
    const handler = (event) => {
      if (event.source !== window) return;
      if (event.data.source !== "redline-background") return;
      // console.log("received response:  ", event.data);
      setMessage(event.data);
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);

  const addtoken = (token) => {
    window.localStorage.setItem("token", token);
    setToken(token);
  };

  const signin = (user) => {
    window.localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };
  const signout = () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("accountAddress");
    window.localStorage.removeItem("keyAddress");
    setUser(false);
  };

  const postMessage = (name) => {
    let messageNew = {
      source: "redline-inpage",
      name: name,
    };
    window.postMessage(messageNew);
  };

  return {
    signin,
    signout,
    addtoken,
    postMessage,
    token,
    account,
    user,
    keyAddress,
    message,
  };
}
