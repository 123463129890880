import React, { useState, useEffect, useContext } from "react";
import RichTextEditor from 'react-rte';

export default function ModalRich(props) {
  const [loading, setLoading] = useState(false);
  const [richeditor, setRicheditor] = useState(RichTextEditor.createEmptyValue());
  const [textEdit, setTextEdit] = useState();

  const handleChange = (newValue) => {
    setRicheditor(newValue);
    props.setTextareaMessage(newValue.toString('html'))
  };

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  return (
    <>
      <div
        className={`modal fade show`}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Say what you want
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form >
              <div className="modal-body">
                <div className="row">
                  <div className="form-group">
                    <RichTextEditor
                      value={richeditor}
                      onChange={handleChange}
                      toolbarConfig={toolbarConfig}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => props?.handleClose()}
                >
                  Close
                </button>
                <button type="button" className="btn btn-accent" onClick={() => props.onSubmitRich(textEdit)}>
                  Public
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
