import React, { useState, useEffect } from "react";
export default function PreviewMultimedia(props) {
  const [typeFiles, setTypeFiles] = useState(props.type ? props.type : null);
  const [file, setFiles] = useState(
    props.file?.size ? URL.createObjectURL(props.file) : props.file
  );

  useEffect(() => {
    setTypeFiles(props.type ? props.type : null);
    setFiles(props.file?.size ? URL.createObjectURL(props.file) : props.file);
  }, [props]);

  return (
    <div>
      {typeFiles.includes("image") && (
        <img
          src={file}
          alt="Preview"
          className={
            props?.classHerence ? props?.classHerence : "img-thumbnail"
          }
          style={{ display: "block", margin: "auto" }}
        />
      )}
      {typeFiles.includes("audio") && <audio src={file} controls />}
      {typeFiles.includes("video") && (
        <video
          muted
          autoPlay
          loop
          controls
          id="full-screenVideo"
          className={
            props?.classHerence ? props?.classHerence : "img-thumbnail"
          }
        >
          <source src={file} type="video/mp4" />
        </video>
      )}
    </div>
  );
}
