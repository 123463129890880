import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import { useWallet } from "../../hooks/useWallet";
import SidebarMenuAccount from "../../components/SidebarMenuAccount";
import HeaderMenuAccount from "../../components/HeaderMenuAccount";
import Countdown from "../../components/CountDown";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { env } from "process";
import FavoriteButton from "../../components/FavoriteButton";
import ShowMultimedia from "../../components/ShowMultimedia";

export default function Myfavorite() {
  const [tab, setTab] = useState("All");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsSold, setItemsSold] = useState([]);
  const [listToken, setListToken] = useState([]);
  const { account, user } = useWallet();

  const getAllNFTCards = async () => {
    setLoading(true);

    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/like`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setListToken(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllNFTCards();
  }, [user]);

  const changeTab = (tab) => {
    if (tab === "Sold") {
      setListToken(itemsSold);
      setTab("Sold");
    } else {
      setTab("All");
      setListToken(items);
    }
  };

  return (
    <>
      <main className="page-wrapper">
        <HeaderMenuAccount />
        <div className="container mb-5 pb-3">
          <div className="bg-light shadow-lg rounded-3 overflow-hidden">
            <div className="row">
              <SidebarMenuAccount />
              <section className="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                  <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-4">
                    <h1 className="h3 mb-sm-0 mb-2 pb-1 text-sm-start text-center">
                      My Favorites
                    </h1>
                  </div>

                  <div className="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 gx-3 mb-4">
                    {listToken.length > 0 &&
                      listToken?.map((item, index) => (
                        <div className="col mb-2" key={index}>
                          <article className="card h-100 border-0 shadow">
                            <div className="card-img-top position-relative overflow-hidden">
                              <Link
                                className="d-block"
                                to={`/nft/view/${item?.ipfs?.contract
                                  ? item?.ipfs?.contract
                                  : process.env.REACT_APP_CONTRACT_COLLECTION
                                  }/${item?.ipfs?.id}`}
                              >
                                <ShowMultimedia
                                  file={item?.ipfs?.multimedia}
                                  alt={item?.ipfs?.name}
                                  type={item?.ipfs?.type_multimedia}
                                />
                              </Link>
                              {item?.ipfs?.type === "2" && (
                                <div className="badge bg-dark m-3 fs-sm position-absolute top-0 start-0 zindex-5">
                                  <i className="ci-time me-1"></i>
                                  <div
                                    className="countdown d-inline"
                                    data-countdown="12/31/2022 09:00:00 PM"
                                  >
                                    <span className="countdown-hours mb-0 me-0">
                                      <span className="countdown-value">0</span>
                                      <span className="countdown-label fs-lg">
                                        :
                                      </span>
                                    </span>
                                    <span className="countdown-minutes mb-0 me-0">
                                      <span className="countdown-value">0</span>
                                      <span className="countdown-label fs-lg">
                                        :
                                      </span>
                                    </span>
                                    <span className="countdown-seconds mb-0 me-0">
                                      <span className="countdown-value">0</span>
                                    </span>
                                  </div>
                                </div>
                              )}
                              <FavoriteButton
                                isLike={item?.ipfs?.isLike}
                                tokenId={item?.ipfs?.id}
                              />
                            </div>
                            <div className="card-body">
                              <h3 className="product-title mb-2 fs-base">
                                <Link
                                  className="d-block text-truncate"
                                  to={`/nft/view/${item?.ipfs?.contract
                                    ? item?.ipfs?.contract
                                    : process.env
                                      .REACT_APP_CONTRACT_COLLECTION
                                    }/${item?.ipfs?.id}`}
                                >
                                  {item?.ipfs?.name}
                                </Link>
                              </h3>
                              <span className="fs-sm text-muted">
                                Current {item?.type === "1" && "Price"}
                                {item?.ipfs?.type === "2" && "Minimum Bid"}
                                {item?.ipfs?.type === "3" &&
                                  "Membership Subcription"}
                                :
                              </span>
                              <div className="d-flex align-items-center flex-wrap">
                                <h4 className="mt-1 mb-0 fs-base text-darker">
                                  {item?.ipfs?.standard === "ERC-1155" && item?.ipfs?.type === "1" ? "See Price" : item?.ipfs?.price_fixed + " " + item?.money}
                                  {item?.ipfs?.type === "2" && item?.ipfs?.price_minimum_bid}{" "}
                                </h4>
                                {/* 
                              <span className="mt-1 ms-1 fs-xs text-muted">
                                (≈ $ 2,000.55)
                              </span>
                              */}
                              </div>
                            </div>
                            <div className="card-footer mt-n1 py-0 border-0">
                              <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                <img
                                  className="me-2 rounded-circle"
                                  src={
                                    item?.ipfs?.owner?.avatar
                                      ? item?.ipfs?.owner?.avatar
                                      : avatarDefault
                                  }
                                  width="32"
                                  alt={item?.ipfs?.owner?.name}
                                />
                                <Link
                                  className="nav-link-style fs-sm stretched-link"
                                  to={`/author/${item?.ipfs?.owner?.id}`}
                                >
                                  @{item?.ipfs?.owner?.username}
                                </Link>
                              </div>
                            </div>
                          </article>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
