import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";

export default function ModalBurn1155(props) {
  const [supply, setSupply] = useState(props?.supply - props?.supplySell);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const watchAllFields = watch();
  const onSubmit = async (data) => {
    props?.handleBurnSupply(data?.quantity);
  };
  return (
    <>
      <div
        className={`modal fade show`}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Amount of NFT you want to burn
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Quantity <small>Max: {supply}</small></label>
                    <input
                      type="text"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      className="form-control"
                      {...register("quantity", { required: true })}
                      defaultValue={1}
                      min={1}
                      max={supply}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => props?.handleClose()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-accent"
                  disabled={
                    parseInt(watchAllFields?.quantity) >
                    parseInt(supply) ||
                    watchAllFields?.quantity <= 0
                  }
                >
                  Complete Burn
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
