import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import FavoriteButton from "../../components/FavoriteButton";
import Countdown from "../../components/CountDown";
import { useSearchParams } from "react-router-dom";
import ShowMultimedia from "../../components/ShowMultimedia";
import ShowOwnerToken from "../../components/ShowOwnerToken";
import "react-toastify/dist/ReactToastify.css";

export default function Explorer() {
  let { contract, tokenId } = useParams();
  const [token, setToken] = useState(undefined);
  const [listToken, setListToken] = useState([]);
  const [listAuthor, setListAuthor] = useState([]);
  const [activeTab, setActiveTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [stadicts, setStadicts] = useState({
    All: 0,
    Art: 0,
    Photography: 0,
    Music: 0,
    Gaming: 0,
    Sports: 0,
    Collections: 0,
    Utility: 0,
  });
  const [form, setForm] = useState({
    pricemin: "",
    pricemax: "",
    money: "",
    sort: "created:desc",
    category: new Set(),
    status: new Set(),
    search: "",
  });

  useEffect(() => {
    searchQuery();
  }, []);

  useEffect(() => {
    if (searchParams.get("category")) {
      setActiveTab(true);
      handleCheckboxCategory(searchParams.get("category"));
    }
  }, [searchParams]);

  const searchQuery = () => {
    setLoading(true);
    console.log(form);
    let userId = JSON.parse(localStorage.getItem("user"));
    let categorys = JSON.stringify([...form.category.values()]);
    let status = JSON.stringify([...form.status.values()]);
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/ipfs-public`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("pricemin", form?.pricemin);
    url.searchParams.append("pricemax", form?.pricemax);
    url.searchParams.append("money", form.money);
    url.searchParams.append("sort", form.sort);
    url.searchParams.append("category", categorys);
    url.searchParams.append("status", status);
    url.searchParams.append("search", form.search);
    url.searchParams.append("userId", userId?.id ? userId?.id : undefined);
    fetch(`${decodeURIComponent(url)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setListToken(response.data.items);
        setStadicts(response.data.category);
        setListAuthor(response.data.users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function handleCheckboxCategory(itemKey) {
    const newSelectedItems = new Set(form.category);
    if (!newSelectedItems.has(itemKey)) {
      newSelectedItems.add(itemKey);
    } else {
      newSelectedItems.delete(itemKey);
    }
    console.log(newSelectedItems);
    setForm({ ...form, category: newSelectedItems });
  }

  function handleCheckboxStatus(itemKey) {
    const newSelectedItems = new Set(form.status);
    if (!newSelectedItems.has(itemKey)) {
      newSelectedItems.add(itemKey);
    } else {
      newSelectedItems.delete(itemKey);
    }
    console.log(newSelectedItems);
    setForm({ ...form, status: newSelectedItems });
  }


  const handlePriceMin = (e) => {
    let value = e.target.value;
    if (/^0\d+/.test(value)) {
      value = value.replace(/^0(\d+)/, '0.$1');
    }
    if (!isNaN(value) || value === '') {
      setForm({ ...form, pricemin: value });
    }
  };

  const handlePriceMax = (e) => {
    let value = e.target.value;
    if (/^0\d+/.test(value)) {
      value = value.replace(/^0(\d+)/, '0.$1');
    }
    if (!isNaN(value) || value === '') {
      setForm({ ...form, pricemax: value });
    }
  };
  return (
    <>
      <main className="page-wrapper">
        <div className="pt-4 pb-5 bg-darker">
          <div className="container pt-2 pb-3 pt-lg-3 pb-lg-4">
            <div className="d-lg-flex justify-content-between pb-3">
              <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li className="breadcrumb-item">
                      <Link className="text-nowrap" to="/feed">
                        <i className="ci-home"></i>Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-nowrap">
                      <Link to={"/explorer"}>NFT Marketplace</Link>
                    </li>
                    <li
                      className="breadcrumb-item text-nowrap active"
                      aria-current="page"
                    >
                      All NFTs
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 className="h3 text-light mb-0">Explore NFTs</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container pb-5 mb-2 mb-md-4">
          <div className="bg-light shadow-lg rounded-3 p-4 mt-n5 mb-4">
            <div className="row gy-3 gx-4 justify-content-between">
              <div className="col-lg-2 col-md-3 col-sm-5 col-12 order-md-1 order-sm-2 order-3">
                <div className="dropdown">
                  <button
                    className={`btn btn-outline-secondary dropdown-toggle w-100   ${activeTab ? "collapsed " : ""
                      } `}
                    onClick={() => setActiveTab(!activeTab)}
                  >
                    <i className="ci-filter me-2"></i>Filters
                  </button>
                </div>
              </div>
              <div className="col-md col-12 order-md-2 order-sm-1 order-1">
                <div className="input-group">
                  <input
                    className="form-control pe-5 rounded"
                    type="text"
                    placeholder="Search accounts, NFTs or collections"
                    onChange={(e) =>
                      setForm({ ...form, search: e.target.value })
                    }
                  />
                  <i className="ci-search position-absolute top-50 end-0 translate-middle-y zindex-5 me-3"></i>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-7 col-12 order-md-3 order-sm-3 order-2">
                <div className="d-flex align-items-center flex-shrink-0">
                  <label className="form-label mb-0 me-2 pe-1 fw-normal text-nowrap d-sm-block d-none">
                    Sort by:
                  </label>
                  <select
                    className="form-select"
                    defaultValue={{ label: "Newest", value: "created:asc" }}
                    onChange={(e) => setForm({ ...form, sort: e.target.value })}
                  >
                    <option value="created:asc">Newest</option>
                    <option value="created:desc">Recently Listed</option>
                    <option value="created:asc">Recently Created</option>
                    <option value="price_fixed:asc">Price: Low to High</option>
                    <option value="price_fixed:desc">Price: High to Low</option>
                    <option value="sold:desc">Highest Last Sale</option>
                    <option value="id:desc">Oldest</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-1 col-md-2 col-sm-1 col-2 order-md-3 order-sm-2 order-4">
                <div className="d-flex align-items-center justify-content-between  flex-shrink-0">
                  <button
                    class="btn btn-sm btn-accent rounded-1 "
                    onClick={() => searchQuery()}
                  >
                    <i className="ci-search "></i>
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`collapse  ${activeTab ? "show " : ""} `}
              id="shop-filters"
            >
              <div className="row g-4 pt-4">
                <div className="col-lg-4 col-sm-6">
                  <div className="card">
                    <div className="card-body px-4">
                      <div className="widget">
                        <h3 className="widget-title mb-2 pb-1">Categories</h3>
                        <ul className="widget-list list-unstyled">
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="All"
                                checked={form.category.has("All")}
                                onChange={() => handleCheckboxCategory("All")}
                              />
                              <label className="form-check-label" htmlFor="all">
                                All NFTs
                              </label>
                            </div>
                            <span className="fs-xs text-muted">
                              {stadicts?.All}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Art"
                                checked={form.category.has("Art")}
                                onChange={() => handleCheckboxCategory("Art")}
                              />
                              <label className="form-check-label" htmlFor="Art">
                                Art
                              </label>
                            </div>
                            <span className="fs-xs text-muted">
                              {stadicts?.Art}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Photography"
                                checked={form.category.has("Photography")}
                                onChange={() =>
                                  handleCheckboxCategory("Photography")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Photography"
                              >
                                Photography
                              </label>
                            </div>
                            <span className="fs-xs text-muted">
                              {stadicts?.Photography}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Music"
                                checked={form.category.has("Music")}
                                onChange={() => handleCheckboxCategory("Music")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Music"
                              >
                                Music
                              </label>
                            </div>
                            <span className="fs-xs text-muted">
                              {stadicts?.Music}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Gaming"
                                checked={form.category.has("Gaming")}
                                onChange={() =>
                                  handleCheckboxCategory("Gaming")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Gaming"
                              >
                                Gaming
                              </label>
                            </div>
                            <span className="fs-xs text-muted">
                              {stadicts?.Gaming}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Collections"
                                checked={form.category.has("Collections")}
                                onChange={() =>
                                  handleCheckboxCategory("Collections")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Collections"
                              >
                                Collections
                              </label>
                            </div>
                            <span className="fs-xs text-muted">
                              {stadicts?.Collections}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Utility"
                                checked={form.category.has("Utility")}
                                onChange={() =>
                                  handleCheckboxCategory("Utility")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="music"
                              >
                                Utility
                              </label>
                            </div>
                            <span className="fs-xs text-muted">
                              {stadicts?.Utility}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="card mb-4">
                    <div className="card-body px-4">
                      <div className="widget">
                        <h3 className="widget-title mb-2 pb-1">Status</h3>
                        <ul className="widget-list list-unstyled">
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Available"
                                checked={form.status.has("1")}
                                onChange={() => handleCheckboxStatus("1")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Available"
                              >
                                Available
                              </label>
                            </div>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Featured"
                                checked={form.status.has("0")}
                                onChange={() => handleCheckboxStatus("0")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Featured"
                              >
                                Featured
                              </label>
                            </div>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-1">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="sold"
                                checked={form.status.has("2")}
                                onChange={() => handleCheckboxStatus("2")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sold"
                              >
                                Sold
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="card">
                    <div className="card-body px-4">
                      <div className="widget">
                        <h3 className="widget-title">
                          Price range - {form.pricemin} {form.money} -{" "}
                          {form.pricemax} {form.money}
                        </h3>
                        <input
                          type="number"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Min Price"
                          className="form-control pe-5 rounded"
                          style={{ marginTop: "10px" }}
                          value={form.pricemin}
                          onChange={handlePriceMin}
                        />
                        <input
                          type="number"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Max Price"
                          className="form-control pe-5 rounded"
                          style={{ marginTop: "10px" }}
                          value={form.pricemax}
                          onChange={handlePriceMax}
                        />
                        <div className="d-flex pb-1">
                          <div
                            className="w-50 pe-2 me-2"
                            style={{ marginTop: "10px" }}
                          >
                            <label className="form-label mb-0 me-2 pe-1 fw-normal text-nowrap d-sm-block d-none">
                              Type Money:
                            </label>
                            <select
                              className="form-select"
                              onChange={(e) =>
                                setForm({ ...form, money: e.target.value })
                              }
                            >
                              <option value="">All</option>
                              <option value="MATIC">MATIC</option>
                              <option value="RLC">RLC</option>
                              {/*<option value="USDC">USDC</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 pt-sm-3">
            {listToken.length > 0 &&
              listToken?.map((item, index) => (
                <div className="col mb-2" key={index}>
                  <article className="card h-100 border-0 shadow">
                    <div className="card-img-top position-relative overflow-hidden">
                      <Link
                        className="d-block"
                        to={`/nft/view/${item.contract
                          ? item.contract
                          : process.env.REACT_APP_CONTRACT_COLLECTION
                          }/${item.id}`}
                      >
                        <ShowMultimedia
                          file={item?.multimedia}
                          alt={item?.name}
                          type={item?.type_multimedia}
                        />
                      </Link>
                      {item?.type === "2" && (
                        <Countdown
                          model="minimalist"
                          dateEnd={item?.bid_expiration_date}
                          dateStart={item?.bid_starting_date}
                        />
                      )}
                      <FavoriteButton
                        isLike={item?.isLike}
                        tokenId={item?.id}
                      />
                    </div>
                    <div className="card-body">
                      <h3 className="product-title mb-2 fs-base">
                        <Link
                          className="d-block text-truncate"
                          to={`/nft/view/${item.contract
                            ? item.contract
                            : process.env.REACT_APP_CONTRACT_COLLECTION
                            }/${item.id}`}
                        >
                          {item?.name}
                        </Link>
                      </h3>
                      <span className="fs-sm text-muted">
                        Current {item?.type === "1" && "Price"}
                        {item?.type === "2" && "Minimum Bid"}
                        {item?.type === "3" && "Membership Suncription"}:
                      </span>
                      <div className="d-flex align-items-center flex-wrap">
                        <h4 className="mt-1 mb-0 fs-base text-darker">
                          {item?.standard === "ERC-1155" && item?.type === "1" ? "See Price" : item?.price_fixed + " " + item?.money}
                          {item?.type === "2" && item?.price_minimum_bid}{" "}
                        </h4>
                        {/* 
                              <span className="mt-1 ms-1 fs-xs text-muted">
                                (≈ $ 2,000.55)
                              </span>
                              */}
                      </div>
                    </div>
                    <div className="card-footer mt-n1 py-0 border-0">
                      <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                        <ShowOwnerToken owners={item?.owners} />
                      </div>
                    </div>
                  </article>
                </div>
              ))}

            {listAuthor.length > 0 &&
              listAuthor?.map((item, index) => (
                <div className="col mb-2" key={index}>
                  <article className="card h-100 border-0 shadow">
                    <div className="card-img-top position-relative overflow-hidden">
                      <Link className="d-block" to={`/author/${item.id}`}>
                        <img
                          src={item?.avatar ? item.avatar : avatarDefault}
                          alt={item?.username}
                        />
                      </Link>
                    </div>
                    <div className="card-body">
                      <h3 className="product-title mb-2 fs-base">
                        <Link className="d-block" to={`/author/${item.id}`}>
                          {item?.username}
                        </Link>
                      </h3>
                      <span className="fs-sm text-muted">Redliner</span>
                    </div>
                    <div className="card-footer mt-n1 py-0 border-0">
                      <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                        <img
                          className="me-2 rounded-circle"
                          src={item?.avatar ? item?.avatar : avatarDefault}
                          width="32"
                          alt={item?.username}
                        />
                        <Link
                          className="nav-link-style fs-sm stretched-link"
                          to={`/author/${item?.id}`}
                        >
                          @{item?.username}
                        </Link>
                      </div>
                    </div>
                  </article>
                </div>
              ))}
          </div>
          <hr className="mt-4 mb-3" />
          {/* 
          <nav
            className="d-flex justify-content-between pt-2"
            aria-label="Page navigation"
          >
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="ci-arrow-left me-2"></i>Prev
                </a>
              </li>
            </ul>
            <ul className="pagination">
              <li className="page-item d-sm-none">
                <span className="page-link page-link-static">1 / 5</span>
              </li>
              <li
                className="page-item active d-none d-sm-block"
                aria-current="page"
              >
                <span className="page-link">
                  1<span className="visually-hidden">(current)</span>
                </span>
              </li>
              <li className="page-item d-none d-sm-block">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
            </ul>
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  Next<i className="ci-arrow-right ms-2"></i>
                </a>
              </li>
            </ul>
          </nav>
          */}
        </div>
      </main>
    </>
  );
}
