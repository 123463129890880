import React, { useState, useEffect, useContext } from "react";
import avatarDefaultTwo from "../assets/img/nft/vendor/avatar.png";
import { toast } from "react-toastify";
import { useWallet } from "../hooks/useWallet";

export default function HeaderMenuAccount() {
  const { account, user, signin } = useWallet();

  const copyAddress = () => {
    navigator.clipboard.writeText(user?.address);
    toast.success("Copied!  👍");
  };
  return (
    <>
      <div className="page-title-overlap bg-accent pt-4">
        <div className="container d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between align-items-center mb-2 pt-2">
          <div className="d-flex align-items-center">
            <div
              className="img-thumbnail rounded-circle position-relative flex-shrink-0"
              style={{ width: "6.375rem" }}
            >
              <img
                className="rounded-circle"
                src={user?.avatar ? user.avatar : avatarDefaultTwo}
                width={"90px"}
                height={"90px"}
                alt={
                  user?.username
                    ? `@${user.username}`
                    : "RedLine Blockchain User"
                }
              />
            </div>
            <div className="ps-3">
              <h3 className="h5 mb-2 text-light">
                {user?.username
                  ? `@${user.username}`
                  : "RedLine Blockchain User"}
              </h3>
              <span className="d-block text-light fs-sm opacity-60">
                Joined {user?.created ? user.created : ""}
              </span>
            </div>
          </div>
          <div className="my-sm-0 my-3 text-sm-end pt-1">
            {/* 
            <div className="d-flex align-items-center text-nowrap fs-sm">
              <div className="mb-2 me-sm-3 me-2 text-muted">
                <span className="fw-medium text-light">0</span>{" "}
                <span className="text-white opacity-70">followers</span>
              </div>
              <div className="mb-2 ps-sm-3 ps-2 border-start border-light text-muted">
                <span className="fw-medium text-light">0</span>{" "}
                <span className="text-white opacity-70">following</span>
              </div>
            </div>
            */}
            <a className="text-light" href="#" onClick={() => copyAddress()}>
              {account &&
                `${account.slice(0, 10)}...${account.slice(
                  account.length - 4,
                  account.length
                )}`}
              <i
                className="ci-copy ms-2 fs-sm"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Copy"
              ></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
