import React, { useState } from "react";

export default function FavoriteButton(props) {
  const [favorite, setFavorite] = useState(props?.isLike);
  const like = async () => {
    let formData = new FormData();
    formData.append("market_ipfs_id", props?.tokenId);
    formData.append("network", process.env.REACT_APP_NETWORK_TYPE);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/like`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        setFavorite(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dislike = async () => {
    let formData = new FormData();
    formData.append("market_ipfs_id", props.tokenId);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/dislike`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        setFavorite(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <button
        className={`${
          props.detail
            ? "btn btn-secondary rounded-pill btn-icon me-sm-3 me-2"
            : "btn-wishlist btn-sm position-absolute top-0 end-0"
        } `}
        type="button"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        title={`${favorite ? "Remove to Favorites" : "Add to Favorites"} `}
        style={{ margin: "12px" }}
        onClick={() => (favorite ? dislike() : like())}
      >
        <i className={`${favorite ? "ci-heart-filled " : "ci-heart"} `}></i>
      </button>
    </>
  );
}
