import React, { useState, useEffect } from "react";
import BgImg from "../assets/img/nft/home/background.webp";
export default function ShowMultimediaDetail(props) {
  const [typeFiles, setTypeFiles] = useState(props?.type ? props?.type : null);
  const [file, setFiles] = useState(
    props.file?.size ? URL.createObjectURL(props?.file) : props?.file
  );

  useEffect(() => {
    //setTypeFiles(props?.type ? props?.type : "image");
    //setFiles(props?.file ? props?.file : BgImg);
  }, [props]);

  return (
    <div>
      {typeFiles.includes("image") && (
        <>
          <img
            className="image-zoom NFTDetail"
            src={file}
            data-zoom={file}
            alt={props?.alt ? props?.alt : "NFT"}
          />
          <div className="image-zoom-pane"></div>
        </>
      )}
      {typeFiles.includes("audio") && (
        <audio src={file} controls className="NFTDetail" />
      )}
      {typeFiles.includes("video") && (
        <video
          muted
          autoPlay
          loop
          controls
          id="full-screenVideo"
          className="NFTDetail"
        >
          <source src={file} type="video/mp4" />
        </video>
      )}
    </div>
  );
}
