import { useState } from "react";
import initialState from "../initialState";
import { utils, ethers } from "ethers";
import NFTVipABI from "../abi/NFTVip.json";
import CollectionABI from "../abi/Collection.json";
import CollectionABI1155 from "../abi/erc1155.json";
import MarketplaceABI from "../abi/Marketplace.json";
import MarketplaceABI1155 from "../abi/Marketplace1155.json";
import ERC721ABI from "../abi/erc721.json";
import SubscriptionABI from "../abi/Subscription.json";
import { erc20Abi } from "../abi/abi";
import Web3 from "web3";

const {
  REACT_APP_NEWTORK_MAINET,
  REACT_APP_RLC,
  REACT_APP_USDC,
  REACT_APP_CONTRACT_COLLECTION,
  REACT_APP_CONTRACT_COLLECTION_1155,
  REACT_APP_CONTRACT_MARKET,
  REACT_APP_CONTRACT_MARKET_1155,
  REACT_APP_CONTRACT_SUBSCRIPTION,
  REACT_APP_CONTRACT_NFT,
  REACT_APP_CONTRACT_NFT_GOLD,
  REACT_APP_CONTRACT_NFT_SILVER,
  REACT_APP_FEE_RLC,
  REACT_APP_FEE_MATIC,
  REACT_APP_ADDRESS_SOREN,
  REACT_APP_NETWORK_CHAIN,
  REACT_APP_TOKENURI,
} = process.env;

const webProvider = new Web3(
  new Web3.providers.HttpProvider(
    REACT_APP_NEWTORK_MAINET //process.env.REACT_APP_INFURA
  )
); // eslint-disable-line

const useInitialState = (key, initialValue) => {
  const [state, setState] = useState(initialState);

  const addUSD = (payload) => {
    setState((prevState) => {
      return {
        ...prevState,
        usd: payload,
      };
    });
  };

  const addRLC = (payload) => {
    console.log(state);
    setState((prevState) => {
      return {
        ...prevState,
        rlc: payload,
      };
    });
  };

  const addAlert = (payload) => {
    setState({
      ...state,
      alert: payload,
    });
  };

  const CallContractRLC = () => {
    const provider = new ethers.providers.JsonRpcProvider(
      REACT_APP_NEWTORK_MAINET
    );
    const addr = "0xF31777D123C86d5A239BF7253502c01B7bEC9738";
    let accounts = provider.listAccounts();
    console.log(accounts);
    const signer = provider.getSigner();
    console.log(signer);
    const priceFeed = new ethers.Contract(addr, NFTVipABI, provider);
    return priceFeed;
  };

  const getBalance = async (accountAddress) => {
    if (!accountAddress) return;
    let balanceTemp = await webProvider.eth.getBalance(accountAddress);
    balanceTemp =
      balanceTemp !== 0 ? webProvider.utils.fromWei(balanceTemp, "ether") : 0;
    return balanceTemp;
  };

  const getBalanceRLC = async (accountAddress) => {
    let tokenAddress = REACT_APP_RLC;
    let walletAddress = accountAddress;
    let contract = new webProvider.eth.Contract(erc20Abi, tokenAddress);
    let balance = await contract.methods
      .balanceOf(walletAddress)
      .call(function (error, result) {
        return result;
      });
    balance = balance !== 0 ? webProvider.utils.fromWei(balance, "ether") : 0;
    return balance;
  };

  const getBalanceUSDC = async (accountAddress) => {
    let tokenAddress = REACT_APP_USDC;
    let walletAddress = accountAddress;
    let contract = new webProvider.eth.Contract(erc20Abi, tokenAddress);
    let balance = await contract.methods
      .balanceOf(walletAddress)
      .call(function (error, result) {
        return result;
      });
    balance = balance !== 0 ? webProvider.utils.fromWei(balance, "mwei") : 0;
    return balance;
  };

  const getBalanceNFT = async (category) => {
    console.log("Categoria de Balance NFT " + category);
    let tokenAddress = "";
    if (category === "Gold") {
      tokenAddress = REACT_APP_CONTRACT_NFT_GOLD;
    } else if (category === "Silver") {
      tokenAddress = REACT_APP_CONTRACT_NFT_SILVER;
    } else if (category === "Bronze") {
      tokenAddress = REACT_APP_CONTRACT_NFT;
    }

    let contract = new webProvider.eth.Contract(NFTVipABI, tokenAddress);
    let balance = await contract.methods
      .totalSupply()
      .call(function (error, result) {
        return result;
      });
    return balance;
  };

  const approvedRLC = async (
    accountAddress,
    privateKey,
    price,
    money,
    standard
  ) => {
    console.log("Categoria de approvedRLC");
    const gasPrice = await webProvider.eth.getGasPrice();
    const count = await webProvider.eth.getTransactionCount(accountAddress);
    const nonce = webProvider.utils.toHex(count);
    let priceTemp = price * 1.1;
    let pricetoWei = webProvider.utils.toWei(`${priceTemp}`, "ether");
    let contractERC = "";
    if (money === "USDC") {
      contractERC = REACT_APP_USDC;
    } else {
      contractERC = REACT_APP_RLC;
    }
    const contract = new webProvider.eth.Contract(erc20Abi, contractERC);
    const contractAddress =
      standard === "ERC-721"
        ? REACT_APP_CONTRACT_MARKET
        : REACT_APP_CONTRACT_MARKET_1155;
    const data = contract.methods
      .approve(contractAddress, pricetoWei)
      .encodeABI();

    const txData = {
      gasPrice: gasPrice,
      gas: 1000000,
      nonce: nonce,
      data,
      from: accountAddress,
      to: contractERC,
    };

    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    return sentTx;
  };

  const approvedRLCSubscriptions = async (
    accountAddress,
    privateKey,
    price
  ) => {
    console.log("Categoria de approvedRLCSubscriptions");
    const gasPrice = await webProvider.eth.getGasPrice();
    const count = await webProvider.eth.getTransactionCount(accountAddress);
    const tokenContractRLC = REACT_APP_RLC;
    const nonce = webProvider.utils.toHex(count);

    const contract = new webProvider.eth.Contract(erc20Abi, tokenContractRLC);
    let priceTemp = price * 3;
    let pricetoWei = webProvider.utils.toWei(`${priceTemp}`, "ether");

    const data = contract.methods
      .approve(REACT_APP_CONTRACT_SUBSCRIPTION, pricetoWei)
      .encodeABI();

    const txData = {
      gasPrice: gasPrice,
      gas: 1000000,
      nonce: nonce,
      data,
      from: accountAddress,
      to: tokenContractRLC,
    };

    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    return sentTx;
  };

  const approvedAllNFT = async (accountAddress, privateKey) => {
    console.log("Aprobando NFT..  ");

    const gasPrice = await webProvider.eth.getGasPrice();
    const count = await webProvider.eth.getTransactionCount(accountAddress);
    const nonce = webProvider.utils.toHex(count);
    const contract = new webProvider.eth.Contract(
      ERC721ABI,
      REACT_APP_CONTRACT_MARKET
    );

    const data = contract.methods
      .setApprovalForAll(REACT_APP_CONTRACT_MARKET, true)
      .encodeABI();

    const txData = {
      gasPrice: gasPrice,
      gas: 1000000,
      nonce: nonce,
      data,
      from: accountAddress,
      to: REACT_APP_CONTRACT_COLLECTION,
    };

    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    return sentTx;
  };

  const signNFT = async (
    tokenId,
    tokenOwner,
    price,
    privateKey,
    standard,
    money
  ) => {
    const SIGNING_DOMAIN_NAME = "LAZY_MARKETPLACE";
    const SIGNING_DOMAIN_VERSION = "1";
    const chainId = REACT_APP_NETWORK_CHAIN;
    const contractAddress =
      standard === "ERC-721"
        ? REACT_APP_CONTRACT_MARKET
        : REACT_APP_CONTRACT_MARKET_1155;

    const signer = new ethers.Wallet(privateKey); // private key that I use for address 0x5B38Da6a701c568545dCfcB03FcB875f56beddC4
    let pricetoWei = 0;
    if (money === "USDC") {
      pricetoWei = webProvider.utils.toWei(`${price}`, "mwei");
    } else {
      pricetoWei = webProvider.utils.toWei(`${price}`, "ether");
    }

    const domain = {
      name: SIGNING_DOMAIN_NAME,
      version: SIGNING_DOMAIN_VERSION,
      verifyingContract: contractAddress,
      chainId,
    };

    const types = {
      structSalesOrder: [
        { name: "contractAddress", type: "address" }, // Contract Address Collections
        { name: "tokenId", type: "uint256" },
        { name: "tokenOwner", type: "address" },
        { name: "price", type: "uint256" },
        { name: "tokenURI", type: "string" },
        { name: "nonce", type: "uint256" },
      ],
    };

    const tokenURITemp = `${REACT_APP_TOKENURI}${tokenId}.json`;
    const voucher = {
      contractAddress:
        standard === "ERC-721"
          ? REACT_APP_CONTRACT_COLLECTION
          : REACT_APP_CONTRACT_COLLECTION_1155,
      tokenId: Number(tokenId),
      tokenOwner,
      price: pricetoWei,
      tokenURI: tokenURITemp,
      nonce: Number(tokenId), // Nonce
    };

    const signature = await signer._signTypedData(domain, types, voucher);
    const signerSave = `["${voucher.contractAddress}", ${voucher.tokenId}, "${voucher.tokenOwner}", "${voucher.price}", "${voucher.tokenURI}", ${voucher.nonce}, "${signature}"]`;
    return [signerSave, voucher.contractAddress];
  };

  const cancelNFT = async (address, privateKey, tokenId) => {
    const gasPrice = await webProvider.eth.getGasPrice();
    const count = await webProvider.eth.getTransactionCount(address);

    const nonce = webProvider.utils.toHex(count);
    /* const MarketplaceABIVAR =
      standard === "ERC-721" ? MarketplaceABI : MarketplaceABI1155;*/
    const contract = new webProvider.eth.Contract(
      MarketplaceABI,
      REACT_APP_CONTRACT_MARKET
    );

    const data = contract.methods.cancelSale(tokenId).encodeABI();
    const txData = {
      gasPrice: gasPrice,
      gas: 1000000,
      nonce: nonce,
      value: 0,
      data,
      from: address,
      to: REACT_APP_CONTRACT_MARKET,
    };

    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    return sentTx;
  };

  const sendTransactionNFT = async (
    address,
    addressto,
    contractAddress,
    tokenAddress,
    privateKey
  ) => {
    console.log(tokenAddress);
    console.log(privateKey);
    const count = await webProvider.eth.getTransactionCount(address);
    const nonce = webProvider.utils.toHex(count);
    const gasPrice = await webProvider.eth.getGasPrice();
    const adjustedGasLimit = Math.ceil(gasPrice * 1.1);
    const contract = new webProvider.eth.Contract(ERC721ABI, contractAddress);
    const value = webProvider.utils.toHex(webProvider.utils.toWei("0", "wei"));
    const data = contract.methods
      .transferFrom(address, addressto, tokenAddress)
      .encodeABI();
    const txData = {
      nonce,
      //gasPrice: gasPrice,
      gasPrice: webProvider.utils.toHex(gasPrice),
      // gasLimit: 200000,
      gasLimit: webProvider.utils.toHex(200000),
      data,
      to: contractAddress,
    };
    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    //const sentTx = await webProvider.eth.sendTransaction(txData);
    return sentTx;
  };

  const sendTransactionNFT1155 = async (
    address,
    addressto,
    contractAddress,
    tokenAddress,
    supply,
    privateKey
  ) => {
    console.log(tokenAddress);
    console.log(privateKey);
    const count = await webProvider.eth.getTransactionCount(address);
    const nonce = webProvider.utils.toHex(count);
    const gasPrice = await webProvider.eth.getGasPrice();
    const adjustedGasLimit = Math.ceil(gasPrice * 1.1);
    const contract = new webProvider.eth.Contract(
      CollectionABI1155,
      contractAddress
    );
    const bytesVacios = webProvider.utils.hexToBytes("0x");
    const value = webProvider.utils.toHex(webProvider.utils.toWei("0", "wei"));
    const data = contract.methods
      .safeTransferFrom(address, addressto, tokenAddress, supply, bytesVacios)
      .encodeABI();
    const txData = {
      nonce,
      gasPrice: webProvider.utils.toHex(adjustedGasLimit),
      gasLimit: webProvider.utils.toHex(200000),
      data,
      to: contractAddress,
    };
    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    //const sentTx = await webProvider.eth.sendTransaction(txData);
    return sentTx;
  };

  const burnNFT1155 = async (
    address,
    contractAddress,
    tokenAddress,
    supply,
    privateKey
  ) => {
    console.log(address, contractAddress, tokenAddress, supply, privateKey);
    const count = await webProvider.eth.getTransactionCount(address);
    const nonce = webProvider.utils.toHex(count);
    const gasPrice = await webProvider.eth.getGasPrice();
    const nuevoGasPrice = gasPrice * 1.5;
    const gasPriceHex = webProvider.utils.toHex(Math.floor(nuevoGasPrice));
    const contract = new webProvider.eth.Contract(
      CollectionABI1155,
      contractAddress
    );
    const data = contract.methods
      .burn(address, tokenAddress, supply)
      .encodeABI();
    const txData = {
      nonce,
      gasPrice: webProvider.utils.toHex(gasPrice),
      // gasLimit: 200000,
      gasLimit: webProvider.utils.toHex(200000),
      data,
      to: contractAddress,
    };
    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);
    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );
    return sentTx;
  };

  const paymentSubscription = async (
    address,
    privateKey,
    addressTo,
    price,
    money
  ) => {
    const gasPrice = await webProvider.eth.getGasPrice();
    const count = await webProvider.eth.getTransactionCount(address);

    const nonce = webProvider.utils.toHex(count);
    const contract = new webProvider.eth.Contract(
      SubscriptionABI,
      REACT_APP_CONTRACT_SUBSCRIPTION
    );

    let pricetoWei = webProvider.utils.toWei(`${price}`, "ether");

    let data;
    if (money === "RLC") {
      console.log("Se fue por RLC");
      data = contract.methods.buyRLC(addressTo, pricetoWei).encodeABI();
    } else {
      console.log("Se fue por MATIC");
      data = contract.methods.buy(addressTo, pricetoWei).encodeABI();
    }

    console.log(price);
    console.log("Author Address ", addressTo);

    const txData = {
      gasPrice: gasPrice,
      gas: 1000000,
      nonce: nonce,
      value: money === "MATIC" ? pricetoWei : 0,
      data,
      from: address,
      to: REACT_APP_CONTRACT_SUBSCRIPTION,
    };

    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);

    const sentTx = await webProvider.eth.accounts
      .signTransaction(txData, privateKey)
      .then((signedTx) =>
        webProvider.eth.sendSignedTransaction(signedTx.rawTransaction)
      );

    return sentTx;
  };

  const sumaValueEth = async (value1, value2) => {
    let gasTrx = value1 + value2;
    return webProvider.utils.fromWei(gasTrx, "ether");
  };

  const buyNFT = async (
    address,
    privateKey,
    tokenId,
    price,
    money,
    royalties,
    supplyMax,
    supply,
    signer,
    standard,
    signerId
  ) => {
    const gasPrice = await webProvider.eth.getGasPrice();
    const count = await webProvider.eth.getTransactionCount(address);
    const contractAddress =
      standard === "ERC-721"
        ? REACT_APP_CONTRACT_MARKET
        : REACT_APP_CONTRACT_MARKET_1155;

    const MarketplaceABIVAR =
      standard === "ERC-721" ? MarketplaceABI : MarketplaceABI1155;
    const nonce = webProvider.utils.toHex(count);
    const contract = new webProvider.eth.Contract(
      MarketplaceABIVAR,
      contractAddress
    );

    console.log(`"Precio" ${price} ${money}`);
    let royaltiesTemp = royalties * 100;
    let pricetoWei = webProvider.utils.toWei(`${price}`, "ether");
    let contractCall =
      standard === "ERC-721"
        ? REACT_APP_CONTRACT_COLLECTION
        : REACT_APP_CONTRACT_COLLECTION_1155;
    let data;
    console.log("Contracto de Mercado es", contractAddress);
    console.log("Contrato de collecion es", contractCall);
    console.log("ABI llamado es ", MarketplaceABIVAR);
    console.log("Contrato es ", contract);
    if (standard === "ERC-721") {
      console.log("Entro a llamar el contrato de ERC721");
      if (money === "USDC") {
        pricetoWei = webProvider.utils.toWei(`${price}`, "mwei");
        data = contract.methods
          .buyFromSalesOrderERC20(
            REACT_APP_USDC,
            address,
            contractCall,
            tokenId,
            pricetoWei,
            royaltiesTemp,
            JSON.parse(signer)
          )
          .encodeABI();
      } else if (money === "RLC") {
        data = contract.methods
          .buyFromSalesOrderToken(
            address,
            contractCall,
            tokenId,
            pricetoWei,
            royaltiesTemp,
            JSON.parse(signer)
          )
          .encodeABI();
      } else {
        data = contract.methods
          .buyFromSalesOrder(
            address,
            contractCall,
            tokenId,
            royaltiesTemp,
            JSON.parse(signer)
          )
          .encodeABI();
      }
    } else {
      console.log("Entro a llamar el contrato de ERC1155");
      if (money === "USDC") {
        console.log("ENTRO A USDC 1155");
        pricetoWei = webProvider.utils.toWei(`${price}`, "mwei");
        data = contract.methods
          .buyFromSalesOrderERC20(
            REACT_APP_USDC,
            address,
            contractCall,
            tokenId,
            supplyMax,
            supply,
            pricetoWei,
            royaltiesTemp,
            JSON.parse(signer)
          )
          .encodeABI();
      } else if (money === "RLC") {
        console.log("ENTRO A RLC 1155");
        data = contract.methods
          .buyFromSalesOrderToken(
            address,
            contractCall,
            tokenId,
            supplyMax,
            supply,
            pricetoWei,
            royaltiesTemp,
            JSON.parse(signer)
          )
          .encodeABI();
      } else {
        console.log("ENTRO A MATIC 1155");
        data = contract.methods
          .buyFromSalesOrder(
            address,
            contractCall,
            tokenId,
            supplyMax,
            supply,
            royaltiesTemp,
            JSON.parse(signer)
          )
          .encodeABI();
      }
    }
    console.log("Salio del encode ABI");

    const txData = {
      gasPrice: gasPrice,
      gas: 2000000,
      nonce: nonce,
      value: money === "MATIC" ? pricetoWei : 0,
      data,
      from: address,
      to: contractAddress,
    };

    const account = webProvider.eth.accounts.privateKeyToAccount(
      "0x" + privateKey
    );
    webProvider.eth.accounts.wallet.add(account);
    console.log(txData);
    // Firmar la transacción
    const signedTx = await webProvider.eth.accounts.signTransaction(
      txData,
      privateKey
    );
    const sentTx = webProvider.eth.sendSignedTransaction(
      signedTx.rawTransaction
    );
    sentTx.on("transactionHash", async (hash) => {
      console.log("Transaction Hash:", hash);
      const transaction = {
        transaction_hash: hash,
        status: "pending",
        operation_type: "BUY NFT",
        sender_address: address,
        value: pricetoWei,
        tokenId: tokenId,
        supply: supply,
        signer_id: signerId,
        price: price,
        money: money,
        timestamp: new Date().toISOString(),
        blockNumber: null,
        gas_used: null,
        gas_price: gasPrice,
        input: data,
        logs: null,
        transaction_receipt: null,
      };
      await addTransactionData(transaction);
    });
    sentTx.on("receipt", async (receipt) => {
      console.log("Transaction Receipt:", receipt);
      const updateData = {
        transaction_hash_two: receipt.transactionHash,
        status: receipt.status ? "success" : "failed",
        block_number: receipt.blockNumber,
        gas_used: receipt.gasUsed,
        logs: JSON.stringify(receipt.logs),
        transaction_receipt: JSON.stringify(receipt),
      };
      await updateTransactionData(updateData);
    });
    sentTx.on("error", async (error) => {
      console.error("Error:", error);
      const updateData = {
        transaction_hash_two: error.receipt.transactionHash,
        status: "failed",
      };
      await updateTransactionData(updateData);
    });

    return sentTx;
  };

  const gasEstimatedBalance = async (account, tokenId, standard, supply) => {
    const contractAddress =
      standard === "ERC-721"
        ? REACT_APP_CONTRACT_COLLECTION
        : REACT_APP_CONTRACT_COLLECTION_1155;

    const coollectionAb =
      standard === "ERC-721" ? CollectionABI : CollectionABI1155;

    const contract = new webProvider.eth.Contract(
      coollectionAb,
      contractAddress
    );
    const tokenTemp = 3000000;
    const accountTemp = "0x65Aa41dB4C2fD1A574b2DAa967c68771e6f22435";
    if (standard === "ERC-721") {
      const data = contract.methods
        .safeMint(accountTemp, tokenTemp, REACT_APP_TOKENURI, 10)
        .encodeABI();
      const gasPrice = await webProvider.eth
        .estimateGas({
          value: 0,
          data: data,
          from: accountTemp,
          to: contractAddress,
        })
        .then(async (result) => {
          const gasPrice = await webProvider.eth
            .getGasPrice()
            .then((result) => {
              console.log(result);
              return result;
            });
          let gasTrx = result + gasPrice;
          return webProvider.utils.fromWei(gasTrx, "ether");
        });
      return gasPrice;
    } else {
      const bytesVacios = webProvider.utils.hexToBytes("0x");
      const data = contract.methods
        .safeMint(
          accountTemp,
          tokenTemp,
          supply,
          bytesVacios,
          REACT_APP_TOKENURI,
          10
        )
        .encodeABI();
      const gasPrice = await webProvider.eth
        .estimateGas({
          value: 0,
          data: data,
          from: accountTemp,
          to: contractAddress,
        })
        .then(async (result) => {
          const gasPrice = await webProvider.eth
            .getGasPrice()
            .then((result) => {
              return result;
            });
          let gasTrx = result + gasPrice;
          return webProvider.utils.fromWei(gasTrx, "ether");
        });
      return gasPrice;
    }
  };

  const addTransactionData = async (data) => {
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/add-transaction`);
    fetch(`${decodeURIComponent(url)}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(`Error sending data to ${url}:`, error);
      });
  };

  const updateTransactionData = async (data) => {
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/update-transaction`
    );
    fetch(`${decodeURIComponent(url)}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(`Error sending data to ${url}:`, error);
      });
  };

  return {
    CallContractRLC,
    addUSD,
    addAlert,
    addRLC,
    getBalance,
    getBalanceRLC,
    getBalanceUSDC,
    signNFT,
    buyNFT,
    paymentSubscription,
    cancelNFT,
    approvedRLC,
    approvedAllNFT,
    approvedRLCSubscriptions,
    getBalanceNFT,
    sumaValueEth,
    gasEstimatedBalance,
    sendTransactionNFT,
    sendTransactionNFT1155,
    burnNFT1155,
    state,
  };
};

export default useInitialState;
