import React, { useState, useEffect, useContext } from "react";
import { useWallet } from "../hooks/useWallet";
import { Link } from "react-router-dom";

export default function SidebarMenuAccount() {
  const { signout } = useWallet();
  const [show, setShow] = useState(false);

  const disconnectWallet = () => {
    localStorage.removeItem("accountAddress");
    signout();
    window.location.reload();
  };

  return (
    <>
      <aside className="col-lg-3 pe-xl-5">
        <div className="d-block d-lg-none px-4 py-1">
          <a
            className="btn btn-outline-accent d-block"
            href="#account-menu"
            onClick={() => setShow(!show)}
            data-bs-toggle="collapse"
          >
            <i className="ci-menu me-2"></i>Account menu
          </a>
        </div>
        <div className="h-100 border-end mb-2">
          <div
            className={`d-lg-block collapse  ${show ? "show " : ""} `}
            id="account-menu"
          >
            <ul className="list-unstyled mb-0">
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  to={"/account/setting"}
                >
                  <i className="ci-settings opacity-60 me-2"></i>Profile
                  Settings
                </Link>
              </li>
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  to={"/feed?page=my"}
                >
                  <i className="ci-document opacity-60 me-2"></i>My Feed
                </Link>
              </li>
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  to={"/account/items"}
                >
                  <i className="ci-image opacity-60 me-2"></i>My NFTs
                </Link>
              </li>
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  to={"/account/collection"}
                >
                  <i className="ci-view-list opacity-60 me-2"></i>My Collections
                </Link>
              </li>
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  to={"/account/subscriptions"}
                >
                  <i className="ci-user opacity-60 me-2"></i>My Subscriptions
                </Link>
              </li>
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  to={"/account/packages"}
                >
                  <i className="ci-coins opacity-60 me-2"></i>My Packages
                </Link>
              </li>
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  to={"/account/favorites"}
                >
                  <i className="ci-heart opacity-60 me-2"></i>Favorites
                </Link>
              </li>
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  to={"/account/notifications"}
                >
                  <i className="ci-bell opacity-60 me-2"></i>
                  Notifications
                </Link>
              </li>
              <li className="border-bottom mb-0">
                <Link
                  className="nav-link-style d-flex align-items-center px-4 py-3"
                  onClick={disconnectWallet}
                  to="#"
                >
                  <i className="ci-sign-out opacity-60 me-2"></i>Sign out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}
