import React, { useState, useEffect } from "react";
import Audio from "../assets/img/nft/home/audio.webp";
import AudioJPG from "../assets/img/nft/home/audio.jpg";
import BgImg from "../assets/img/nft/home/background.webp";
export default function ShowMultimedia(props) {
  const [thumbailVideo, setThumbailVideo] = useState();
  const [typeFiles, setTypeFiles] = useState(props.type ? props.type : null);
  const [file, setFiles] = useState(
    props.file?.size ? URL.createObjectURL(props.file) : props.file
  );

  useEffect(() => {
    setTypeFiles(props.type ? props.type : "image");
    setFiles(props.file ? props.file : BgImg);
  }, [props]);

  return (
    <div>
      {typeFiles.includes("image") && (
        <img
          src={file}
          alt={props?.alt ? props?.alt : "NFT"}
          className={"NFTimage"}
          loading={"lazy"}
        />
      )}
      {typeFiles.includes("audio") && (
        <img
          src={AudioJPG}
          alt={props?.alt ? props?.alt : "NFT"}
          className={"NFTimage"}
          loading={"lazy"}
        />
      )}
      {typeFiles.includes("video") && (
        <>
          <div className="buttonPlay">
            <i className="ci-play-circle " />
          </div>
          <video src={`${file}#t=1`} className={"NFTimage"}></video>
        </>
      )}
    </div>
  );
}
