import React, { useState, useEffect, useContext } from "react";
import { useWallet } from "../hooks/useWallet";
import AppContext from "../context/AppContex";
import { setConstantValue } from "typescript";
export default function Modal(props) {
  const [balance, setBalance] = useState("0");
  const [blink, setBlink] = useState(false);
  const [balanceRLC, setBalanceRLC] = useState("0");
  const [balanceUSDC, setBalanceUSDC] = useState("0");
  const [total, setTotal] = useState("0");
  const [totalERC20, setTotalERC20] = useState("0");
  const [estimateGas, setEstimateGas] = useState("0");
  const [supply, setSupply] = useState(1);
  const { account } = useWallet();
  const {
    state,
    getBalanceRLC,
    getBalanceUSDC,
    getBalance,
    gasPriceBalance,
    gasEstimatedBalance,
  } = useContext(AppContext);

  useEffect(() => {
    getBalanceWallet();
    getGasPrice();
  }, [account]);

  const getBalanceWallet = async () => {
    console.log(account);
    if (account !== "") {
      let balanceUSDCTemp = await getBalanceUSDC(account);
      let balanceRLCTemp = await getBalanceRLC(account);
      let balanceMaticTemp = await getBalance(account);
      setBalance(balanceMaticTemp);
      setBalanceRLC(balanceRLCTemp);
      setBalanceUSDC(balanceUSDCTemp);
    }
  };

  useEffect(() => {
    getGasPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supply]);


  const getGasPrice = async () => {
    const tokenId = props?.data?.token_id ? props?.data?.token_id : props?.data?.id;
    const supplyCount = supply ? supply : props?.data?.supply;
    let gasEstimated = await gasEstimatedBalance(
      props?.data?.address,
      tokenId,
      props?.data?.standard,
      supplyCount,
    );
    console.log("Gas estimado", gasEstimated);
    setEstimateGas(gasEstimated);
    let totalMATIC = 0;
    let totalERC20 = 0;
    if (props?.data?.type === "1") {
      if (props?.data?.money !== "MATIC") {
        totalERC20 = props?.signer?.price_fixed * supplyCount;
      } else {
        totalMATIC = props?.signer?.price_fixed * supplyCount;
      }
    } else if (props?.data?.type === "2") {
      if (props?.data?.money !== "MATIC") {
        totalERC20 = props?.signer?.price_minium_bid * supplyCount;
      } else {
        totalMATIC = props?.signer?.price_minium_bid * supplyCount;
      }
    }
    let priceTotalString = parseFloat(totalMATIC) + parseFloat(gasEstimated);
    let priceTotalERC20String = parseFloat(totalERC20);
    setTotal(priceTotalString.toString());
    setTotalERC20(priceTotalERC20String.toString());
  };

  useEffect(function () {
    const myInterval = setInterval(() => {
      getGasPrice();
      setBlink(!blink);
    }, 10000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <div
        className={`modal fade show `}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Complete Transaction - {props?.operation}
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 col-sm-6 divMedia">
                  <p>Amount of NFT to buy
                    <br />
                    <small >
                      Max {props?.signer?.supply}
                    </small>
                  </p>
                </div>
                <div className="col-md-6 col-sm-6 divMedia">
                  <input
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    className="form-control"
                    defaultValue={1}
                    min="1"
                    max={props?.signer?.supply}
                    onChange={(e) => setSupply(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 divMedia">
                  <p>
                    Estimated gas fee
                    <br />
                    <small
                      style={{ color: "#6bea81" }}
                      className={`${blink ? "blink_me" : ""} `}
                    >
                      Likely in 30 seconds
                    </small>
                  </p>
                </div>
                <div className="col-md-6 col-sm-6 divMedia">
                  <p className={`${blink ? "blink_me" : ""} `}>
                    {`${estimateGas.slice(0, 20)}`}
                    <br />
                    <small className={`${blink ? "blink_me" : ""} `}>
                      Max fee: {`${estimateGas.slice(0, 20)}`}
                    </small>
                  </p>
                </div>
              </div>
              <hr className="solid" />
              <div className="row">
                <div className="col-md-6 col-sm-6 divMedia">
                  <p>
                    Total
                    <br />
                    <small>Amount + Gas</small>
                  </p>
                </div>
                <div className="col-md-6 col-sm-6 divMedia">
                  <p className={`${blink ? "blink_me" : ""} `}>
                    {`${total.slice(0, 15)}`} <strong>MATIC</strong>
                    <br />
                    {props?.data?.money != "MATIC" && (
                      <>
                        {`${totalERC20.slice(0, 15)}`}{" "}
                        <strong>{props?.data?.money}</strong>
                      </>
                    )}
                  </p>
                </div>
              </div>
              <hr className="solid" />
              <div className="row">
                <div className="col-md-6 col-sm-6 divMedia">
                  <p>Balance MATIC</p>
                </div>
                <div className="col-md-6 col-sm-6 divMedia">
                  <p
                    className={`${Number(total) > Number(balance) ? "balanceError" : ""
                      } `}
                  >{`${balance.slice(0, 20)}`}</p>
                </div>
              </div>
              <hr className="solid" />
              <div className="row">
                <div className="col-md-6 col-sm-6 divMedia">
                  <p>Balance RLC</p>
                </div>
                <div className="col-md-6 col-sm-6 divMedia">
                  <p
                    className={`${Number(totalERC20) > Number(balanceRLC)
                      ? "balanceError"
                      : ""
                      } `}
                  >{`${balanceRLC.slice(0, 20)}`}</p>
                </div>
              </div>
              <hr className="solid" />
              {/*
              <div className="row">
                <div className="col-md-6 col-sm-6 divMedia">
                  <p>Balance USDC</p>
                </div>
                <div className="col-md-6 col-sm-6 divMedia">
                  <p
                    className={`${Number(totalERC20) > Number(balanceUSDC)
                      ? "balanceError"
                      : ""
                      } `}
                  >{`${balanceUSDC.slice(0, 20)}`}</p>
                </div>
              </div>
                    */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-accent"
                disabled={
                  Number(total) > Number(balance) ||
                  Number(totalERC20) > Number(balanceRLC) ||
                  props?.loading || Number(supply) <= 0 || Number(supply) > Number(props?.signer?.supply)
                }
                onClick={() => props?.handleSigner(supply)}
              >
                Send Signature
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
