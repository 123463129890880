import React, { useState, useEffect, useContext } from "react";
import { Skeleton, SkeletonCircle, SkeletonText, Stack } from '@chakra-ui/react'
export default function Loading() {

    return (
        <>
            <div className="bg-light shadow-lg rounded-3 px-4 py-lg-4 py-3 mb-5">
                <div className="cover-image-skeleton" />
            </div>
        </>
    );
}
