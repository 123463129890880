import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useWallet } from "../hooks/useWallet";
export default function Notifications() {
  const { user } = useWallet();
  const [listNotifications, setListNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      getNotifications();
    }
  }, [user]);

  useEffect(function () {
    const myInterval = setInterval(() => {
      if (user?.id) {
        getNotifications();
      }
    }, 10000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const getNotifications = () => {
    setLoading(true);
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/notifications/`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setListNotifications(response?.data?.items);
        setTotalNotifications(response?.data?.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const changeNotifications = (id, link) => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/notifications/${id}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        getNotifications();
        navigate(link);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Link to={`/feed/notifications`} className="nocolor">
        <div className="badge-wrap" role="button">
          <i className="navbar-tool-icon ci-bell "></i>
          {totalNotifications > 0 && (
            <div className="badge-with-number" style={{ lineHeight: "12px" }}>
              {totalNotifications > 9 ? "9+" : totalNotifications}
            </div>
          )}
        </div>
      </Link>
      <div className="dropdown-menu dropdown-menu-end">
        <div style={{ minWidth: "14rem" }}>
          <div
            className="dropdown-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h6>Notifications</h6>
            <Link to={`/feed/notifications`}>
              All <i className="ci ci-list mx-2" role="button" />
            </Link>
          </div>

          {listNotifications.length > 0 &&
            listNotifications?.map((item, index) => (
              <div
                role="button"
                className="dropdown-item align-items-center"
                key={index}
                onClick={() => changeNotifications(item?.id, item?.link)}
              >
                <div className="d-inline-flex align-items-center">
                  <div>
                    <i
                      className={`${
                        item.view === "0" ? "colorPrimary" : "opacity-60"
                      }  me-2 `}
                    >
                      ●
                    </i>
                  </div>
                  <div>
                    <p className="nomargin">{item?.title}</p>
                    <p className="nomargin">{item?.description}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
