import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import FavoriteButton from "../../components/FavoriteButton";
import Countdown from "../../components/CountDown";
import { useSearchParams } from "react-router-dom";
import ShowMultimedia from "../../components/ShowMultimedia";
import ShowOwnerToken from "../../components/ShowOwnerToken";
import "react-toastify/dist/ReactToastify.css";

export default function CollectionExplorer() {
  let { contract, slug } = useParams();
  const [token, setToken] = useState(undefined);
  const [listToken, setListToken] = useState([]);
  const [collection, setCollection] = useState([]);
  const [activeTab, setActiveTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getCollection();
    console.log(contract)
    console.log(slug)
  }, [slug]);

  const getCollection = () => {
    setLoading(true);
    let userId = JSON.parse(localStorage.getItem("user"));
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/collection-public/${slug}`
    );
    url.searchParams.append("userId", userId?.id ? userId?.id : undefined);
    fetch(`${decodeURIComponent(url)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response?.collection) {
          setCollection(response?.collection);
        }
        if (response?.data) {
          setListToken(response?.data || []);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <main className="page-wrapper">
        <img
          src={collection?.banner}
          alt={collection?.name}
          className="img-responsive"
          style={{ width: "100%", height: "auto", maxHeight: '300px', objectFit: 'cover' }}
        />
        <div className="pt-4  bg-darker">
          <div className="container pt-2 pb-3 pt-lg-3 ">
            <div className="d-lg-flex justify-content-between pb-3">
              <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li className="breadcrumb-item">
                      <Link className="text-nowrap" to="/feed">
                        <i className="ci-home"></i>Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-nowrap">
                      <Link to={"/explorer"}>NFT Marketplace</Link>
                    </li>
                    <li
                      className="breadcrumb-item text-nowrap active"
                      aria-current="page"
                    >
                      Collection NFT
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-2 pb-2 bg-darker">
          <div className="container pt-2 pb-3 pt-lg-3 pb-lg-4">
            <div className="d-lg-flex justify-content-between pb-3">
              <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                <h1 className="h3 text-light mb-0">
                  {`${collection?.name
                    ? collection?.name?.slice(0, 100)
                    : "Loading..."
                    }`}
                </h1>
                <span
                  className="fs-sm text-light "
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {`${collection?.description
                    ? collection?.description?.slice(0, 5000)
                    : "Loading..."
                    }`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container pb-5 mb-2 mb-md-4">
          <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 pt-3">
            {listToken &&
              listToken?.map((item, index) => (
                <div className="col mb-2" key={index}>
                  <article className="card h-100 border-0 shadow">
                    <div className="card-img-top position-relative overflow-hidden">
                      <Link
                        className="d-block"
                        to={`/nft/view/${item.contract
                          ? item.contract
                          : process.env.REACT_APP_CONTRACT_COLLECTION
                          }/${item.id}`}
                      >
                        <ShowMultimedia
                          file={item?.multimedia}
                          alt={item?.name}
                          type={item?.type_multimedia}
                        />
                      </Link>
                      {item?.type === "2" && (
                        <Countdown
                          model="minimalist"
                          dateEnd={item?.bid_expiration_date}
                          dateStart={item?.bid_starting_date}
                        />
                      )}
                      <FavoriteButton
                        isLike={item?.isLike}
                        tokenId={item?.id}
                      />
                    </div>
                    <div className="card-body">
                      <h3 className="product-title mb-2 fs-base">
                        <Link
                          className="d-block text-truncate"
                          to={`/nft/view/${item.contract
                            ? item.contract
                            : process.env.REACT_APP_CONTRACT_COLLECTION
                            }/${item.id}`}
                        >
                          {item?.name}
                        </Link>
                      </h3>
                      <span className="fs-sm text-muted">
                        Current {item?.type === "1" && "Price"}
                        {item?.type === "2" && "Minimum Bid"}
                        {item?.type === "3" && "Membership Suncription"}:
                      </span>
                      <div className="d-flex align-items-center flex-wrap">
                        <h4 className="mt-1 mb-0 fs-base text-darker">
                          {item?.standard === "ERC-1155" && item?.type === "1" ? "See Price" : item?.price_fixed + " " + item?.money}
                          {item?.type === "2" && item?.price_minimum_bid}{" "}
                        </h4>
                        {/* 
                              <span className="mt-1 ms-1 fs-xs text-muted">
                                (≈ $ 2,000.55)
                              </span>
                              */}
                      </div>
                    </div>
                    <div className="card-footer mt-n1 py-0 border-0">
                      <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                        <ShowOwnerToken owners={item?.owners} />
                      </div>
                    </div>
                  </article>
                </div>
              ))}
          </div>
          <hr className="mt-4 mb-3" />
        </div>
      </main>
    </>
  );
}
