import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import avatarDefaultTwo from "../../assets/img/nft/vendor/avatar.png";
import avatarDefaultSquare from "../../assets/img/nft/vendor/avatar-square.jpg";
import { useWallet } from "../../hooks/useWallet";
import AppContext from "../../context/AppContex";
import { ToastContainer, toast } from "react-toastify";
import { useLocalStorage } from "../../hooks/useLocalState";
import SidebarMenuAccount from "../../components/SidebarMenuAccount";
import HeaderMenuAccount from "../../components/HeaderMenuAccount";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";

export default function MyNotifications() {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [activeSlide] = useState(true);
  const [loading, setLoading] = useState(false);
  const [checkboxAll, setCheckboxAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    nft: true,
    follow: true,
    subscription: true,
  });
  const { account, user, signin } = useWallet();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    getInformation();
  }, [user]);

  const getInformation = async () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/notifications-settings`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response?.data) {
          setCheckboxes({
            nft: response?.data?.nft === "1" ? true : false,
            follow: response?.data?.follow === "1" ? true : false,
            subscription: response?.data?.subscription === "1" ? true : false,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = () => {
    setLoading(true);
    console.log(checkboxes);
    var formData = new FormData();
    formData.append("nft", checkboxes.nft ? "1" : "0");
    formData.append("follow", checkboxes.follow ? "1" : "0");
    formData.append("subscription", checkboxes.subscription ? "1" : "0");
    fetch(`${process.env.REACT_APP_API_BACKEND}/notifications-settings`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        getInformation();
        setLoading(false);
        toast.success("Notification settings updated.   😀");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleAll = () => {
    console.log(checkboxAll);
    if (checkboxAll === true) {
      setCheckboxes({
        nft: false,
        follow: false,
        subscription: false,
      });
      setCheckboxAll(false);
    } else {
      setCheckboxes({
        nft: true,
        follow: true,
        subscription: true,
      });
      setCheckboxAll(true);
    }
  };
  return (
    <>
      <main className="page-wrapper">
        <HeaderMenuAccount />
        <div className="container mb-5 pb-3">
          <div className="bg-light shadow-lg rounded-3 overflow-hidden">
            <div className="row">
              <SidebarMenuAccount />
              <section className="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                  <h1 className="h3 mb-4 pb-2 text-sm-start text-center">
                    Notifications
                  </h1>
                  <form>
                    <div className="bg-secondary rounded-3 p-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="nf-disable-all"
                          onChange={() => handleAll()}
                        />
                        <label
                          className="form-check-label fw-medium"
                          htmlFor="nf-disable-all"
                          checked={checkboxAll}
                        >
                          Enable/disable all notifications
                        </label>
                      </div>
                    </div>
                    <div id="notification-settings">
                      <div className="border-bottom p-4">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="nf-product-sold"
                            {...register("nft")}
                            onChange={() =>
                              setCheckboxes({
                                ...checkboxes,
                                nft: !checkboxes.nft,
                              })
                            }
                            checked={checkboxes?.nft}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="nf-product-sold"
                          >
                            NFT
                          </label>
                        </div>
                        <div className="form-text">
                          Send an notifications when someone purchase, offers,
                          change of status of NFT.
                        </div>
                      </div>
                      <div className="border-bottom p-4">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="nf-product-updated"
                            onChange={() =>
                              setCheckboxes({
                                ...checkboxes,
                                follow: !checkboxes.follow,
                              })
                            }
                            checked={checkboxes?.follow}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="nf-product-updated"
                          >
                            Follow
                          </label>
                        </div>
                        <div className="form-text">
                          Send an notifications when a follow and interact with
                          me
                        </div>
                      </div>
                      <div className="border-bottom p-4">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="nf-product-comment"
                            onChange={() =>
                              setCheckboxes({
                                ...checkboxes,
                                subscription: !checkboxes.subscription,
                              })
                            }
                            checked={checkboxes?.subscription}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="nf-product-comment"
                          >
                            Subscription
                          </label>
                        </div>
                        <div className="form-text">
                          Send an notifications when a subscribe, change value
                          or create new content.
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-sm-row flex-column mt-4">
                      <button
                        className="btn btn-accent"
                        type="submit"
                        disabled={loading}
                        onClick={() => onSubmit()}
                      >
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
