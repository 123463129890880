import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useWallet } from "../../hooks/useWallet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import RichTextEditor from 'react-rte';

export default function FeedEdit() {
  let { postId } = useParams();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [typeFiles, setTypeFiles] = useState(undefined);
  const [audienceList, setAudienceList] = useState(new Set(["Public"]));
  const [myplan, setMyPlan] = useState([]);
  const [multimedia, setMultimedia] = useState("");
  const [loading, setLoading] = useState(false);
  const [richeditor, setRicheditor] = useState(RichTextEditor.createEmptyValue());
  const [textEdit, setTextEdit] = useState();
  const { account, user, signin } = useWallet();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const watchAllFields = watch();

  useEffect(() => {
    if (user) {
      getMyPlan();
      getPost();
    }
  }, [user]);

  const getPost = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/subscriptions/${postId}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("userId", user?.id);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        reset(response.data);
        const defaultValue = RichTextEditor.createValueFromString(response?.data?.description, 'html');
        setRicheditor(defaultValue)
        setMultimedia(response?.data?.multimedia);
        setAudienceList(new Set(JSON.parse(response?.data?.type)));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMyPlan = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/my-plan-subscriptions/${user.id}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setMyPlan(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const selectFile = async (event) => {
    setTypeFiles(event.target.files[0].type);
    setLoading(true);
    let uploadFile = new FormData();
    uploadFile.append("folder", "feed");
    uploadFile.append("address", account);
    uploadFile.append("file", event.target.files[0]);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/s3-upload`, {
      method: "POST",
      body: uploadFile,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        console.log(response.data);
        setMultimedia(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    setLoading(true);
    var formData = new FormData();
    formData.append("description", textEdit);
    if (typeFiles) {
      formData.append("multimedia", multimedia);
      formData.append("type_multimedia", typeFiles);
    }
    formData.append("type", JSON.stringify([...audienceList]));
    fetch(`${process.env.REACT_APP_API_BACKEND}/subscriptions/${postId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(Object.fromEntries(formData)),
    })
      .then((response) => response.json())
      .then((response) => {
        toast.success("Post updated! 😀");
        const timer = setTimeout(() => {
          window.location.replace(`/author/${user.id}`);
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function handleCheckboxCategory(itemKey) {
    const newSelectedItems = new Set(audienceList);
    if (!newSelectedItems.has(itemKey)) {
      newSelectedItems.add(itemKey);
    } else {
      newSelectedItems.delete(itemKey);
    }
    console.log(newSelectedItems);
    setAudienceList(newSelectedItems);
  }

  const handleChange = (newValue) => {
    setRicheditor(newValue);
    setTextEdit(newValue.toString('html'))
  };
  return (
    <>
      <main className="page-wrapper">
        <div className="bg-accent mb-4 py-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/feed">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-nowrap">
                    <Link to={"/explorer"}>NFT Marketplace</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    Edit feed post
                  </li>
                </ol>
              </nav>
            </div>
            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
              <h1 className="h3 text-light mb-0">Edit feed post</h1>
            </div>
          </div>
        </div>

        <section className="container mb-5 pt-3 pb-md-5 pb-1">
          <div className="row gy-5">
            <form
              className="needs-validation col-md-8"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <h2 className="h5 mb-2 pb-1">Upload file</h2>
                <p className="mb-4 fs-sm">
                  File types supported: PNG, JPG, GIF, WEBP, MP3 or MP4. Max
                  size: 100mb.
                </p>

                <div className="file-drop-area">
                  <>
                    <img
                      src={multimedia}
                      alt="Preview"
                      className="img-thumbnail"
                      width={200}
                      height={200}
                      style={{ display: "block", margin: "auto" }}
                    />
                  </>
                  <input
                    className="file-drop-input"
                    type="file"
                    onChange={selectFile}
                    id="file-upload"
                    accept="image/png, image/jpeg, image/gif"
                    disabled={loading}
                  />

                  <label
                    htmlFor="file-upload"
                    className="file-drop-btn btn btn-outline-accent"
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    ) : (
                      <i className="ci-loading me-2"></i>
                    )}
                    Or select file
                  </label>
                </div>
              </div>

              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <h2 className="h5 mb-3">Details</h2>
                <div className="row gy-md-4 gy-3">
                  <div className="col-12">
                    <label className="form-label" htmlFor="details-title">
                      Post Title<span className="ms-1 text-danger">*</span>
                    </label>
                    <RichTextEditor
                      value={richeditor}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="pt-2">
                  <button
                    className="btn btn-lg btn-accent d-block w-100"
                    type="submit"
                    disabled={loading}
                  >
                    Update post
                  </button>
                </div>
              </div>
            </form>

            <aside className="col-lg-3 offset-lg-1 col-md-4 position-relative">
              <div className="sticky-top" style={{ top: "100px" }}>
                <h4 className="h5 mb-3 text-md-start text-center">
                  Audience type?
                </h4>
                <p>Define the public that can see this publication</p>
                <div
                  className="card border-0 shadow mx-auto"
                  style={{ maxWidth: "500px" }}
                >
                  <div className="card-body">
                    <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={audienceList.has("Public")}
                          onChange={() => handleCheckboxCategory("Public")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Plan Public
                        </label>
                      </div>
                      {myplan.length > 0 &&
                        myplan?.map((item, index) => (
                          <div className="form-check" key={index}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={audienceList.has(item.plan)}
                              onChange={() => handleCheckboxCategory(item.plan)}
                            />
                            <label className="form-check-label">
                              Plan {item.plan}{" "}
                              <small>
                                {item?.price} {item?.money}{" "}
                              </small>
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </main>
    </>
  );
}
