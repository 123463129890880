import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import avatarDefaultTwo from "../../assets/img/nft/vendor/avatar.png";
import avatarDefaultSquare from "../../assets/img/nft/vendor/avatar-square.jpg";
import Countdown from "../../components/CountDown";
import { useWallet } from "../../hooks/useWallet";
import AppContext from "../../context/AppContex";
import ImgBlock from "../../assets/img/block.png";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import PreviewMultimedia from "../../components/PreviewMultimedia";
import "react-toastify/dist/ReactToastify.css";
import ModalSubscriptions from "../../components/ModalSubscriptions";
import ModalDelete from "../../components/ModalDelete";
import ModalSubscriptionCancel from "../../components/ModalSubscriptionCancel";
import ModalFollows from "../../components/ModalFollows";
import ShowMultimedia from "../../components/ShowMultimedia";
import ShowOwnerToken from "../../components/ShowOwnerToken";
export default function Author() {
  let { contract, authorId } = useParams();
  const [author, setAuthor] = useState();
  const [listToken, setListToken] = useState([]);
  const [listCollection, setLitstCollection] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [packages, setPackages] = useState([]);
  const [listFeed, setLitstFeed] = useState([]);
  const [mysubscription, setMysubscription] = useState([]);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("feed");
  const [followers, setFollowers] = useState(0);
  const [following, setFollowing] = useState(0);
  const [followState, setFollowState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tipsTooltip, setTipsTooltip] = useState(true);
  const [showModal, setshowModal] = useState(false);
  const [offcanvas, setOffCanvas] = useState(false);
  const [readMore, setReadmore] = useState(false);
  const [showModalDelete, setshowModalDelete] = useState(false);
  const [showModalFollow, setshowModalFollow] = useState(false);
  const [showModalSubscriptionCancel, setshowModalSubscriptionCancel] =
    useState(false);
  const [deleteInfo, setDeleteInfo] = useState({
    type: "post",
    id: null,
  });
  const [followInfo, setfollowInfo] = useState({
    type: "",
    id: null,
  });
  const { user } = useWallet();

  const { state } = useContext(AppContext);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    getAllNFTCards();
    getFollows();
  }, [user && authorId]);

  useEffect(() => {
    getAllNFTCards();
  }, []);

  useEffect(() => {
    if (searchParams.get("creator")) {
      setActiveTab("created");
    }
  }, [searchParams]);

  const getAllNFTCards = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/author/${authorId}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("userId", user?.id);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setAuthor(response.data);
        setListToken(response.data?.ipfs);
        setLitstCollection(response.data?.collection);
        setLitstFeed(response.data?.feed);
        setPackages(response.data?.packages);
        setMysubscription(
          response.data?.mysubscriptions.length > 0
            ? response.data?.mysubscriptions
            : null
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getFollows = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/follow/${authorId}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("userId", user?.id);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setFollowers(response.followers);
        setFollowing(response.following);
        setFollowState(response.follow ? true : false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const follow = async (data) => {
    const id = toast.loading("Please wait...");
    var dataForm = new FormData();
    dataForm.append("market_follow_id", authorId);
    dataForm.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${process.env.REACT_APP_API_BACKEND}/follow`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        toast.update(id, {
          render: "Following! 😎",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setFollowState(true);
        getFollows();
      })
      .catch((error) => {
        toast.update(id, {
          render: "Something went wrong try again ",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.log(error);
      });
  };
  const handleDeletedContent = async (id) => {
    setLoading(true);
    if (deleteInfo.id === null) {
      return;
    }
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/subscriptions/${deleteInfo.id}`
    );
    fetch(`${url}`, {
      method: "delete",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        getAllNFTCards();
        setLoading(false);
        setshowModalDelete(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleRemoveSubscription = async () => {
    setLoading(true);
    if (mysubscription[0].id === null) {
      return;
    }
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/remove-subscriptions/${mysubscription[0].id}`
    );
    fetch(`${url}`, {
      method: "delete",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        getAllNFTCards();
        setLoading(false);
        setshowModalSubscriptionCancel(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const unfollow = async (data) => {
    const id = toast.loading("Please wait...");
    var dataForm = new FormData();
    dataForm.append("market_follow_id", authorId);
    dataForm.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${process.env.REACT_APP_API_BACKEND}/unfollow`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        toast.update(id, {
          render: "Unfollowed! 👽",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setFollowState(false);
        getFollows();
      })
      .catch((error) => {
        toast.update(id, {
          render: "Something went wrong try again ",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.log(error);
      });
  };
  const showDelete = (id) => {
    setDeleteInfo({
      type: "Post",
      id: id,
    });
    setshowModalDelete(true);
  };
  const closeModal = () => {
    setshowModal(false);
    getAllNFTCards();
  };
  const closeDelete = (id) => {
    setshowModalDelete(false);
    setshowModalSubscriptionCancel(false);
    setshowModalFollow(false);
  };
  const showFollow = (type, id) => {
    setfollowInfo({
      type: type,
      id: id,
    });
    setshowModalFollow(true);
  };
  const copyAddress = () => {
    navigator.clipboard.writeText(author?.address);
    toast.success("Copied!  👍");
  };

  const isURLValid = (url) => {
    const expresionRegularURL = /^(ftp|http|https):\/\/[^ "]+$/;
    return expresionRegularURL.test(url);
  };
  return (
    <>
      {showModal && (
        <ModalSubscriptions
          show={showModal}
          handleClose={closeModal}
          loading={loading}
          packages={packages}
          addressAuthor={author?.address}
        />
      )}
      {showModalDelete && (
        <ModalDelete
          show={showModalDelete}
          handleClose={closeDelete}
          handleDelete={handleDeletedContent}
          data={deleteInfo}
        />
      )}
      {showModalSubscriptionCancel && (
        <ModalSubscriptionCancel
          show={showModalSubscriptionCancel}
          handleClose={closeDelete}
          handleDelete={handleRemoveSubscription}
          data={mysubscription}
          author={author}
        />
      )}
      {showModalFollow && (
        <ModalFollows
          show={showModalFollow}
          handleClose={closeDelete}
          data={followInfo}
        />
      )}
      <main className="page-wrapper">
        <div className="page-title-overlap bg-accent pt-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/feed">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-nowrap">
                    <Link to="/explorer">Marketplace</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    Redliner
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <section className="container mb-4">
          <div className="mt-lg-2">
            <img
              className="rounded-circle border border-3 d-lg-block d-none"
              src={author?.avatar ? author.avatar : avatarDefaultTwo}
              width="140"
              alt={
                author?.username ? author.username : "RedLine Blockchain User"
              }
            />
            <div className="d-lg-none d-flex align-items-center justify-content-center">
              <img
                className="rounded-circle me-2"
                src={author?.avatar ? author.avatar : avatarDefaultTwo}
                width="36"
                alt={
                  author?.username ? author.username : "RedLine Blockchain User"
                }
              />
              <h6 className="mb-0 text-light no-traslate">
                {author?.username ? author.username : "RedLine Blockchain User"}
              </h6>
            </div>
            {width <= "980" && (
              <>
                <div className="d-lg-none d-flex align-items-center justify-content-center mt-2">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => setOffCanvas(true)}
                  >
                    <i className="bi bi-info-circle-fill"></i>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="row">
            <aside className="col-lg-3 pt-lg-4">
              <div
                className={`offcanvas offcanvas-collapse  ${offcanvas ? "show " : ""
                  } `}
                id="vendor-sidebar"
              >
                <div className="offcanvas-header align-items-center shadow-sm">
                  <button
                    className="btn-close ms-auto"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => setOffCanvas(false)}
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <img
                    className="rounded-circle d-lg-none d-block mb-4"
                    src={author?.avatar ? author.avatar : avatarDefaultTwo}
                    width="140"
                    alt={
                      author?.username
                        ? author.username
                        : "RedLine Blockchain User"
                    }
                  />
                  <div className="mb-4 pb-2">
                    <h3 className="mb-2 fs-lg fw-normal text-body no-traslate">
                      {author?.name_hidden === "0" ? (
                        <>{author?.name ? author.name : "Name"}</>
                      ) : (
                        <></>
                      )}
                    </h3>
                    <h2 className="h4 mb-3 no-traslate">
                      @
                      {author?.username
                        ? author.username
                        : "RedLine Blockchain User"}
                    </h2>
                    <span className="d-inline-block mb-3 fs-sm text-muted no-traslate">
                      Joined {moment(author?.created).calendar()}
                    </span>
                    <a
                      className="d-block mb-4 text-decoration-none"
                      href="#"
                      onClick={() => copyAddress()}
                    >
                      <span className="text-body">
                        {`${author?.address.slice(
                          0,
                          10
                        )}...${author?.address.slice(
                          author?.address.length - 4,
                          author?.address.length
                        )}`}
                      </span>
                      <i
                        className="ci-copy ms-2 ps-1 fs-sm text-accent"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Copy"
                      ></i>
                    </a>
                    <div className="d-flex align-items-center flex-wrap text-nowrap mb-4 fs-sm">
                      <div
                        className="mb-2 me-sm-3 me-2 text-muted"
                        role="button"
                        onClick={() => showFollow("Followers", author.id)}
                      >
                        <span className="fw-medium text-body">{followers}</span>{" "}
                        followers
                      </div>
                      <div
                        className="mb-2 me-sm-3 me-2 ps-sm-3 ps-2 border-start text-muted"
                        role="button"
                        onClick={() => showFollow("Following", author.id)}
                      >
                        <span className="fw-medium text-body">{following}</span>{" "}
                        following
                      </div>
                    </div>
                    {user && author?.id !== user?.id && (
                      <div className="d-flex flex-lg-row flex-column mt-2 ">
                        <button
                          className={`btn me-2 ${followState
                            ? "btn-outline-danger"
                            : "btn-outline-accent"
                            } `}
                          onClick={() => (followState ? unfollow() : follow())}
                        >
                          {followState ? "Unfollow" : "Follow"}
                        </button>
                        {!mysubscription ? (
                          <button
                            className="btn btn-accent tooltipNFT bottom"
                            onMouseLeave={() => setTipsTooltip(false)}
                            onClick={() => setshowModal(true)}
                          >
                            <i className="ci-locked"></i>
                            <div
                              className={`tiptext ${tipsTooltip ? "headShake_me visible" : ""
                                } `}
                            >
                              Subscribe to content
                            </div>
                          </button>
                        ) : (
                          <button
                            className="btn btn-accent tooltipNFT bottom"
                            onMouseLeave={() => setTipsTooltip(false)}
                            onClick={() => setshowModalSubscriptionCancel(true)}
                          >
                            <i className="ci-check"></i>
                            <div
                              className={`tiptext ${tipsTooltip ? "headShake_me visible" : ""
                                } `}
                            >
                              Active Subscription {mysubscription[0]?.type}
                            </div>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="offcanvas-footer d-flex flex-column align-items-start p-lg-0">
                  <div className="mb-4 pb-2 fs-sm">
                    <h6 className="mb-2 fs-md">Short bio</h6>
                    <p className="mb-0">
                      {readMore ? (
                        author?.short
                      ) : (
                        <>
                          {author?.short?.length > 100
                            ? author?.short.slice(0, 100) + "..."
                            : author?.short}
                        </>
                      )}
                    </p>
                    {author?.short?.length > 100 && (
                      <div
                        className="d-inline-block mt-1"
                        href="#"
                        role="button"
                        onClick={() => setReadmore(!readMore)}
                      >
                        <ins>{readMore ? "Minimize" : "Read More"}</ins>
                      </div>
                    )}
                  </div>
                  <div className="mt-n2">
                    {isURLValid(author?.twitter) && (
                      <a
                        className="btn-social bs-twitter me-2 mt-2"
                        href={author?.twitter}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ci-twitter"></i>
                      </a>
                    )}
                    {isURLValid(author?.facebook) && (
                      <a
                        className="btn-social bs-facebook me-2 mt-2"
                        href={author?.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ci-facebook"></i>
                      </a>
                    )}
                    {isURLValid(author?.instagram) && (
                      <a
                        className="btn-social bs-instagram me-2 mt-2"
                        href={author?.instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ci-instagram"></i>
                      </a>
                    )}
                    {isURLValid(author?.website) && (
                      <a
                        className="btn-social bs-youtube me-2 mt-2"
                        href={author?.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ci-globe"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </aside>
            <div className="col-lg-9 mt-lg-n5 mt-5 pt-lg-2 pt-3">
              <div className="d-flex flex-md-row flex-column align-items-md-center justify-content-md-between mb-lg-4 mb-3 pb-md-1">
                <div
                  className="mb-md-0 mb-4 pb-1"
                  style={{ overflowX: "block" }}
                >
                  <ul
                    className="nav nav-tabs nav-fill flex-nowrap text-nowrap mb-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "feed" ? "active" : ""
                          } `}
                        href="#activity"
                        data-bs-toggle="tab"
                        role="tab"
                        onClick={() => setActiveTab("feed")}
                      >
                        Feed
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "created" ? "active" : ""
                          } `}
                        href="#created"
                        data-bs-toggle="tab"
                        role="tab"
                        onClick={() => setActiveTab("created")}
                      >
                        NFT Items{" "}
                        <span className="opacity-60">({listToken.length})</span>
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "collections" ? "active" : ""
                          } `}
                        href="#collections"
                        data-bs-toggle="tab"
                        role="tab"
                        onClick={() => setActiveTab("collections")}
                      >
                        Collections{" "}
                        <span className="opacity-60">
                          ({listCollection.length})
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <section className="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3">
                {activeTab === "feed" && (
                  <>
                    {listFeed.length > 0 &&
                      listFeed?.map((item, index) => (
                        <div className="col-md-12" key={index}>
                          <article>
                            <div className="card mb-3">
                              <div className="card-body p-3">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <div className="d-inline-flex align-items-center position-relative">
                                    <img
                                      className="me-2 rounded-circle"
                                      width="32"
                                      src={
                                        author?.avatar
                                          ? author?.avatar
                                          : avatarDefault
                                      }
                                      alt={author?.name}
                                    />
                                    <div className="fs-sm">
                                      @{author?.username}
                                      <br />
                                      {moment(item?.created).calendar()}
                                    </div>
                                  </div>
                                  <div className="d-none d-md-block">
                                    <p>
                                      Content:{" "}
                                      {JSON.parse(item?.type).join(", ")}
                                    </p>
                                  </div>
                                  <div className="d-flex my-2 justify-content-end">
                                    {author?.id === user?.id && (
                                      <>
                                        <Link to={`/feed/edit/${item.id}`}>
                                          <button
                                            className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                            type="button"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="left"
                                            title="Edit"
                                          >
                                            <i className="ci-edit"></i>
                                          </button>
                                        </Link>
                                        <button
                                          className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                          type="button"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          title="Deleted"
                                          onClick={() => showDelete(item.id)}
                                        >
                                          <i className="ci-trash"></i>
                                        </button>
                                      </>
                                    )}
                                    {user && author?.id !== user?.id && (
                                      <>
                                        {item?.multimedia === "Private" && (
                                          <button
                                            className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                            type="button"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="left"
                                            title="Locked"
                                            onClick={() => setshowModal(true)}
                                          >
                                            <i className="ci-locked"></i>
                                          </button>
                                        )}
                                      </>
                                    )}
                                    {/* 
                                    <button
                                      className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                      type="button"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="left"
                                      title="Remove"
                                    >
                                      <i className="ci-trash"></i>
                                    </button>
                                    */}
                                  </div>
                                </div>
                                <div style={{ whiteSpace: "break-spaces" }}>
                                  <p
                                    style={{ marginLeft: "2rem" }}
                                    className="no-traslate"
                                  >
                                    <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                                  </p>
                                </div>
                                <div className="row ">
                                  <div className="col">
                                    {item.multimedia !== "Private" ? (
                                      <>
                                        {item?.multimedia && (
                                          <PreviewMultimedia
                                            classHerence="rounded-1 center-block MaxHeightWidthFeed"
                                            file={item?.multimedia}
                                            type={item?.type_multimedia}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          className="rounded-1"
                                          alt="Private Content"
                                          src={ImgBlock}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </article>
                        </div>
                      ))}
                  </>
                )}
                {activeTab === "created" && (
                  <>
                    {listToken.length > 0 &&
                      listToken?.map((item, index) => (
                        <div className="col mb-2" key={index}>
                          <article className="card h-100 border-0 shadow">
                            <div className="card-img-top position-relative overflow-hidden">
                              <Link
                                className="d-block"
                                to={`/nft/view/${item.contract
                                  ? item.contract
                                  : process.env.REACT_APP_CONTRACT_COLLECTION
                                  }/${item.id}`}
                              >
                                <ShowMultimedia
                                  file={item?.multimedia}
                                  alt={item?.name}
                                  type={item?.type_multimedia}
                                />
                              </Link>
                              {item?.type === "2" && (
                                <Countdown
                                  model="minimalist"
                                  dateEnd={item?.bid_expiration_date}
                                  dateStart={item?.bid_starting_date}
                                />
                              )}
                            </div>
                            <div className="card-body">
                              <h3 className="product-title mb-2 fs-base">
                                <Link
                                  className="d-block text-truncate"
                                  to={`/nft/view/${item.contract
                                    ? item.contract
                                    : process.env
                                      .REACT_APP_CONTRACT_COLLECTION
                                    }/${item.id}`}
                                >
                                  {item?.name}
                                </Link>
                              </h3>
                              <span className="fs-sm text-muted">
                                Current {item?.type === "1" && "Price"}
                                {item?.type === "2" && "Minimum Bid"}
                                {item?.type === "3" && "Membership Suncription"}
                                :
                              </span>
                              <div className="d-flex align-items-center flex-wrap">
                                <h4 className="mt-1 mb-0 fs-base text-darker">
                                  {item?.standard === "ERC-1155" && item?.type === "1" ? "See Price" : item?.price_fixed + " " + item?.money}
                                  {item?.type === "2" && item?.price_minimum_bid}{" "}
                                </h4>
                                {/* 
                              <span className="mt-1 ms-1 fs-xs text-muted">
                                (≈ $ 2,000.55)
                              </span>
                              */}
                              </div>
                            </div>
                            <div className="card-footer mt-n1 py-0 border-0">
                              <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                <img
                                  className="me-2 rounded-circle"
                                  width="32"
                                  src={
                                    author?.avatar
                                      ? author?.avatar
                                      : avatarDefault
                                  }
                                  alt={author?.name}
                                />
                                @{author?.username}
                              </div>
                            </div>
                          </article>
                        </div>
                      ))}
                  </>
                )}
                {activeTab === "collections" && (
                  <>
                    {listCollection.length > 0 &&
                      listCollection?.map((item, index) => (
                        <div className="col mb-2" key={index}>
                          <article className="card h-100 border-0 shadow">
                            <div className="card-img-top position-relative overflow-hidden">
                              <Link
                                className="d-block"
                                to={`/explorer/collection/${item?.slug}`}
                              >
                                <picture className="picture-container">
                                  <source srcSet={item?.banner ? item?.banner : BgImg} type="image/webp" />
                                  <img className="rounded-1 picture-content" alt={item?.name} src={item?.banner ? item?.banner : BgImg} />
                                </picture>
                              </Link>
                            </div>
                            <div className="card-body">
                              <h3 className="product-title mb-2 fs-base">
                                <Link
                                  className="d-block text-truncate"
                                  to={`/explorer/collection/${item?.slug}`}
                                >
                                  {item?.name}
                                </Link>
                              </h3>
                              <span className="fs-sm text-muted">
                                {`${item?.description.slice(0, 100)}`}...
                              </span>
                            </div>
                          </article>
                        </div>
                      ))}
                  </>
                )}
              </section>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
