import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import avatarDefaultTwo from "../../assets/img/nft/vendor/avatar.png";
import avatarDefaultSquare from "../../assets/img/nft/vendor/avatar-square.jpg";
import { useWallet } from "../../hooks/useWallet";
import AppContext from "../../context/AppContex";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, useFieldArray } from "react-hook-form";

export default function FeedSetting() {
  const [audienceSetting, setAudienceSetting] = useState("Daily");
  const [audienceList, setAudienceList] = useState(new Set(["Public"]));
  const [getDaily, setGetDaily] = useState(null);
  const [getWeekly, setGetWeekly] = useState(null);
  const [getMonthly, setGetMonthly] = useState(null);
  const [getAnnual, setGetAnnual] = useState(null);
  const [getVIP, setGetVIP] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams] = useSearchParams();
  const [formClear, setformClear] = useState({
    description: "",
    price: "",
    money: "MATIC",
  });
  const { account, user, addtoken, signin, signout } = useWallet();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (user?.id) {
      getMyPlan();
    }
  }, [user]);

  useEffect(() => {
    console.log(searchParams.get("plan"));
    if (searchParams.get("plan")) {
      console.log("Tiene plan");
      setAudienceSetting(searchParams.get("plan"));
      setIsEdit(true);
    }
  }, [searchParams]);

  const watchAllFields = watch();
  const onSubmit = async (data) => {
    setLoading(true);
    const id = toast.loading("Mint in progress");
    var formData = new FormData();
    formData.append("plan", audienceSetting);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("money", data.money);
    fetch(`${process.env.REACT_APP_API_BACKEND}/add-plan-subscriptions/`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        getMyPlan();
        setAudienceSetting(audienceSetting);
        setLoading(false);
        toast.update(id, {
          render: "Membership updated!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMyPlan = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/my-plan-subscriptions/${user?.id}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let getDailyTemp = response.data.filter(function (item) {
          return item.plan === "Daily";
        });
        let getWeeklyTemp = response.data.filter(function (item) {
          return item.plan === "Weekly";
        });
        let getMonthlyTemp = response.data.filter(function (item) {
          return item.plan === "Monthly";
        });
        let getAnnualTemp = response.data.filter(function (item) {
          return item.plan === "Annual";
        });
        let getVIPTemp = response.data.filter(function (item) {
          return item.plan === "VIP";
        });
        reset(null);
        setGetDaily(getDailyTemp ? getDailyTemp[0] : null);
        setGetWeekly(getWeeklyTemp ? getWeeklyTemp[0] : null);
        setGetMonthly(getMonthlyTemp ? getMonthlyTemp[0] : null);
        setGetAnnual(getAnnualTemp ? getAnnualTemp[0] : null);
        setGetVIP(getVIPTemp ? getVIPTemp[0] : null);
        setLoading(false);
        if (searchParams.get("plan") === "Daily") {
          reset(getDailyTemp[0]);
        } else if (searchParams.get("plan") === "Weekly") {
          reset(getWeeklyTemp[0]);
        } else if (searchParams.get("plan") === "Monthly") {
          reset(getMonthlyTemp[0]);
        } else if (searchParams.get("plan") === "Annual") {
          reset(getAnnualTemp[0]);
        } else if (searchParams.get("plan") === "VIP") {
          reset(getVIPTemp[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function handleCheckboxCategory(itemKey) {
    if (itemKey === "Daily") {
      getDaily ? reset(getDaily) : reset(formClear);
    } else if (itemKey === "Weekly") {
      getWeekly ? reset(getWeekly) : reset(formClear);
    } else if (itemKey === "Monthly") {
      getMonthly ? reset(getMonthly) : reset(formClear);
    } else if (itemKey === "Annual") {
      getAnnual ? reset(getAnnual) : reset(formClear);
    } else if (itemKey === "VIP") {
      getVIP ? reset(getVIP) : reset(formClear);
    }
    setAudienceSetting(itemKey);
    setAudienceList(itemKey);
  }
  return (
    <>
      <main className="page-wrapper">
        <div className="bg-accent mb-4 py-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/feed">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-nowrap">
                    <Link to={"/explorer"}>NFT Marketplace</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    {isEdit
                      ? "Edit membership plan " + searchParams.get("plan")
                      : "Add a membership plan"}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
              <h1 className="h3 text-light mb-0">
                {isEdit ? "Edit membership plan " : "Add a membership plan"}
              </h1>
            </div>
          </div>
        </div>

        <section className="container mb-5 pt-1 pb-md-5 pb-4">
          <div className="card card-body mt-2 mb-4 border-0 shadow position-relative h-100">
            <h5 className="modal-title" id="exampleModalLabel">
              Type Audience
            </h5>
            <select
              className="form-select"
              id="price-fix-payment"
              onChange={(e) => handleCheckboxCategory(e.target.value)}
              value={searchParams.get("plan") ? searchParams.get("plan") : ""}
            >
              <option value="" selected disabled hidden>
                Choose here
              </option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Annual">Annual</option>
              <option value="VIP">VIP Annual</option>
            </select>
          </div>
          <div>
            <section>
              <div className="row">
                <form
                  className="needs-validation col-md-12"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <article className="card border-0 shadow position-relative h-100">
                    <div className="card-body">
                      <h2 className="h5 mb-2">
                        Configure {audienceSetting} Plan
                      </h2>
                      <p className="mb-2 fs-sm text-muted">
                        Choose what benefits you'll offer fans at a set{" "}
                        {audienceSetting} price
                      </p>
                      <div className="row gy-md-4 gy-3">
                        <div className="col-12">
                          <label className="form-label" htmlFor="details-title">
                            Description
                            <span className="ms-1 text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="details-title"
                            type="Text"
                            placeholder="Plan description"
                            {...register("description", { required: true })}
                            required
                            disabled={loading}
                            maxLength={2500}
                          />
                          <div className="invalid-feedback">
                            Please, enter the title.
                          </div>
                          <span className="form-text">
                            Maximum 2500 characters. No HTML or emoji allowed.
                          </span>
                        </div>
                        <div className="col-12">
                          <label className="form-label" htmlFor="details-title">
                            Price<span className="ms-1 text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="details-title"
                            type="text"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="Price"
                            {...register("price", { required: true })}
                            required
                            disabled={loading}
                          />
                        </div>
                        <div className="col-sm-6">
                          <label
                            className="form-label"
                            htmlFor="price-fix-payment"
                            disabled={loading}
                          >
                            Preferred payment type
                          </label>
                          <select
                            className="form-select"
                            id="price-fix-payment"
                            {...register("money")}
                          >
                            <option>MATIC</option>
                            <option>RLC</option>
                            {/*<option value="USDC">USDC</option> */}
                          </select>
                        </div>
                        <div className="col-sm-6">
                          <label
                            className="form-label"
                            htmlFor="price-fix-payment"
                          >
                            <strong>Fees</strong>
                          </label>
                          <p>
                            Services Fee{" "}
                            {watchAllFields?.money === "RLC" ? "0.75" : "5"}%{" "}
                            <i className="ci-copy ms-3 text-accent"></i>
                          </p>
                        </div>
                      </div>
                      <div className="mb-2 mt-5 fs-sm text-muted">
                        <button
                          type="submit"
                          className="btn btn-lg btn-accent d-block w-100 mb-2"
                          disabled={loading}
                        >
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          {loading ? "Loading..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </article>
                </form>
              </div>
            </section>
          </div>
        </section>
      </main>
    </>
  );
}
