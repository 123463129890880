import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import avatarDefaultTwo from "../../assets/img/nft/vendor/avatar.png";
import avatarDefaultSquare from "../../assets/img/nft/vendor/avatar-square.jpg";
import { useWallet } from "../../hooks/useWallet";
import AppContext from "../../context/AppContex";
import { ToastContainer, toast } from "react-toastify";
import { useLocalStorage } from "../../hooks/useLocalState";
import "react-toastify/dist/ReactToastify.css";
import { useForm, useFieldArray } from "react-hook-form";

export default function FeedCreate() {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [typeFiles, setTypeFiles] = useState(undefined);
  const [radioPointer, setRadioPointer] = useState("free");
  const [myplan, setMyPlan] = useState([]);
  const [tab, setSelectTab] = useState(1);
  const [activeSlide] = useState(true);
  const [loading, setLoading] = useState(false);
  const { account, user, signin } = useWallet();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const watchAllFields = watch();

  useEffect(() => {
    if (user) {
      getMyPlan();
    }
  }, [user]);

  const getMyPlan = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/my-plan-subscriptions/${user.id}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data?.money) {
          setMyPlan(response.data);
        } else {
          window.location.replace("/feed/setting");
        }
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const selectFile = async (event) => {
    setSelectedFiles(event.target.files[0]);
    setTypeFiles(event.target.files[0].type);
  };

  const onSubmit = async (data) => {
    if (!selectedFiles) {
      toast.error("Please select a file");
      return;
    }
    setLoading(true);
    const id = toast.loading("Please wait...");
    let uploadFile = new FormData();
    uploadFile.append("folder", "feed");
    uploadFile.append("address", account);
    uploadFile.append("file", selectedFiles);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/s3-upload`, {
      method: "POST",
      body: uploadFile,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Image uploaded to S3", response);
        return response;
      })
      .then((response) => {
        var formData = new FormData();
        formData.append("description", data.description);
        formData.append("multimedia", response.data);
        formData.append("type_multimedia", typeFiles);
        formData.append("type", radioPointer);
        formData.append("network", process.env.REACT_APP_NETWORK_TYPE);
        fetch(`${process.env.REACT_APP_API_BACKEND}/subscriptions`, {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            setLoading(false);
            toast.update(id, {
              render: "Post published! 🤠c",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            window.location.replace(`/author/${user.id}`);
          });
      })
      .catch((error) => {
        setLoading(false);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const handleChange = (e) => {
    console.log("entro aqui");
    setRadioPointer(e);
  };
  return (
    <>
      <main className="page-wrapper">
        <div className="bg-accent mb-4 py-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/feed">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-nowrap">
                    <Link to={"/explorer"}>NFT Marketplace</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    Create feed post
                  </li>
                </ol>
              </nav>
            </div>
            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
              <h1 className="h3 text-light mb-0">Create feed post</h1>
            </div>
          </div>
        </div>

        <section className="container mb-5 pt-3 pb-md-5 pb-1">
          <div className="row gy-5">
            <form
              className="needs-validation col-md-8"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <h2 className="h5 mb-2 pb-1">Upload file</h2>
                <p className="mb-4 fs-sm">
                  File types supported: PNG, JPG, GIF, WEBP, MP3 or MP4. Max
                  size: 100mb.
                </p>

                <div className="file-drop-area">
                  {selectedFiles ? (
                    <>
                      <img
                        src={URL.createObjectURL(selectedFiles)}
                        alt="Preview"
                        className="img-thumbnail"
                        width={200}
                        height={200}
                        style={{ display: "block", margin: "auto" }}
                      />
                    </>
                  ) : (
                    <>
                      <div className="file-drop-icon ci-cloud-upload"></div>
                      <span className="file-drop-message">
                        Drag and drop here to upload
                      </span>
                    </>
                  )}
                  <input
                    className="file-drop-input"
                    type="file"
                    onChange={selectFile}
                    id="file-upload"
                    accept="image/png, image/jpeg, image/gif"
                    disabled={loading}
                  />

                  <label
                    htmlFor="file-upload"
                    className="file-drop-btn btn btn-outline-accent"
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    ) : (
                      <i className="ci-loading me-2"></i>
                    )}
                    Or select file
                  </label>
                </div>
              </div>

              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <h2 className="h5 mb-3">Details</h2>
                <div className="row gy-md-4 gy-3">
                  <div className="col-12">
                    <label className="form-label" htmlFor="details-title">
                      Post Title<span className="ms-1 text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="details-title"
                      rows="4"
                      placeholder="Item title"
                      {...register("description", { required: true })}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onChange={() => handleChange("free")}
                    value="free"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Public
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    onChange={() => handleChange("private")}
                    value="private"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Only Subscribers
                  </label>
                </div>
              </div>
              <div>
                <div className="pt-2">
                  <button
                    className="btn btn-lg btn-accent d-block w-100"
                    type="submit"
                    disabled={loading}
                  >
                    Create post
                  </button>
                </div>
              </div>
            </form>

            <aside className="col-lg-3 offset-lg-1 col-md-4 position-relative">
              <div className="sticky-top" style={{ top: "100px" }}>
                <h4 className="h5 mb-3 text-md-start text-center">
                  Who can see this post?
                </h4>

                <div
                  className="card border-0 shadow mx-auto"
                  style={{ maxWidth: "500px", pointerEvents: "none" }}
                >
                  <div className="card-body">
                    {radioPointer === "private" && (
                      <>
                        <h4 className="fs-sm text-muted">Information:</h4>
                        <span className="fs-sm text-muted">
                          {myplan?.price} {myplan?.money} for Month
                        </span>
                      </>
                    )}
                    {radioPointer === "free" && (
                      <>
                        <h4 className="fs-sm text-muted">Information:</h4>
                        <span className="fs-sm text-muted">Free</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </main>
    </>
  );
}
