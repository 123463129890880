import React, { useState, useEffect, useContext } from "react";
import { useWallet } from "../hooks/useWallet";
import AppContext from "../context/AppContex";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { exit } from "process";

export default function ModalLower(props) {
  const [loading, setLoading] = useState(false);
  const { account, user } = useWallet();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    const id = toast.loading("Please wait...");
    setLoading(true);
    var formData = new FormData();
    formData.append("price_fixed", data.amount);
    formData.append("price_minimum_bid", data.amount);
    fetch(
      `${process.env.REACT_APP_API_BACKEND}/ipfs-price/${props?.data?.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          status: props?.data?.status,
          token_id: props?.data?.token_id,
          price_fixed: data.amount,
          price_minimum_bid: data.amount,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        toast.update(id, {
          render: "Price updated! ✅🙂",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setLoading(false);
        props?.handleClose();
      })
      .catch((error) => {
        setLoading(false);
        toast.update(id, {
          render: "something went wrong, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        props?.handleClose();
      });
  };

  const handlePriceChange = (e) => {
    let value = e.target.value;
    if (/^0\d+/.test(value)) {
      value = value.replace(/^0(\d+)/, '0.$1');
    }
    if (!isNaN(value) || value === '') {
      setValue('amount', value);
    }
  };

  return (
    <>
      <div
        className={`modal fade show`}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Change Price
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">New Price</label>
                    <input
                      type="text"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      className="form-control"
                      placeholder="Amount"
                      {...register("amount", { required: true })}
                      min="0"
                      onChange={handlePriceChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => props?.handleClose()}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-accent">
                  Change Price
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
