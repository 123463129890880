import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
export default function ModalSubscriptionCancel(props) {
  return (
    <>
      <div
        className={`modal fade show `}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ width: "400px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Unsubscribe from {props?.author?.name}
              </h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <p className="text-justify">
                  This action cannot be reversed, and your subscription to this
                  RedNFT profile will be canceled
                </p>
                <div className="col-md-12 ">
                  <button
                    className="btn btn-outline-danger w-100 mb-2"
                    onClick={() => props?.handleDelete()}
                  >
                    Cancel subscription
                  </button>
                </div>
                <div className="col-md-12 ">
                  <button
                    className="btn btn-outline-secondary w-100"
                    onClick={() => props?.handleClose()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
