import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import { useWallet } from "../../hooks/useWallet";
import SidebarMenuAccount from "../../components/SidebarMenuAccount";
import HeaderMenuAccount from "../../components/HeaderMenuAccount";
import ModalDelete from "../../components/ModalDelete";
import "react-toastify/dist/ReactToastify.css";

export default function MyCollection() {
  const [tab, setTab] = useState("All");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsSold, setItemsSold] = useState([]);
  const [listToken, setListToken] = useState([]);
  const [showModalDelete, setshowModalDelete] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({
    type: "post",
    id: null,
  });

  const { account, user } = useWallet();

  useEffect(() => {
    getAllNFTCards();
  }, [user]);

  const getAllNFTCards = async () => {
    setLoading(true);

    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/collection`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setListToken(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDeletedContent = async () => {
    setLoading(true);
    if (deleteInfo.id === null) {
      return;
    }
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/collection/${deleteInfo.id}`
    );
    fetch(`${url}`, {
      method: "delete",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setLoading(false);
        setshowModalDelete(false);
        getAllNFTCards();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const showDelete = (id) => {
    setDeleteInfo({
      type: "Collection",
      id: id,
    });
    setshowModalDelete(true);
  };

  const closeDelete = (id) => {
    setshowModalDelete(false);
  };
  return (
    <>
      {showModalDelete && (
        <ModalDelete
          show={showModalDelete}
          handleClose={closeDelete}
          handleDelete={handleDeletedContent}
          data={deleteInfo}
        />
      )}
      <main className="page-wrapper">
        <HeaderMenuAccount />
        <div className="container mb-5 pb-3">
          <div className="bg-light shadow-lg rounded-3 overflow-hidden">
            <div className="row">
              <SidebarMenuAccount />
              <section className="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                  <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-4">
                    <h1 className="h3 mb-sm-0 mb-2 pb-1 text-sm-start text-center">
                      My Collections
                    </h1>
                  </div>

                  <div className="row row-cols-md-2 row-cols-1 g-4 mb-4 pb-3">
                    {listToken.length > 0 &&
                      listToken?.map((item, index) => (
                        <div className="col" key={index}>
                          <article>
                            <div className="card mb-3">
                              <div className="card-body p-3">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <div className="d-inline-flex align-items-center position-relative">
                                    <img
                                      className="rounded-circle me-2"
                                      width="32"
                                      src={
                                        item?.owner?.avatar
                                          ? item?.owner?.avatar
                                          : avatarDefault
                                      }
                                      alt={item?.owner?.name}
                                    />
                                    <div className="fs-sm">
                                      by
                                      <Link
                                        className="ms-1 fw-medium text-accent stretched-link"
                                        to={`/author/${item?.owner?.id}`}
                                      >
                                        @{item?.owner?.username}
                                      </Link>
                                    </div>
                                  </div>
                                  <div>
                                    <Link
                                      to={`/account/collection/${item?.id}`}
                                    >
                                      <button
                                        className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="left"
                                        title="Edit"
                                      >
                                        <i className="ci-edit"></i>
                                      </button>
                                    </Link>
                                    <button
                                      className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                      type="button"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="left"
                                      title="Remove"
                                      onClick={() => showDelete(item?.id)}
                                    >
                                      <i className="ci-trash"></i>
                                    </button>
                                  </div>
                                </div>
                                <Link className="d-block" to={`/explorer/collection/${item?.slug}`}>
                                  <div className="row g-2">
                                    <div className="col">
                                      <picture className="picture-container">
                                        <source srcSet={item?.banner ? item?.banner : BgImg} type="image/webp" />
                                        <img className="rounded-1 picture-content" alt={item?.name} src={item?.banner ? item?.banner : BgImg} />
                                      </picture>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <h3 className="h5 mb-1">
                              <Link
                                className="nav-link-style"
                                to={`/explorer/collection/${item?.slug}`}
                              >
                                {`${item?.name.slice(0, 200)}`}
                              </Link>
                            </h3>
                            <span className="fs-sm text-muted">
                              {`${item?.description.slice(0, 100)}`}...
                            </span>
                          </article>
                        </div>
                      ))}
                  </div>
                  <div className="d-flex flex-sm-row flex-column">
                    <Link className="btn btn-accent" to={"/nft/collection"}>
                      Create new Collection
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
