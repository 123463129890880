import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
export default function ModalAudience(props) {
  const [audienceSetting, setAudienceSetting] = useState(null);
  const [audienceList, setAudienceList] = useState(new Set(["Public"]));
  const [getDaily, setGetDaily] = useState(null);
  const [getWeekly, setGetWeekly] = useState(null);
  const [getMonthly, setGetMonthly] = useState(null);
  const [getAnnual, setGetAnnual] = useState(null);
  const [getVIP, setGetVIP] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    getMyPlan();
  }, []);

  const watchAllFields = watch();
  const onSubmit = async (data) => {
    setLoading(true);
    var formData = new FormData();
    formData.append("plan", audienceSetting);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("money", data.money);
    fetch(`${process.env.REACT_APP_API_BACKEND}/add-plan-subscriptions/`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        getMyPlan();
        setAudienceSetting(null);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMyPlan = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/my-plan-subscriptions/${props.userId}`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let getDailyTemp = response.data.filter(function (item) {
          return item.plan === "Daily";
        });
        let getWeeklyTemp = response.data.filter(function (item) {
          return item.plan === "Weekly";
        });
        let getMonthlyTemp = response.data.filter(function (item) {
          return item.plan === "Monthly";
        });
        let getAnnualTemp = response.data.filter(function (item) {
          return item.plan === "Annual";
        });
        let getVIPTemp = response.data.filter(function (item) {
          return item.plan === "VIP";
        });
        setGetDaily(getDailyTemp ? getDailyTemp[0] : null);
        setGetWeekly(getWeeklyTemp ? getWeeklyTemp[0] : null);
        setGetMonthly(getMonthlyTemp ? getMonthlyTemp[0] : null);
        setGetAnnual(getAnnualTemp ? getAnnualTemp[0] : null);
        setGetVIP(getVIPTemp ? getVIPTemp[0] : null);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function handleCheckboxCategory(itemKey) {
    const newSelectedItems = new Set(audienceList);
    if (!newSelectedItems.has(itemKey)) {
      newSelectedItems.add(itemKey);
    } else {
      newSelectedItems.delete(itemKey);
    }
    console.log(newSelectedItems);
    setAudienceList(newSelectedItems);
  }

  function EditAudienceSetting(type, item) {
    setAudienceSetting(type);
    reset(item);
  }

  return (
    <>
      <div
        className={`modal fade show `}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ top: "1%" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Type Audience
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {audienceSetting !== null ? (
                <section>
                  <div className="row">
                    <form
                      className="needs-validation col-md-12"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <article className="card border-0 shadow position-relative h-100">
                        <div className="card-body">
                          <h2 className="h5 mb-2">
                            Configure {audienceSetting} Plan
                          </h2>
                          <p className="mb-2 fs-sm text-muted">
                            Choose what benefits you'll offer patrons at a set{" "}
                            {audienceSetting} price
                          </p>
                          <div className="row gy-md-4 gy-3">
                            <div className="col-12">
                              <label
                                className="form-label"
                                htmlFor="details-title"
                              >
                                Description
                                <span className="ms-1 text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="details-title"
                                type="Text"
                                placeholder="Plan description"
                                {...register("description", { required: true })}
                                required
                                disabled={loading}
                                maxLength={2500}
                              />
                              <div className="invalid-feedback">
                                Please, enter the title.
                              </div>
                              <span className="form-text">
                                Maximum 2500 characters. No HTML or emoji
                                allowed.
                              </span>
                            </div>
                            <div className="col-12">
                              <label
                                className="form-label"
                                htmlFor="details-title"
                              >
                                Price<span className="ms-1 text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                id="details-title"
                                type="text"
                                pattern="[0-9]+([\.,][0-9]+)?"
                                placeholder="Price"
                                {...register("price", { required: true })}
                                required
                                disabled={loading}
                              />
                            </div>
                            <div className="col-sm-6">
                              <label
                                className="form-label"
                                htmlFor="price-fix-payment"
                                disabled={loading}
                              >
                                Preferred payment type
                              </label>
                              <select
                                className="form-select"
                                id="price-fix-payment"
                                {...register("money")}
                              >
                                <option>MATIC</option>
                                <option>RLC</option>
                                {/*<option value="USDC">USDC</option> */}
                              </select>
                            </div>
                            <div className="col-sm-6">
                              <label
                                className="form-label"
                                htmlFor="price-fix-payment"
                              >
                                <strong>Fees</strong>
                              </label>
                              <p>
                                Services Fee{" "}
                                {watchAllFields?.money === "RLC" ? "0.75" : "5"}
                                % <i className="ci-copy ms-3 text-accent"></i>
                              </p>
                            </div>
                          </div>
                          <div className="mb-2 mt-5 fs-sm text-muted">
                            <button
                              type="submit"
                              className="btn btn-lg btn-accent d-block w-100 mb-2"
                              disabled={loading}
                            >
                              {loading && (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              {loading ? "Loading..." : "Save"}
                            </button>

                            <button
                              className="btn btn-lg btn-accent d-block w-100 mb-2"
                              onClick={() => setAudienceSetting(null)}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </article>
                    </form>
                  </div>
                </section>
              ) : (
                <section>
                  <div className="row">
                    <div className="col-md-12">
                      <h6>Who can see your post?</h6>
                      <p>
                        Your post will show up in News Feed, on your profile,
                        and in search results. The audience is most likely set
                        to <strong>Public</strong>, but you can change it for
                        this particular post.
                      </p>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="list-group"
                        style={{ height: "210px", overflow: "auto" }}
                      >
                        <label className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <div>
                              <h6 className="mb-1">Public</h6>
                              <span>Anyone within RedLine NFT</span>
                            </div>
                            <input
                              className="form-check-input me-1 checkboxBig"
                              type="checkbox"
                              checked={audienceList.has("Public")}
                              onChange={() => handleCheckboxCategory("Public")}
                            />
                          </div>
                        </label>
                        <label className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <div>
                              <h6 className="mb-1">Day Pass</h6>
                              {getDaily ? (
                                <span>
                                  Price: {getDaily?.price} {getDaily?.money} -{" "}
                                  <span
                                    className="fw-medium text-body"
                                    role="button"
                                    onClick={() =>
                                      EditAudienceSetting("Daily", getDaily)
                                    }
                                  >
                                    Edit
                                    <i className="ci-edit ms-1 text-accent"></i>
                                  </span>
                                </span>
                              ) : (
                                <span>There is no configuration</span>
                              )}
                            </div>
                            {getDaily ? (
                              <input
                                className="form-check-input me-1 checkboxBig"
                                type="checkbox"
                                checked={audienceList.has("Daily")}
                                onChange={() => handleCheckboxCategory("Daily")}
                              />
                            ) : (
                              <>
                                <button
                                  className="btn btn-secondary rounded-pill btn-icon"
                                  type="button"
                                  title=""
                                  aria-label="settings"
                                  onClick={() =>
                                    EditAudienceSetting("Daily", getDaily)
                                  }
                                >
                                  <i className="ci-settings"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </label>
                        <label className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <div>
                              <h6 className="mb-1">Weekly Subscribers</h6>
                              {getWeekly ? (
                                <span>
                                  Price: {getWeekly?.price} {getWeekly?.money} -{" "}
                                  <span
                                    className="fw-medium text-body"
                                    role="button"
                                    onClick={() =>
                                      EditAudienceSetting("Weekly", getWeekly)
                                    }
                                  >
                                    Edit
                                    <i className="ci-edit ms-1 text-accent"></i>
                                  </span>
                                </span>
                              ) : (
                                <span>There is no configuration</span>
                              )}
                            </div>
                            {getWeekly ? (
                              <input
                                className="form-check-input me-1 checkboxBig"
                                type="checkbox"
                                checked={audienceList.has("Weekly")}
                                onChange={() =>
                                  handleCheckboxCategory("Weekly")
                                }
                              />
                            ) : (
                              <>
                                <button
                                  className="btn btn-secondary rounded-pill btn-icon"
                                  type="button"
                                  title=""
                                  aria-label="settings"
                                  onClick={() =>
                                    EditAudienceSetting("Weekly", getWeekly)
                                  }
                                >
                                  <i className="ci-settings"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </label>
                        <label className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <div>
                              <h6 className="mb-1">Monthly Subscribers</h6>
                              {getMonthly ? (
                                <span>
                                  Price: {getMonthly?.price} {getMonthly?.money}{" "}
                                  -{" "}
                                  <span
                                    className="fw-medium text-body"
                                    role="button"
                                    onClick={() =>
                                      EditAudienceSetting("Monthly", getMonthly)
                                    }
                                  >
                                    Edit
                                    <i className="ci-edit ms-1 text-accent"></i>
                                  </span>
                                </span>
                              ) : (
                                <span>There is no configuration</span>
                              )}
                            </div>
                            {getMonthly ? (
                              <input
                                className="form-check-input me-1 checkboxBig"
                                type="checkbox"
                                checked={audienceList.has("Monthly")}
                                onChange={() =>
                                  handleCheckboxCategory("Monthly")
                                }
                              />
                            ) : (
                              <>
                                <button
                                  className="btn btn-secondary rounded-pill btn-icon"
                                  type="button"
                                  title=""
                                  aria-label="settings"
                                  onClick={() =>
                                    EditAudienceSetting("Monthly", getMonthly)
                                  }
                                >
                                  <i className="ci-settings"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </label>
                        <label className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <div>
                              <h6 className="mb-1">Annual Subscribers</h6>
                              {getAnnual ? (
                                <span>
                                  Price: {getAnnual?.price} {getAnnual?.money} -{" "}
                                  <span
                                    className="fw-medium text-body"
                                    role="button"
                                    onClick={() =>
                                      EditAudienceSetting("Annual", getAnnual)
                                    }
                                  >
                                    Edit
                                    <i className="ci-edit ms-1 text-accent"></i>
                                  </span>
                                </span>
                              ) : (
                                <span>There is no configuration</span>
                              )}
                            </div>
                            {getAnnual ? (
                              <input
                                className="form-check-input me-1 checkboxBig"
                                type="checkbox"
                                checked={audienceList.has("Annual")}
                                onChange={() =>
                                  handleCheckboxCategory("Annual")
                                }
                              />
                            ) : (
                              <>
                                <button
                                  className="btn btn-secondary rounded-pill btn-icon"
                                  type="button"
                                  title=""
                                  aria-label="settings"
                                  onClick={() =>
                                    EditAudienceSetting("Annual", getAnnual)
                                  }
                                >
                                  <i className="ci-settings"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </label>
                        <label className="list-group-item">
                          <div className="d-flex w-100 justify-content-between">
                            <div>
                              <h6 className="mb-1">VIP Annual Subscribers</h6>
                              {getVIP ? (
                                <span>
                                  Price: {getVIP?.price} {getVIP?.money} -{" "}
                                  <span
                                    className="fw-medium text-body"
                                    role="button"
                                    onClick={() =>
                                      EditAudienceSetting("VIP", getVIP)
                                    }
                                  >
                                    Edit
                                    <i className="ci-edit ms-1 text-accent"></i>
                                  </span>
                                </span>
                              ) : (
                                <span>There is no configuration</span>
                              )}
                            </div>
                            {getVIP ? (
                              <input
                                className="form-check-input me-1 checkboxBig"
                                type="checkbox"
                                checked={audienceList.has("VIP")}
                                onChange={() => handleCheckboxCategory("VIP")}
                              />
                            ) : (
                              <>
                                <button
                                  className="btn btn-secondary rounded-pill btn-icon"
                                  type="button"
                                  title=""
                                  aria-label="settings"
                                  onClick={() =>
                                    EditAudienceSetting("VIP", getVIP)
                                  }
                                >
                                  <i className="ci-settings"></i>
                                </button>
                              </>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
            {audienceSetting === null && (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => props?.handleClose()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-accent"
                  onClick={() => props.onChangeAudience(audienceList)}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
