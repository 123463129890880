import React, { useState, useEffect, useContext } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
export default function FAQ() {

    const [query, setQuery] = useState('');
    const [filteredTitles, setFilteredTitles] = useState([]);

    const titles = [
        {
            question: 'How do I register for REDNFT?',
            answer: `<p><span >To register for REDNFT, follow these detailed steps. Note that the REDNFT Marketplace is only accessible via the Google Chrome browser using the Google Chrome RED WALLET extension.&nbsp;</span><strong><span style="font-size:11pt;">Please note:</span></strong><span style="font-size:11pt;">&nbsp;The REDNFT Marketplace is only accessible via the Google Chrome browser using the Google Chrome RED WALLET extension.</span></p>
      <ol>
          <li >
              <p><span >Download RED WALLET:</span></p>
              <ul>
                  <li >
                      <p><span >For Google Chrome:</span></p>
                      <ul>
                          <li >
                              <p><span>Open your Chrome browser and go to the Chrome Web Store.</span></p>
                          </li>
                          <li >
                              <p><span >Search for &quot;RED WALLET&quot; and click &quot;Add to Chrome.&quot;</span></p>
                          </li>
                          <li >
                              <p><span >Alternatively, you can use this link to access the download page directly.</span></p>
                          </li>
                          <li >
                              <p><span>Click &quot;Add Extension&quot; and follow the prompts to install the extension.</span></p>
                          </li>
                      </ul>
                  </li>
              </ul>
          </li>
          <li>
              <p><span >Install RED WALLET:</span></p>
              <ul>
                  <li >
                      <p><span >Once the extension is added to Chrome, click on the RED WALLET icon in your browser toolbar.</span></p>
                  </li>
                  <li >
                      <p><span >Follow the setup instructions provided within the extension.</span></p>
                  </li>
              </ul>
          </li>
          <li >
              <p><span >Complete KYC:</span></p>
              <ul>
                  <li >
                      <p><span >Open the RED WALLET extension in your Chrome browser.</span></p>
                  </li>
                  <li >
                      <p><span >Navigate to the KYC section, usually found in the settings or profile section.</span></p>
                  </li>
                  <li >
                      <p><span >Upload a clear photo of a government-issued ID (passport, driver&rsquo;s license, or national ID card).</span></p>
                  </li>
                  <li >
                      <p><span>Some KYC processes may require a selfie or live photo for verification.</span></p>
                  </li>
                  <li >
                      <p><span>Enter your full name, address, and any other required information.</span></p>
                  </li>
                  <li >
                      <p><span >Submit the documents and information for verification. The process may take some time, and you will receive a notification once your KYC is approved.</span></p>
                  </li>
              </ul>
          </li>
          <li >
              <p><span >Connect Your Wallet:</span></p>
              <ul>
                  <li >
                      <p><span >Visit the REDNFT Marketplace website in your Chrome browser.</span></p>
                  </li>
                  <li >
                      <p><span >Click on the &quot;Connect Wallet&quot; button at the top right corner of the page.</span></p>
                  </li>
                  <li >
                      <p><span>Select RED WALLET from the list of available wallet options.</span></p>
                  </li>
                  <li >
                      <p><span >Follow the prompts to authorize the connection. Your RED WALLET will now be connected to the REDNFT Marketplace.</span></p>
                  </li>
              </ul>
          </li>
      </ol>
      `
        },
        {
            question: 'How do I mint an NFT?',
            answer: `<p><span >Minting an NFT on the REDNFT Marketplace involves creating and publishing a digital asset on the blockchain.&nbsp;</span><strong><span style="font-size:11pt;">Please note:</span></strong><span style="font-size:11pt;">&nbsp;The REDNFT Marketplace is only accessible via the Google Chrome browser using the Google Chrome RED WALLET extension. Here&apos;s how you do it:</span></p>
      <ol>
          <li ><span >Open the REDNFT Marketplace:</span>
              <ul>
                  <li ><span >Ensure you are using Google Chrome and that your RED WALLET extension is connected to the REDNFT Marketplace.</span></li>
              </ul>
          </li>
          <li ><span >Navigate to the &quot;Create&quot; Section:</span>
              <ul>
                  <li ><span >Click on the &quot;Create&quot; button on the REDNFT Marketplace.</span></li>
              </ul>
          </li>
          <li ><span >Select &quot;Create NFT&quot;:</span>
              <ul>
                  <li ><span >This will open the NFT creation form.</span></li>
              </ul>
          </li>
          <li ><span >Fill in the NFT Details:</span>
              <ul>
                  <li ><span >Title: Enter a title for your NFT (maximum 100 characters, no HTML or emoji).</span></li>
                  <li ><span >Category: Select the appropriate category for your NFT (e.g., Art, Music, Collectibles).</span></li>
                  <li ><span >Collection: Choose to add the NFT to an existing collection or create a new one.</span></li>
                  <li ><span >Type: Select the NFT type (ERC-721 for single supply, ERC-1155 for multiple supplies).</span></li>
                  <li ><span >Supply: Specify the number of NFTs.</span></li>
                  <li ><span >Creator Earnings: Set the percentage of earnings you wish to receive from secondary sales (up to 10%).</span></li>
                  <li ><span >Description: Provide a short description of your item.</span></li>
                  <li ><span >Commercial Use: Indicate if the NFT has commercial use rights.</span></li>
                  <li ><span >Price &amp; Payment Options:</span>
                      <ul>
                          <li ><span >Price: Enter the price you want to list the NFT for.</span></li>
                          <li ><span >Preferred Payment Type: Choose MATIC.</span></li>
                          <li ><span >Fees: Note the 1% service fee.</span></li>
                      </ul>
                  </li>
              </ul>
          </li>
          <li ><span >Mint the NFT:</span>
              <ul>
                  <li ><span >Review all the information to ensure it is correct.</span></li>
                  <li ><span >Click &quot;Mint NFT&quot; to publish your NFT on the Polygon network.</span></li>
                  <li ><span >You will receive a confirmation once the NFT is successfully minted and listed for sale.</span></li>
              </ul>
          </li>
      </ol>`
        },
        {
            question: 'What payment types are accepted?',
            answer: `<p>
      <span>The REDNFT Marketplace accepts MATIC as the preferred payment type for all transactions. MATIC is the native cryptocurrency of the Polygon network, which offers low transaction fees and fast processing times.</span>
      <strong><span>Please note:</span></strong>
      <span>The REDNFT Marketplace is only accessible via the Google Chrome browser using the Google Chrome RED WALLET extension. Here’s how you can use it:</span>
  </p>
  <ol>
      <li>
          <p><span>Ensure You Have MATIC in Your Wallet:</span></p>
          <ul>
              <li>
                  <p><span>Purchase MATIC from a cryptocurrency exchange (e.g., Binance, Coinbase) and transfer it to your RED WALLET.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Set MATIC as Preferred Payment Type:</span></p>
          <ul>
              <li>
                  <p><span>When listing an NFT or creating a subscription package, choose MATIC as your preferred payment type.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Pay Fees in MATIC:</span></p>
          <ul>
              <li>
                  <p><span>All service fees on the REDNFT Marketplace (e.g., minting fees, subscription package fees) will be deducted in MATIC.</span></p>
              </li>
          </ul>
      </li>
  </ol>
  `
        },
        {
            question: 'How do I create a subscription package?',
            answer: `<p>
      <span>Creating a subscription package on the REDNFT Marketplace allows you to offer exclusive content to your subscribers. Here’s how you can set it up:</span>
  </p>
  <ol>
      <li>
          <p><span>Open the REDNFT Marketplace:</span></p>
          <ul>
              <li>
                  <p><span>Ensure you are using Google Chrome and that your RED WALLET extension is connected to the REDNFT Marketplace.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Navigate to the "Create" Section:</span></p>
          <ul>
              <li>
                  <p><span>Click on the "Create" button on the REDNFT Marketplace.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Select "Create Subscription Package":</span></p>
          <ul>
              <li>
                  <p><span>This will open the subscription package creation form.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Configure Your Plan:</span></p>
          <ul>
              <li>
                  <p><span>Description: Enter a detailed description of your subscription plan (maximum 2500 characters, no HTML or emoji).</span></p>
              </li>
              <li>
                  <p><span>Price: Set the price for the subscription.</span></p>
              </li>
              <li>
                  <p><span>Preferred Payment Type: Choose MATIC.</span></p>
              </li>
              <li>
                  <p><span>Fees: Note the 5% service fee.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Choose Subscription Options:</span></p>
          <ul>
              <li>
                  <p><span>You can offer various subscription options such as daily, weekly, monthly, annually, and VIP packages.</span></p>
              </li>
              <li>
                  <p><span>Define the benefits subscribers will receive at each subscription level (e.g., exclusive content, early access to new NFTs, personalized interactions).</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Publish the Package:</span></p>
          <ul>
              <li>
                  <p><span>Review all the information to ensure accuracy.</span></p>
              </li>
              <li>
                  <p><span>Click "Create Subscription Package" to publish it. Subscribers can now choose and subscribe to your packages.</span></p>
              </li>
          </ul>
      </li>
  </ol>
  `
        },
        {
            question: 'How do I manage my profile?',
            answer: `<p><span style="font-size:11pt;">Managing your profile on the REDNFT Marketplace involves updating your personal information, social media links, and viewing your activities. Follow these steps:</span></p>
      <ol>
          <li style="list-style-type:decimal;font-size:11pt;">
              <p><span style="font-size:11pt;">Access Profile Settings:</span></p>
              <ul>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">Ensure you are using Google Chrome and that your RED WALLET extension is connected to the REDNFT Marketplace.</span></p>
                  </li>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">Navigate to the profile section on the REDNFT Marketplace.</span></p>
                  </li>
              </ul>
          </li>
          <li style="list-style-type:decimal;font-size:11pt;">
              <p><span style="font-size:11pt;">Update Personal Information:</span></p>
              <ul>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">Modify your name, username, email, and bio.</span></p>
                  </li>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">Add links to your social media profiles and personal website.</span></p>
                  </li>
              </ul>
          </li>
          <li style="list-style-type:decimal;font-size:11pt;">
              <p><span style="font-size:11pt;">Manage Your Content:</span></p>
              <ul>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">My Feed: View and manage your posts.</span></p>
                  </li>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">My NFTs: Organize and manage your NFTs.</span></p>
                  </li>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">My Collections: Create and manage collections of your NFTs.</span></p>
                  </li>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">My Subscriptions: View and manage your active subscriptions.</span></p>
                  </li>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">My Packages: Manage your subscription packages.</span></p>
                  </li>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">Favorites: Keep track of your favorite NFTs.</span></p>
                  </li>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">Notifications: Check notifications related to your account and activities.</span></p>
                  </li>
              </ul>
          </li>
          <li style="list-style-type:decimal;font-size:11pt;">
              <p><span style="font-size:11pt;">Save Changes:</span></p>
              <ul>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">Ensure all updates are saved.</span></p>
                  </li>
              </ul>
          </li>
          <li style="list-style-type:decimal;font-size:11pt;">
              <p><span style="font-size:11pt;">Sign Out:</span></p>
              <ul>
                  <li style="list-style-type:disc;font-size:11pt;">
                      <p><span style="font-size:11pt;">Log out of your account securely by clicking the &quot;Sign Out&quot; option.</span></p>
                  </li>
              </ul>
          </li>
      </ol>`
        },
        {
            question: 'How do I create NFTs?',
            answer: `<p>
      <span>Creating NFTs on the REDNFT Marketplace involves several steps to ensure your digital asset is properly minted and listed. Here’s a detailed guide:</span>
  </p>
  <ol>
      <li>
          <p><span>Connect Your RED WALLET:</span></p>
          <ul>
              <li>
                  <p><span>Ensure you are using Google Chrome and that your RED WALLET extension is connected to the REDNFT Marketplace.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Navigate to the "Create" Section:</span></p>
          <ul>
              <li>
                  <p><span>Click on the "Create" button.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Select "Create NFT":</span></p>
          <ul>
              <li>
                  <p><span>This will open the NFT creation form.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Fill in the NFT Details:</span></p>
          <ul>
              <li>
                  <p><span>Title: Enter a title for your NFT (maximum 100 characters, no HTML or emoji).</span></p>
              </li>
              <li>
                  <p><span>Category: Select the appropriate category (e.g., Art, Music, Collectibles).</span></p>
              </li>
              <li>
                  <p><span>Collection: Choose to add the NFT to an existing collection or create a new one.</span></p>
              </li>
              <li>
                  <p><span>Type: Select the NFT type (ERC-721 for single supply, ERC-1155 for multiple supplies).</span></p>
              </li>
              <li>
                  <p><span>Supply: Specify the number of NFTs.</span></p>
              </li>
              <li>
                  <p><span>Creator Earnings: Set the percentage of earnings you wish to receive from secondary sales (up to 10%).</span></p>
              </li>
              <li>
                  <p><span>Description: Provide a short description of your item.</span></p>
              </li>
              <li>
                  <p><span>Commercial Use: Indicate if the NFT has commercial use rights.</span></p>
              </li>
              <li>
                  <p><span>Price & Payment Options:</span></p>
                  <ul>
                      <li>
                          <p><span>Price: Enter the price you want to list the NFT for.</span></p>
                      </li>
                      <li>
                          <p><span>Preferred Payment Type: Choose MATIC.</span></p>
                      </li>
                      <li>
                          <p><span>Fees: Note the 1% service fee.</span></p>
                      </li>
                  </ul>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Mint the NFT:</span></p>
          <ul>
              <li>
                  <p><span>Review all the information to ensure it is correct.</span></p>
              </li>
              <li>
                  <p><span>Click "Mint NFT" to publish your NFT on the Polygon network.</span></p>
              </li>
              <li>
                  <p><span>You will receive a confirmation once the NFT is successfully minted and listed for sale.</span></p>
              </li>
          </ul>
      </li>
  </ol>
  `
        },
        {
            question: 'How do I create subscription packages?',
            answer: `<p>
      <span>Creating subscription packages on the REDNFT Marketplace allows you to offer exclusive content to your subscribers. Here’s how you do it:</span>
  </p>
  <ol>
      <li>
          <p><span>Open the REDNFT Marketplace:</span></p>
          <ul>
              <li>
                  <p><span>Ensure you are using Google Chrome and that your RED WALLET extension is connected to the REDNFT Marketplace.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Navigate to the "Create" Section:</span></p>
          <ul>
              <li>
                  <p><span>Click on the "Create" button.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Select "Create Subscription Package":</span></p>
          <ul>
              <li>
                  <p><span>This will open the subscription package creation form.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Configure Your Plan:</span></p>
          <ul>
              <li>
                  <p><span>Description: Enter a detailed description of your subscription plan (maximum 2500 characters, no HTML or emoji).</span></p>
              </li>
              <li>
                  <p><span>Price: Set the price for the subscription.</span></p>
              </li>
              <li>
                  <p><span>Preferred Payment Type: Choose MATIC.</span></p>
              </li>
              <li>
                  <p><span>Fees: Note the 5% service fee.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Choose Subscription Options:</span></p>
          <ul>
              <li>
                  <p><span>Offer various subscription options such as daily, weekly, monthly, annually, and VIP packages.</span></p>
              </li>
              <li>
                  <p><span>Define the benefits subscribers will receive at each subscription level (e.g., exclusive content, early access to new NFTs, personalized interactions).</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Publish the Package:</span></p>
          <ul>
              <li>
                  <p><span>Review all the information to ensure accuracy.</span></p>
              </li>
              <li>
                  <p><span>Click "Create Subscription Package" to publish it. Subscribers can now choose and subscribe to your packages.</span></p>
              </li>
          </ul>
      </li>
  </ol>
  `
        },
        {
            question: 'How do I manage my profile management? ',
            answer: `<p>
      <span>Profile management on the REDNFT Marketplace involves updating personal details, managing your content, and interacting with other users. </span>
      <span><strong>Please note:</strong></span>
      <span> The REDNFT Marketplace is only accessible via the Google Chrome browser using the Google Chrome RED WALLET extension. Here’s how to do it:</span>
  </p>
  <ol start="1">
      <li><span>Access Profile Settings:</span></li>
  </ol>
  <ul start="1">
      <li><span>Ensure you are using Google Chrome and that your RED WALLET extension is connected to the REDNFT Marketplace.</span></li>
      <li><span>Navigate to the profile section on the REDNFT Marketplace.</span></li>
  </ul>
  <ol start="2">
      <li><span>Update Personal Information:</span></li>
  </ol>
  <ul start="1">
      <li><span>Modify your name, username, email, and bio.</span></li>
      <li><span>Add links to your social media profiles and personal website.</span></li>
  </ul>
  <ol start="3">
      <li><span>You May Also Manage Your Content:</span></li>
  </ol>
  <ul start="1">
      <li><span>My Feed: View and manage your posts.</span></li>
      <li><span>My NFTs: Organize and manage your NFTs.</span></li>
      <li><span>My Collections: Create and manage collections of your NFTs.</span></li>
      <li><span>My Subscriptions: View and manage your active subscriptions.</span></li>
      <li><span>My Packages: Manage your subscription packages.</span></li>
      <li><span>Favorites: Keep track of your favorite NFTs.</span></li>
      <li><span>Notifications: Check notifications related to your account and activities.</span></li>
  </ul>
  <ol start="4">
      <li><span>Save Changes:</span></li>
  </ol>
  <ul start="1">
      <li><span>Ensure all updates are saved.</span></li>
  </ul>
  <ol start="5">
      <li><span>Sign Out:</span></li>
  </ol>
  <ul start="1">
      <li><span>Log out of your account securely by clicking the "Sign Out" option.</span></li>
  </ul>
  `
        },
        {
            question: 'How do I explore the marketplace?',
            answer: `<p>
      <span>Exploring the REDNFT Marketplace allows you to discover new content, interact with creators, and find NFTs that interest you. Here’s how to navigate the marketplace:</span>
  </p>
  <ol start="1">
      <li><span>Access the Marketplace:</span></li>
  </ol>
  <ul start="1">
      <li><span>Ensure you are using Google Chrome and that your RED WALLET extension is connected to the REDNFT Marketplace.</span></li>
  </ul>
  <ol start="2">
      <li><span>Explore Different Sections:</span></li>
  </ol>
  <ul start="1">
      <li><span>My Feed: Displays all the posts you have made on the marketplace.</span></li>
  </ul>
  <ul start="1">
      <li><span>You can manage your posts and interact with other users' comments.</span></li>
  </ul>
  <ul start="1">
      <li><span>For You: Curates feeds of posts from accounts you follow and are subscribed to.</span></li>
  </ul>
  <ul start="1">
      <li><span>Includes both public and private content based on your subscriptions.</span></li>
  </ul>
  <ul start="1">
      <li><span>Feed Explorer: Allows you to browse all public posts available on the marketplace.</span></li>
  </ul>
  <ul start="1">
      <li><span>Discover new content and creators by exploring different categories and tags.</span></li>
  </ul>
  <ol start="3">
      <li><span>Interact with Content:</span></li>
  </ol>
  <ul start="1">
      <li><span>Like, comment, and share posts that interest you.</span></li>
      <li><span>Follow creators to stay updated with their latest content.</span></li>
  </ul>
  <ol start="4">
      <li><span>Subscribe to Creators:</span></li>
  </ol>
  <ul start="1">
      <li><span>Visit a creator’s profile by clicking on their profile picture or name.</span></li>
      <li><span>Click on the "Subscribe" button to choose a subscription package and gain access to exclusive content.</span></li>
  </ul>
  `
        },
        {
            question: 'How do subscription models work?',
            answer: `<p>
      <span>Subscription models on the REDNFT Marketplace allow creators to offer exclusive content to their subscribers. </span>
      <strong><span>Please note:</span></strong>
      <span> The REDNFT Marketplace is only accessible via the Google Chrome browser using the Google Chrome RED WALLET extension. Here’s how it works:</span>
  </p>
  <ol>
      <li>
          <p><span>Creating Subscription Packages:</span></p>
          <ul>
              <li>
                  <p><span>Open the REDNFT Marketplace: Ensure you are using Google Chrome and that your RED WALLET extension is connected to the REDNFT Marketplace.</span></p>
              </li>
              <li>
                  <p><span>Navigate to the "Create" Section: Click on the "Create" button.</span></p>
              </li>
              <li>
                  <p><span>Select "Create Subscription Package": This will open the subscription package creation form.</span></p>
              </li>
              <li>
                  <p><span>Configure Your Plan:</span></p>
                  <ul>
                      <li>
                          <p><span>Description: Enter a detailed description of your subscription plan (maximum 2500 characters, no HTML or emoji).</span></p>
                      </li>
                      <li>
                          <p><span>Price: Set the price for the subscription.</span></p>
                      </li>
                      <li>
                          <p><span>Preferred Payment Type: Choose MATIC.</span></p>
                      </li>
                      <li>
                          <p><span>Fees: Note the 5% service fee.</span></p>
                      </li>
                  </ul>
              </li>
              <li>
                  <p><span>Choose Subscription Options:</span></p>
                  <ul>
                      <li>
                          <p><span>Offer various subscription options such as daily, weekly, monthly, annually, and VIP packages.</span></p>
                      </li>
                      <li>
                          <p><span>Define the benefits subscribers will receive at each subscription level (e.g., exclusive content, early access to new NFTs, personalized interactions).</span></p>
                      </li>
                  </ul>
              </li>
              <li>
                  <p><span>Publish the Package: Review all the information to ensure accuracy and click "Create Subscription Package" to publish it.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Subscribing to Packages:</span></p>
          <ul>
              <li>
                  <p><span>Visit a Creator’s Profile: Click on their profile picture or name to view their profile.</span></p>
              </li>
              <li>
                  <p><span>Subscribe: Click on the "Subscribe" button and choose a subscription package.</span></p>
              </li>
              <li>
                  <p><span>Access Exclusive Content: Subscriptions give you access to private posts and exclusive content that is not visible to non-subscribers. Public content and NFTs are always accessible.</span></p>
              </li>
          </ul>
      </li>
      <li>
          <p><span>Managing Subscriptions:</span></p>
          <ul>
              <li>
                  <p><span>View Active Subscriptions: Go to "My Subscriptions" in your profile settings to see all your active subscriptions.</span></p>
              </li>
              <li>
                  <p><span>Cancel or Modify Subscriptions: Manage your subscriptions, including cancellation or upgrading to different packages, from your profile settings.</span></p>
              </li>
          </ul>
      </li>
  </ol>
  `
        }
    ];

    const handleChange = (e) => {
        const value = e.target.value.toLowerCase();
        setQuery(value);

        const filtered = titles.filter(title =>
            title.question.toLowerCase().includes(value)
        );
        setFilteredTitles(filtered);
    };

    const handleSelect = (title) => {
        setSelectFAQ(title.answer);
        const targetDiv = document.getElementById("response");
        if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const [selectFAQ, setSelectFAQ] = useState("");

    const [openIndex, setOpenIndex] = useState(null);

    const toggleQuestion = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };

    return (
        <>
            <section class="position-relative bg-dark bg-size-cover bg-position-center-x position-relative py-5 mb-5" ><span class="position-absolute top-0 start-0 w-100 h-100 bg-darker" ></span>
                <div class="container position-relative zindex-5 py-4 my-3">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <h1 class="text-light text-center">How can we help?</h1>
                            <p class="pb-3 text-light text-center">Ask Questions. Browse Topics. Find Answers.</p>
                            <div class="input-group input-group-lg mb-3"><i class="ci-search position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                                <input class="form-control rounded-start" type="search" value={query}
                                    onChange={handleChange}
                                    placeholder="Ask your question..." />
                            </div>
                            <div class="fs-sm">
                                <span class="text-light opacity-50 me-1">Suggestions:</span>
                                {filteredTitles.map((item, index) => (
                                    <div class="nav-link-style nav-link-light me-1 pb-1 border-bottom border-light" onClick={() => handleSelect(item)}> {item.question}, </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="container pt-4 pb-5" id="response">
                <h2 class="h3 text-center">F.A.Q.</h2>
                <div class="row pt-4 p-5">
                    <div class="col-sm-12">
                        <ul class="list-unstyled">
                            {titles.map((faq, index) => (
                                <li class="faq-question mb-2 p-3" onClick={() => toggleQuestion(index)}>
                                    <div class="nav-link-style d-flex align-items-center border-bottom" style={{ cursor: 'pointer' }}><i class="ci-book opacity-60 me-2"></i><span>{faq.question}</span></div>
                                    {openIndex === index && (
                                        <section class="container pt-1 pb-5 mb-2 faq" >
                                            <div class="pb-3" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                        </section>
                                    )}
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
            </section>

        </>
    );
}
