import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
export default function PolicyAndPrivacy() {
  const [activeSlide] = useState(true);
  return (
    <>
      <div className="pt-4">
        <section className="container">
          <div className="card py-5 border-0 shadow text-left">
            <div className="card-body py-md-4 py-3 px-4 text-left ">
              <p>
                <b>REDNFT/Redline Blockchain Global Privacy Notice</b>
              </p>
              <p ><strong>March 26, 2024</strong></p>
              <p >This privacy notice explains how Redline Blockchain, S.A., a Panamanian corporation registered under Folio No. 155712200 of the Public Registry of Panama, and its affiliates, including but not limited to (&ldquo;<strong>REDNFT</strong>, RedLine Blockchain, S.A.&rdquo; &ldquo;<strong>we</strong>,&rdquo; or &ldquo;<strong>us</strong>&rdquo;) process information about you. This privacy notice applies when you use our websites, mobile applications, browser extension, payment processing services, application programming interfaces, and other online products and services that link to this privacy notice (collectively, our &ldquo;<strong>Services</strong>&rdquo;), contact our customer service team, engage with us on social media, or otherwise interact with us or our Services.</p>
              <p >We may change this privacy notice from time to time. If we make changes, we will notify you by revising the date at the top of this notice and, in some cases, we may provide you with additional notice (such as adding a statement to our website or sending you a notification). We encourage you to review this privacy notice regularly to stay informed about our information practices and the choices available to you.</p>
              <p ><strong>Contents</strong></p>
              <ul >
                <li >Collection of Information</li>
                <li >Definitions Provided by Panamanian Regulation</li>
                <li >Use of Information</li>
                <li >Sharing of Information</li>
                <li >Analytics</li>
                <li >Transfer of Information to the United States and Other Countries</li>
                <li >Your Choices</li>
                <li >Protection of Information</li>
                <li >Contact Us</li>
              </ul>
              <p >&nbsp;</p>
              <ol start="1" type="1">
                <li ><strong>Collection of Information</strong></li>
              </ol>
              <div >
                <ol>
                  <li ><strong>Information You Provide to Us</strong></li>
                </ol>
              </div>
              <p >We collect information you provide directly to us. For example, you can provide information directly to us when you create a business or consumer account, use our Services to send and receive money or cryptocurrency or to buy and sell cryptocurrency, request access to features, request customer support, or otherwise communicate with us. The types of information we may collect about you include your name and contact information (such as email address, postal address, and phone number), date of birth, username, profile image, photograph, social security or your GOV issued ID numbers, payment and transaction hashes (including blockchain addresses), identity information you submit (such as government-issued identification numbers, photos of government-issued documents, and proof of address), passcode, payment and financial account information (including payment method information and bank account and routing numbers), and any other information you choose to provide.</p>
              <p >If you have a business account with REDNFT/Redline Blockchain, we may collect additional information about you and the beneficial owners of the business. The types of additional information we may collect for business accounts include business name, physical address, mailing address, proof of address, tax ID, name of beneficial owner(s), proof of legal formation (for example, a Certificate of Incorporation), bank statement, and bank account information.</p>
              <div >
                <ol>
                  <li ><strong>Information We Collect Automatically When You Interact with Us</strong></li>
                </ol>
              </div>
              <p >When you use or interact with our Services, we automatically collect certain information, including:</p>
              <ul type="disc">
                <li ><strong>Transactional Information</strong></li>
              </ul>
              <p >When you transfer, purchase, or sell cryptocurrency or make or receive a payment, we collect information about the transaction, such as the transaction amount, the date of the transaction, the transaction origination and destination (including any address information), and, in some cases, the identity of the other party to your transaction.</p>
              <ul type="disc">
                <li ><strong>Device Information</strong></li>
              </ul>
              <p >We collect information about your device when you interact with our Services, including hardware model, operating system version, mobile network, IP address, device identifier, browser type, and app version.</p>
              <ul type="disc">
                <li ><strong>Location Information</strong></li>
              </ul>
              <p >We infer your approximate geographic location (e.g., country or city-level location) based upon your IP address.</p>
              <ul type="disc">
                <li ><strong>Information Collected by Cookies and Similar Tracking Technologies</strong></li>
              </ul>
              <p >We (and our service providers) use tracking technologies, such as cookies and web beacons, to collect information about you. Cookies are small data files stored on your hard drive or in device memory that help us provide and improve our Services and your experience, such as by allowing us to see which areas and features of our Services are popular, store preferences, and help protect our systems and users. Web beacons (also known as &ldquo;pixel tags&rdquo; or &ldquo;clear GIFs&rdquo;) are electronic images that we use on our Services and in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness.</p>
              <div >
                <ol>
                  <li ><strong>Information We Collect from Other Sources</strong></li>
                </ol>
              </div>
              <p >We collect information about you from third-party sources. For example, we may collect information about you from identity verification and know-your-customer service providers, financial services providers, financial institutions, including information such as your name, username, phone number, postal address, transaction confirmations, age, government-issued identification numbers (such as Social Security number), and financial account information (including account balances and transaction history). We may also receive information about you from other users, such as when they engage in transactions with you through our Services or invite you to use our Services. This information may include transaction descriptions that relate to you or your name or username.</p>
              <div >
                <ol>
                  <li ><strong>Information We Derive</strong></li>
                </ol>
              </div>
              <p >Finally, we may also derive information or draw inferences about you based on the information we collect. For example, as noted above, we may make inferences about your location based on your IP address, we may infer that certain products or services may be of interest to you based on your transaction history, and we may draw inferences about potentially fraudulent activity.</p>
              <p ><strong>&nbsp;</strong></p>
              <div >
                <ol >
                  <li ><strong>Definitions Provided by Panamanian Regulation</strong></li>
                </ol>
              </div>
              <p >Below are the definitions provided by Law 81 for the terminology used in this policy:</p>
              <ul type="disc">
                <li ><strong>Personal data</strong>: Any information regarding natural persons that identifies or makes them identifiable. All personal data is treated as confidential.</li>
                <li ><strong>Confidential data</strong>: Data that should not be public knowledge or known to unauthorized third parties, including data protected by law, confidentiality, or nondisclosure agreements, to safeguard information. For public administration, it&apos;s data whose processing is limited to these purposes or with explicit consent of the data subject, notwithstanding special laws or regulations. Confidential data is always restricted access.</li>
                <li ><strong>Sensitive data</strong>: Refers to the intimate sphere of the data subject, or its misuse could lead to discrimination or pose a serious risk. Encompassing personal data that may reveal racial or ethnic origin; religious, philosophical, or moral beliefs; trade union membership or political opinions; health, life, sexual preference or orientation, genetic data, or biometric data, regulated to uniquely identify a natural person.</li>
              </ul>
              <p >&nbsp;</p>
              <ol start="3" type="1">
                <li ><strong>Use of Information</strong></li>
              </ol>
              <p >We use the information we collect to provide, maintain, and improve our Services, including to process transactions, identify and repair errors in our Services, and analyze usage over time. We also use the information we collect to:</p>
              <ul type="disc">
                <li >Authenticate and help verify your identity.</li>
                <li >Send you transactional or other account-related information, including confirmations, receipts, customer experience surveys, security alerts, transaction updates, and other customer support and administrative messages.</li>
                <li >Send you marketing communications that we think will interest you.</li>
                <li >Detect, investigate, and help prevent security incidents and other malicious, fraudulent, or illegal activity and help protect the rights and property of REDNFT/Redline Blockchain and others. Some of these preventative actions may prevent you from registering for an account, set deposit or withdrawal limits, or reject requested transactions on an automated basis. If you have questions about the validity of any automated rejection or limitation, please contact us.</li>
                <li >De-identify, anonymize, or aggregate your information.</li>
                <li >Comply with our legal and financial obligations; and carry out any other purpose described to you at the time the information was collected.</li>
              </ul>
              <p >&nbsp;</p>
              <ol start="4" type="1">
                <li ><strong>Sharing of Information</strong></li>
              </ol>
              <p >We share information about you in the following circumstances or as otherwise described in this notice:</p>
              <ul type="disc">
                <li >With companies, professional advisors, and contractors that access your information to perform services for us, such as companies that assist us with web hosting, data storage, identity verification, fraud prevention, payment processing, financial services, customer service and support, legal and consulting services, customer communications, analytics, and marketing and advertising.<ul type="circle">
                  <li >Depending on your location, we, or our financial institution partners may use vendors to help verify your identity and prevent fraud, including by determining whether a selfie you take matches the photo in your government-issued identity document. All biometric data stored by vendors on REDNFT/Redline Blockchain on behalf is permanently destroyed when no longer required for the purpose of collection, or within three years of your last interaction with REDNFT/Redline Blockchain, whichever comes first. With financial institutions that we partner with to receive and process payments and transactions you have authorized via the Services. Our financial institution partners may vary depending on your geographic location. Please review our Terms of Service for additional information regarding our financial institution</li>
                </ul>
                </li>
                <li >With financial institutions that we partner with to receive and process payments and transactions you have authorized via the Services. Our financial institution partners may vary depending on your geographic location. Please review our Terms of Service for additional information regarding our financial institution partners&rsquo;</li>
                <li >With business partners that provide products or services you request or that jointly offer products and services with us.</li>
                <li >If we believe that disclosure is in accordance with, or required by, any applicable law or legal process. For example, we may disclose information about users or transactions in connection with tax filings, regulatory requests, court orders, or law enforcement requests.</li>
                <li >If we believe that your actions are inconsistent with our user agreements or policies, that you have violated the law, or that it is necessary to protect the rights, property, and safety of REDNFT/Redline Blockchain, our users, the public, or others.</li>
                <li >In connection with, or during negotiations concerning, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
                <li >Between and among REDNFT/Redline Blockchain and its affiliates for use in connection with the purposes outlined above.</li>
                <li >With your consent or at your direction, such as by sending your user information and transaction amount in connection with transactions you engage in with other users.</li>
              </ul>
              <p >We may also share aggregated or de-identified information that cannot reasonably be used to identify you.</p>
              <ol start="5" type="1">
                <li ><strong>Analytics</strong></li>
              </ol>
              <p >We allow others to provide analytics services in connection with our Services. These entities may use cookies, web beacons, device identifiers, and other technologies to collect information about your use of our Services and across other websites and online services, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in mobile apps, and links clicked. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, and better understand your online activity.</p>
              <ol start="6" type="1">
                <li ><strong>Transfer of Information to the United States and Other Countries</strong></li>
              </ol>
              <p >REDNFT/Redline Blockchain has operations and service providers in the United States, Panama, and other countries. Therefore, we and our service providers may transfer information about you to jurisdictions that may not provide levels of data protection that are equivalent to those of your home jurisdiction. When that is the case, we will take steps designed to ensure that information about you receives an adequate level of protection in the jurisdictions in which we process it. This includes implementing contractual clauses specifically tailored to safeguard your personal data, in alignment with Panamanian regulations. These clauses will detail the handling, transfer, and processing of personal data, setting forth clear obligations and responsibilities for both parties involved.</p>
              <p >&nbsp;</p>
              <ol start="7" type="1">
                <li ><strong>Your Rights and Choices</strong></li>
              </ol>
              <div >
                <ol >
                  <li ><strong>Your Rights.</strong></li>
                </ol>
              </div>
              <p >In alignment with Panama&apos;s Law 81 of 2019, you have rights regarding your personal data, including access, rectification, erasure, and objection as follows:</p>
              <div >
                <ul >
                  <li ><strong>Access:</strong> You can request details about the personal data we hold about you.</li>
                </ul>
              </div>
              <ul type="disc">
                <li ><strong>Rectification:</strong> You have the right to correct inaccurate or incomplete information.</li>
                <li ><strong>Erasure:</strong> Under certain conditions, you may request the deletion of your data.</li>
                <li ><strong>Objection:</strong> You may oppose the processing of your data for specific purposes.</li>
              </ul>
              <p >However, these rights are not absolute and are subject to limitations. Crucially, if we do not have accurate and complete information, it may result in the cancellation of the services provided to you. This emphasizes the importance of providing and maintaining correct personal data to ensure continuous service.&nbsp;</p>
              <div >
                <ol >
                  <li ><strong>Your Choices</strong></li>
                </ol>
              </div>
              <ul type="disc">
                <li ><strong>Account Information</strong></li>
              </ul>
              <p >You may update and correct certain account information by logging into your account. If you wish to close your account, please use the <u><span><span >support@redlineblockchain.com</span></span></u> email and contact with our support to deactivate your account. Note that we may retain certain information as required by law or for our legitimate business purposes.</p>
              <ul type="disc">
                <li ><strong>Cookies</strong></li>
              </ul>
              <p >Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your browser settings to remove or reject browser cookies. Please note that removing or rejecting cookies could affect the availability and functionality of our Services.</p>
              <ul type="disc">
                <li ><strong>Communications Preferences</strong></li>
              </ul>
              <p >You may opt out of receiving marketing emails from REDNFT/Redline Blockchain by following the instructions in those communications. If you opt out, we may still send you other non-marketing emails, such as those about your account and transactions or our ongoing business relations.</p>
              <ul type="disc">
                <li ><strong>Mobile Push Notifications</strong></li>
              </ul>
              <p >With your consent, we may send push notifications to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.</p>
              <ol start="8" type="1">
                <li ><strong>Protection of Information</strong></li>
              </ol>
              <p >REDNFT/Redline Blockchain implements security practices and policies designed to protect the confidentiality and security of your nonpublic personal information, which includes the information you provide to register for a consumer account (such as Social Security number and other identifying information) and other non-public personal information we collect about you. REDNFT/Redline Blockchain implements measures designed to limit access to this information to personnel that have a business reason to know it and prohibits its personnel from unlawfully disclosing this information.</p>
              <p >If we receive your request from an authorized agent, we may ask for evidence that you have provided such an agent with a power of attorney or that the agent otherwise has valid written authority to submit requests to exercise rights on your behalf. If you are an authorized agent seeking to make a request, please contact us.</p>
              <p ><strong>8.2. Additional Disclosures for Users Outside the United States</strong></p>
              <p >Depending upon your jurisdiction, you may have certain additional rights and protections under the law regarding the processing of your personal data as further described below.</p>
              <p ><strong>8.3. Legal Basis for Processing</strong></p>
              <p >When we process your personal data, we will do so in reliance on the following lawful bases:</p>
              <ul type="disc">
                <li >To perform our responsibilities under our contract with you (e.g., providing the Services you requested).</li>
                <li >When we have a legitimate interest in processing your personal data to operate our business or protect our interests (e.g., to provide, maintain, and improve our Services, conduct data analytics, and communicate with you).</li>
                <li >To comply with our legal obligations (e.g., to maintain a record of any consents and track those who have opted out of marketing communications).</li>
                <li >When we have your consent to do so. When consent is the legal basis for our processing of your personal data, you may withdraw such consent at any time.</li>
              </ul>
              <ol start="9" type="1">
                <li ><strong>Questions or Complaints</strong></li>
              </ol>
              <p >If you have a concern about our processing of personal data, please email us at support@redlineblockchain.com</p>
              <p >&nbsp;</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
