import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";

export default function ModalSupply(props) {
  const [supply, setSupply] = useState(props?.supply - props?.supplySell);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const watchAllFields = watch();


  const onSubmit = async (data) => {
    props?.handleSignerSupply(data?.quantity, data?.price);
  };

  const handlePrice = (e) => {
    let value = e.target.value;
    if (/^0\d+/.test(value)) {
      value = value.replace(/^0(\d+)/, '0.$1');
    }
    if (!isNaN(value) || value === '') {
      setValue('price', value);
    }
  };

  return (
    <>
      <div
        className={`modal fade show`}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                List for sale
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Quantity <small>Max: {supply}</small></label>
                    <input
                      type="number"
                      pattern="\d+"
                      className="form-control"
                      {...register("quantity", { required: true })}
                      defaultValue={1}
                      min="1"
                      max={supply}
                      step="1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Price in {props?.data?.money}</label>
                    <input
                      type="text"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      className="form-control"
                      defaultValue={props?.data?.price_fixed}
                      {...register("price", { required: true })}
                      min="0"
                      onChange={handlePrice}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => props?.handleClose()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-accent"
                  disabled={
                    parseInt(watchAllFields?.quantity) >
                    parseInt(supply)
                    ||
                    watchAllFields?.quantity < 0
                    ||
                    parseFloat(watchAllFields?.price) <= 0
                    ||
                    !watchAllFields?.price
                  }
                >
                  Complete Listing
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
