import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import { useWallet } from "../../hooks/useWallet";
import SidebarMenuAccount from "../../components/SidebarMenuAccount";
import HeaderMenuAccount from "../../components/HeaderMenuAccount";
import Countdown from "../../components/CountDown";
import ShowMultimedia from "../../components/ShowMultimedia";
import ShowOwnerToken from "../../components/ShowOwnerToken";
import ModalLower from "../../components/ModalLower";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { env } from "process";

export default function Myitems() {
  const [tab, setTab] = useState("All");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsSale, setItemsSale] = useState([]);
  const [itemsSold, setItemsSold] = useState([]);
  const [listToken, setListToken] = useState([]);
  const [detail, setDetail] = useState([]);
  const [showModalLower, setshowModalLower] = useState(false);
  const [allItems, setAllItems] = useState(0);
  const { account, user } = useWallet();

  const getAllNFTCards = async () => {
    setLoading(true);
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/ipfs`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setAllItems(response.data.items.length);
        setListToken(response.data.items);
        setItems(
          [...response.data.items].filter(
            (item) => item.market_owner_id === user?.id
          )
        );
        setItemsSale(
          [...response.data.items].filter((item) => item.status === "1")
        );
        setItemsSold([...response.data.itemsSold]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllNFTCards();
  }, [user]);

  const changeTab = (tab) => {
    if (tab === "Sold") {
      console.log("Sold");
      console.log(itemsSold);
      setListToken(itemsSold);
      setTab("Sold");
    } else if (tab === "Sale") {
      console.log("Sale");
      console.log(itemsSale);
      setListToken(itemsSale);
      setTab("Sale");
    } else {
      console.log("All");
      console.log(items);
      setTab("All");
      setListToken(items);
    }
  };
  const closeDelete = (id) => {
    setshowModalLower(false);
    getAllNFTCards();
  };

  const modalLower = (data) => {
    setDetail(data);
    setshowModalLower(true);
  };
  return (
    <>
      {showModalLower && (
        <ModalLower
          show={showModalLower}
          handleClose={closeDelete}
          data={detail}
        />
      )}
      <main className="page-wrapper">
        <HeaderMenuAccount />
        <div className="container mb-5 pb-3">
          <div className="bg-light shadow-lg rounded-3 overflow-hidden">
            <div className="row">
              <SidebarMenuAccount />
              <section className="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                  <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-4">
                    <h1 className="h3 mb-sm-0 mb-2 pb-1 text-sm-start text-center">
                      My NFTs
                    </h1>
                    <div className="pb-1" style={{ overflowX: "auto" }}>
                      <ul className="nav nav-tabs nav-fill flex-nowrap text-nowrap mb-0">
                        <li className="nav-item">
                          <div
                            role="button"
                            className={`nav-link ${tab === "All" ? " active" : ""
                              } `}
                            onClick={() => changeTab("All")}
                          >
                            All <span className="opacity-70">({allItems})</span>
                          </div>
                        </li>
                        <li className="nav-item">
                          <div
                            role="button"
                            className={`nav-link ${tab === "Sale" ? " active" : ""
                              } `}
                            onClick={() => changeTab("Sale")}
                          >
                            On sale{" "}
                            <span className="opacity-70">
                              ({itemsSale.length})
                            </span>
                          </div>
                        </li>
                        <li className="nav-item">
                          <div
                            role="button"
                            className={`nav-link ${tab === "Sold" ? " active" : ""
                              } `}
                            onClick={() => changeTab("Sold")}
                          >
                            Sold{" "}
                            <span className="opacity-70">
                              ({itemsSold.length})
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 gx-3 mb-4">
                    {listToken.length > 0 &&
                      listToken?.map((item, index) => (
                        <div className="col mb-2" key={index}>
                          <article className="card h-100 border-0 shadow">
                            <div className="card-img-top position-relative overflow-hidden">
                              <Link
                                className="d-block"
                                to={`/nft/view/${item.contract
                                  ? item.contract
                                  : process.env.REACT_APP_CONTRACT_COLLECTION
                                  }/${item.id}`}
                              >
                                <ShowMultimedia
                                  file={item?.multimedia}
                                  alt={item?.name}
                                  type={item?.type_multimedia}
                                />
                              </Link>
                              {item?.type === "2" && (
                                <Countdown
                                  model="minimalist"
                                  dateEnd={item?.bid_expiration_date}
                                  dateStart={item?.bid_starting_date}
                                />
                              )}

                              {item.market_owner_id === user?.id &&
                                item.status === "2" && (
                                  <button
                                    className="btn-wishlist btn-sm position-absolute top-0 end-0"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Edit"
                                    onClick={() => modalLower(item)}
                                    style={{ margin: "12px" }}
                                  >
                                    <i className="ci-currency-exchange"></i>
                                  </button>
                                )}
                              {item.market_users_id === user?.id &&
                                item.status === "0" && (
                                  <Link
                                    className="d-block"
                                    to={`/nft/edit/${item.id}`}
                                  >
                                    <button
                                      className="btn-wishlist btn-sm position-absolute top-0 end-0"
                                      type="button"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="left"
                                      title="Edit"
                                      style={{ margin: "12px" }}
                                    >
                                      <i className="ci-edit"></i>
                                    </button>
                                  </Link>
                                )}
                            </div>
                            <div className="card-body">
                              <h3 className="product-title mb-2 fs-base">
                                <Link
                                  className="d-block text-truncate"
                                  to={`/nft/view/${item.contract
                                    ? item.contract
                                    : process.env
                                      .REACT_APP_CONTRACT_COLLECTION
                                    }/${item.id}`}
                                >
                                  {item?.name}
                                </Link>
                              </h3>
                              <span className="fs-sm text-muted">
                                Current {item?.type === "1" && "Price"}
                                {item?.type === "2" && "Minimum Bid"}
                                {item?.type === "3" && "Membership Suncription"}
                                :
                              </span>
                              <div className="d-flex align-items-center flex-wrap">
                                <h4 className="mt-1 mb-0 fs-base text-darker">
                                  {item?.standard === "ERC-1155" && item?.type === "1" ? "See Price" : item?.price_fixed + " " + item?.money}
                                  {item?.type === "2" && item?.price_minimum_bid}{" "}
                                </h4>
                                {/* 
                              <span className="mt-1 ms-1 fs-xs text-muted">
                                (≈ $ 2,000.55)
                              </span>
                              */}
                              </div>
                            </div>
                            <div className="card-footer mt-n1 py-0 border-0">
                              <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                <ShowOwnerToken owners={item?.owners} />
                              </div>
                            </div>
                          </article>
                        </div>
                      ))}
                  </div>
                  <div className="d-flex flex-sm-row flex-column">
                    <Link className="btn btn-accent" to={"/nft/create"}>
                      Create new item
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
