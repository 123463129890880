import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import avatarDefault from "../assets/img/nft/vendor/avatar-sm.png";
export default function ModalOwners(props) {
  const [loading, setLoading] = useState(false);
  const [owners, setOwners] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getOwners();
  }, [props?.data?.id]);

  const getOwners = () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/ipfs-public-owners/${props?.data?.id}`
    );
    fetch(`${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setOwners(response.data);
        console.log(response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const URLAuthor = (id) => {
    props?.handleClose();
    navigate(`/author/${id}`);
  };
  return (
    <>
      <div
        className={`modal fade show `}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Owned by
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <ul className="list-group">
                    {owners.length > 0 && (
                      <>
                        {owners.map((item, index) => (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            <div className="d-inline-flex align-items-center position-relative">
                              <img
                                className="rounded-circle me-2"
                                width="32"
                                src={
                                  item?.owner?.avatar
                                    ? item?.owner?.avatar
                                    : avatarDefault
                                }
                                alt={item?.owner?.name}
                              />
                              <div className="fs-sm">
                                <span
                                  role="button"
                                  className="text-primary"
                                  onClick={() => URLAuthor(item?.owner?.id)}
                                >
                                  @{item?.owner?.username}
                                </span>
                              </div>
                            </div>
                            <div>{item?.supply} items</div>
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
