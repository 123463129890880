import React, { useState, useEffect, useContext } from "react";
import { useWallet } from "../hooks/useWallet";
import AppContext from "../context/AppContex";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function ModalOffer(props) {
  const [balance, setBalance] = useState("0");
  const [balanceRLC, setBalanceRLC] = useState("0");
  const [balanceUSDC, setBalanceUSDC] = useState("0");
  const [loading, setLoading] = useState(false);
  const { account, user } = useWallet();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { getBalanceRLC, getBalanceUSDC, getBalance } = useContext(AppContext);

  useEffect(() => {
    getBalanceWallet();
  }, [account]);

  const getBalanceWallet = async () => {
    console.log(account);
    if (account !== "") {
      let balanceUSDCTemp = await getBalanceUSDC(account);
      let balanceRLCTemp = await getBalanceRLC(account);
      let balanceMaticTemp = await getBalance(account);
      setBalance(balanceMaticTemp);
      setBalanceRLC(balanceRLCTemp);
      setBalanceUSDC(balanceUSDCTemp);
    }
  };

  const onSubmit = async (data) => {
    const id = toast.loading("Please wait...");
    setLoading(true);
    console.log(data);
    console.log(props?.data);
    let tokenData = props?.data;
    var formData = new FormData();
    formData.append("ipfs_id", tokenData.id);
    formData.append("network", process.env.REACT_APP_NETWORK_TYPE);
    formData.append("price", data.amount);
    formData.append("money", tokenData.money);
    formData.append("expiration", data.expiration);
    fetch(`${process.env.REACT_APP_API_BACKEND}/offer`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        toast.update(id, {
          render: "Offer sent. ✅🙂",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setLoading(false);
        const timer = setTimeout(() => {
          window.location.reload();
        }, 5000);
        props?.handleClose();
      })
      .catch((error) => {
        setLoading(false);
        toast.update(id, {
          render: "something went wrong, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });

        props?.handleClose();
      });
  };


  const handlePriceChange = (e) => {
    let value = e.target.value;
    if (/^0\d+/.test(value)) {
      value = value.replace(/^0(\d+)/, '0.$1');
    }
    if (!isNaN(value) || value === '') {
      setValue('amount', value);
    }
  };
  return (
    <>
      <div
        className={`modal fade show`}
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Make an offer
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                onClick={() => props?.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Offer Amount</label>
                    <input
                      type="text"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      className="form-control"
                      placeholder="Amount"
                      {...register("amount", { required: true })}
                      onChange={handlePriceChange}
                      min="0"
                      max={
                        props?.data?.money === "RLC"
                          ? balanceRLC.slice(0, 20)
                          : balance.slice(0, 20)
                      }
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      Balance:{" "}
                      {props?.data?.money === "RLC"
                        ? balanceRLC.slice(0, 20)
                        : balance.slice(0, 20)}{" "}
                      {props?.data?.money === "RLC" ? "RLC" : "MATIC"}
                    </small>
                  </div>
                  <br />
                  <br />
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                      Offer Expiration
                    </label>
                    <input
                      type="date"
                      {...register("expiration", { required: true })}
                      className="form-control"
                      placeholder="Date"
                      min="today"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => props?.handleClose()}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-accent">
                  Make offer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade show`}
        onClick={() => props?.handleClose()}
      ></div>
    </>
  );
}
