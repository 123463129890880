import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import avatarDefaultTwo from "../../assets/img/nft/vendor/avatar.png";
import avatarDefaultSquare from "../../assets/img/nft/vendor/avatar-square.jpg";
import { useWallet } from "../../hooks/useWallet";
import AppContext from "../../context/AppContex";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalTermsAndConditions from "../../components/ModalTermsAndConditions";
export default function ApprovedAll() {
  let { contract, tokenId } = useParams();
  const [token, setToken] = useState(undefined);
  const [activeTab, setActiveTab] = useState("details");
  const [loading, setLoading] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const { account, keyAddress, user, signin } = useWallet();
  const { state, approvedAllNFT } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (keyAddress) {
      haddleApprovedNFT();
    }
  }, [keyAddress]);

  const haddleApprovedNFT = async () => {
    setLoading(true);
    const id = toast.loading("Creating NFT! ");
    await approvedAllNFT(account, keyAddress)
      .then((response) => {
        // Activity List
        console.log("transaccion completed");
        console.log(response);
        updateApproved();
        toast.update(id, {
          render: "NFT creation completed! " + response.transactionHash,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.update(id, {
          render: "Transaction failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);
      });
  };

  const updateApproved = () => {
    fetch(`${process.env.REACT_APP_API_BACKEND}/users/${user.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        approvedRLC: 1,
        approvedNFT: 1,
        name: user.name,
        username: user.username,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        let update = { ...user, approvedNFT: "1", approvedRLC: "1" };
        signin(update);
        navigate("/nft/create");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const closeModal = () => {
    setshowModal(false);
  };

  const acceptModal = () => {
    setshowModal(false);
    setTermsAndConditions(false);
  };
  return (
    <>
      {showModal && (
        <ModalTermsAndConditions
          show={showModal}
          handleClose={closeModal}
          handleAccept={acceptModal}
        />
      )}
      <main className="page-wrapper">
        <div className="bg-accent mb-4 py-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/feed">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-nowrap">
                    <Link to={"/explorer"}>NFT Marketplace</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    Connect Wallet
                  </li>
                </ol>
              </nav>
            </div>
            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
              <h1 className="h3 text-light mb-0">Connect Wallet</h1>
            </div>
          </div>
        </div>

        <section className="container mb-5 pt-1 pb-md-5 pb-4">
          <div className="row  gy-sm-grid-gutter gy-3">
            <div
              className="col-12"
              style={{ float: "none", textAlign: "center" }}
            >
              <article className="card border-0 shadow position-relative h-100 ">
                <div className="card-body">
                  <h2 className="h5 mb-2">RED WALLET</h2>
                  <p className="mb-2 fs-sm text-muted text-left">
                    To create NFT we need you to sign a transaction for token
                    creation and Matic or RLC acceptance
                  </p>
                  <p
                    role="button"
                    onClick={() => setshowModal(true)}
                    className="text-left fs-sm mb-2"
                    style={{ color: "#4E54C8" }}
                  >
                    Read and accept terms and conditions.
                  </p>
                  <div className="mb-0 fs-sm text-muted">
                    <button
                      type="button"
                      className="btn btn-lg btn-accent d-block w-100 mb-4"
                      id="redline_signBrowser"
                      disabled={loading || termsAndConditions}
                    >
                      Sign Transacction
                    </button>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
