import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import { useWallet } from "../../hooks/useWallet";
import AppContext from "../../context/AppContex";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "../../components/CountDown";
import ModalLower from "../../components/ModalLower";
import Modal from "../../components/Modal";
import ModalOffer from "../../components/ModalOffer";
import FavoriteButton from "../../components/FavoriteButton";
import ShareButton from "../../components/ShareButton";
import ModalDelete from "../../components/ModalDelete";
import ShowMultimediaDetail from "../../components/ShowMultimediaDetail";
import ModalDisclaimer from "../../components/ModalDisclaimer";
import ModalSupply from "../../components/ModalSupply";
import ModalOwners from "../../components/ModalOwners";
import ModalBurn1155 from "../../components/ModalBurn1155"
import moment from "moment";
import Loading from "../../components/Loading";

export default function View() {
  let { contract, tokenId } = useParams();
  const [signerMethod, setSignerMethod] = useState("");
  const [token, setToken] = useState(undefined);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [supplySell, setSupplySell] = useState(1);
  const [priceSell, setPriceSell] = useState(0);
  const [offerList, setOfferList] = useState([]);
  const [sellList, setSellList] = useState([]);
  const [offerSend, setOfferSend] = useState(false);
  const [activeTab, setActiveTab] = useState("details");
  const [loading, setLoading] = useState(false);
  const [isOwner, setIsOwner] = useState([]);
  const [signerInfo, setSignerInfo] = useState();
  const [buySupply, setBuySupply] = useState(1);
  const [showModal, setshowModal] = useState(false);
  const [showModalDisclaimer, setShowModalDisclaimer] = useState(false);
  const [showModalOffer, setShowModalOffer] = useState(false);
  const [showModalDelete, setshowModalDelete] = useState(false);
  const [showModalLower, setshowModalLower] = useState(false);
  const [showModalSupply, setshowModalSupply] = useState(false);
  const [showModalOwners, setshowModalOwners] = useState(false);
  const [showModalBurn, setShowModalBurn] = useState(false);
  const [searchParams] = useSearchParams();
  const [deleteInfo, setDeleteInfo] = useState({
    type: "post",
    id: null,
  });

  const { account, keyAddress, user, message, signout, postMessage } = useWallet();
  const { signNFT, approvedRLC, buyNFT, sendTransactionNFT, sendTransactionNFT1155 } = useContext(AppContext);

  useEffect(() => {
    getToken();
  }, [tokenId]);

  useEffect(() => {
    console.log(searchParams.get("view"));
    if (searchParams.get("view")) {
      setActiveTab("bids");
    }
  }, [searchParams]);

  useEffect(() => {
    /** Add View */
    var dataForm = new FormData();
    dataForm.append("market_ipfs_id", tokenId);
    fetch(`${process.env.REACT_APP_API_BACKEND}/views`, {
      method: "POST",
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("view add");
      })
      .catch((error) => {
        console.log(error);
      });
  }, [tokenId]);

  useEffect(() => {
    console.log(message);
    console.log(keyAddress);
    console.log(signerMethod);
    if (signerMethod === "Mint" && keyAddress) {
      console.log("Minteo");
      haddleSell(); //Status 0
      return;
    } else if (signerMethod === "Sell" && keyAddress) {
      console.log("Sell");
      haddleSell(); //Status 1
      return;
    } else if (signerMethod === "CancelList" && keyAddress) {
      console.log("Cancelar Lista");
      handdleCancelList(); //Status 2
      return;
    } else if (signerMethod === "Buy" && keyAddress) {
      console.log("Comprar");
      haddleApproved();
      //haddleBuy();
      return;
    } else if (signerMethod === "Burn" && keyAddress) {
      console.log("Burn Quemar");
      haddleBurn();
      //haddleBuy();
      return;
    } else if (signerMethod === "Burn1155" && keyAddress) {
      console.log("Burn 1155");
      haddleBurn1155();
      //haddleBuy();
      return;
    }
  }, [message]);

  const getOffer = async () => {
    /** Get Offer */
    if (localStorage.getItem("token")) {
      setLoading(true);
      let url = new URL(
        `${process.env.REACT_APP_API_BACKEND}/offer/${tokenId}`
      );
      fetch(`${decodeURIComponent(url)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setOfferList(response.data);
          setOfferSend(response.myoffer ? true : false);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };


  const getToken = async () => {
    /** Information Token */
    console.log('entro aqui')
    setLoading(true);
    setLoadingTotal(true);
    getOffer();
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/ipfs-public/${tokenId}`
    );
    fetch(`${decodeURIComponent(url)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data);
        setToken(response.data);
        setIsOwner(response.data?.isOwner);
        setSellList(response.data?.sell);
        setLoading(false);
        setLoadingTotal(false);
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
        window.location.href = "/account/items";
      });
  };

  const getSigner = async (signerId) => {
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/signer-ipfs/${signerId}`
    );
    fetch(`${decodeURIComponent(url)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response?.data?.signer);
        haddleBuy(response?.data?.signer);
        return response;
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const haddleSell = async () => {
    setLoading(true);
    var trx = "";
    const id = toast.loading("Sell in progress. 🤖");
    let pricetoWei;
    if (token?.type === "1") {
      pricetoWei = token?.price_fixed;
    } else if (token?.type === "2") {
      pricetoWei = token?.price_minimum_bid;
    } else if (token?.type === "3") {
      pricetoWei = token?.price_membership_month;
    }
    if (token?.standard === "ERC-1155") {
      pricetoWei = priceSell;
    }
    console.log("Signer");
    console.log("Precio", pricetoWei);
    trx = await signNFT(
      tokenId,
      account,
      pricetoWei,
      keyAddress,
      token?.standard,
      token?.money
    )
      .then((response) => {
        updateSigner(response[0], response[1], supplySell);
        addActivity({
          ipfs_id: tokenId,
          event: "List",
          price: pricetoWei,
          from: user?.address,
          to: process.env.REACT_APP_CONTRACT_MARKET,
          date: new Date().toISOString(),
          trx: "",
          network: "Polygon",
        });
        toast.update(id, {
          render: "Sell completed. 🤩🤑",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        toast.update(id, {
          render: "Transaction failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 50000);
        setLoading(false);
      });
  };

  const handdleCancelList = async () => {
    setLoading(true);
    console.log("Cancel listing");
    var trx = "";
    let pricetoWei;
    if (token?.type === "1") {
      pricetoWei = token?.price_fixed;
    } else if (token?.type === "2") {
      pricetoWei = token?.price_minimum_bid;
    } else if (token?.type === "3") {
      pricetoWei = token?.price_membership_month;
    }
    addActivity({
      ipfs_id: tokenId,
      event: "Cancel List",
      price: pricetoWei,
      from: user?.address,
      to: process.env.REACT_APP_CONTRACT_MARKET,
      date: new Date().toISOString(),
      trx: "",
      network: "Polygon",
    });
    updateTokenStatus(2);
    deleteSigner();
  };

  const haddleApproved = async () => {
    setLoading(true);
    if (token?.money === "MATIC") {
      getSigner(signerInfo.id);
      return;
    }
    const id = toast.loading("Approval in progress.  🤖");
    let pricetoWei = signerInfo?.price_fixed * buySupply;
    console.log(pricetoWei)
    await approvedRLC(account, keyAddress, pricetoWei, token?.money, token?.standard)
      .then((response) => {
        console.log(response);
        toast.update(id, {
          render: "Approval completed. ✅🙂 " + response.transactionHash,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        getSigner(signerInfo.id);
      })
      .catch((error) => {
        console.log(error);
        toast.update(id, {
          render: "Transaction failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 50000);
        setLoading(false);
      });
  };

  const haddleBuy = async (signer) => {
    var trx = "";
    setLoading(true);
    const id = toast.loading("Buy in progress. 🤖");
    let pricetoWei;
    if (token?.type === "1") {
      pricetoWei = signerInfo?.price_fixed * buySupply;
    } else if (token?.type === "2") {
      pricetoWei = signerInfo?.price_fixed * buySupply;
    }
    let supply = buySupply ? buySupply : signerInfo.supply;
    let supplyMax = token?.supplyMax;
    console.log("BUY NFT");
    trx = await buyNFT(
      account,
      keyAddress,
      tokenId,
      pricetoWei,
      token?.money,
      token?.royalties,
      supplyMax,
      supply,
      signer,
      token?.standard,
      signerInfo?.id
    )
      .then((response) => {
        // Activity List
        toast.update(id, {
          render: "Buy completed. 😋 " + response.transactionHash,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        addActivity({
          ipfs_id: tokenId,
          event: "Sale",
          price: pricetoWei,
          from: user?.address,
          to: token.address,
          date: new Date().toISOString(),
          trx: response.transactionHash,
          network: "Polygon",
        });
        addActivity({
          ipfs_id: tokenId,
          event: "Transfer",
          price: pricetoWei,
          from: token.address,
          to: user?.address,
          date: new Date().toISOString(),
          trx: response.transactionHash,
          network: "Polygon",
        });
        buyTokenStatus();
      })
      .catch((error) => {
        console.log(error);
        toast.update(id, {
          render: "Transaction failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        toast.update(id, {
          render: JSON.stringify(error),
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        //descomentar
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);
        setLoading(false);
      });
  };


  const haddleBurn = async (signer) => {
    var trx = "";
    setLoading(true);
    console.log(
      account,
      //"0x0000000000000000000000000000000000000000",
      "0x2FCf7d3001A0754F22f0C10331b8F9bA06ec2980",
      token?.contract,
      token?.token_id,
      keyAddress)
    const id = toast.loading("Burn in progress. 🤖");
    trx = await sendTransactionNFT(
      account,
      "0x2FCf7d3001A0754F22f0C10331b8F9bA06ec2980",
      token?.contract,
      token?.token_id,
      keyAddress
      /* 
      accountAddressN.trim(),
      address.trim(),
      nft.token_address,
      nft.token_id,
      keyAddressN
      */
    )
      .then((response) => {
        // Activity List
        tokenBurn();
        setLoading(false);
        toast.update(id, {
          render: "The burning was successful.",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.update(id, {
          render: "Transaction failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);
        setLoading(false);
      });
  };



  const haddleBurn1155 = async (signer) => {
    var trx = "";
    setLoading(true);
    const id = toast.loading("Burn in progress. 🤖");
    console.log(account,
      token?.contract,
      token?.token_id,
      supplySell,
      keyAddress);
    trx = await sendTransactionNFT1155(
      account,
      "0x2FCf7d3001A0754F22f0C10331b8F9bA06ec2980",
      token?.contract,
      token?.token_id,
      supplySell,
      keyAddress
    )
      .then((response) => {
        // Activity List
        toast.update(id, {
          render: "The burning was successful.",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        tokenBurn1155();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.update(id, {
          render: "Transaction failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);
        setLoading(false);
      });
  };

  const addActivity = async (data) => {
    var dataForm = new FormData();
    dataForm.append("ipfs_id", data?.ipfs_id ? data.ipfs_id : "");
    dataForm.append("event", data?.event ? data.event : "");
    dataForm.append("price", data?.price ? data.price : "");
    dataForm.append("from", data?.from ? data.from : "");
    dataForm.append("to", data?.to ? data.to : "");
    dataForm.append("trx", data?.trx ? data.trx : "");
    dataForm.append("network", data?.network ? data.network : "");
    fetch(`${process.env.REACT_APP_API_BACKEND}/ipfs-activity`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Add Activity", response);
      })
      .catch((error) => {
        toast.error("Error in Upload Activity!");
        console.log(error);
      });
  };

  const updateTokenStatus = async (status) => {
    setLoading(true);
    const id = toast.loading("Updating NFT");
    fetch(
      `${process.env.REACT_APP_API_BACKEND}/ipfs-status/${isOwner.idOwner}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          status: status,
          contract: contract,
          token_id: tokenId,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("Transaction Completed", response);
        if (response.status === false) {
          toast.update(id, {
            render: response.msg,
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
        //descomentar 
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        toast.update(id, {
          render: "Update failed, please try again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.log(error);
      });
  };

  const updateSigner = async (signer, contract, supply) => {
    var dataForm = new FormData();
    dataForm.append("id", tokenId);
    dataForm.append("token_id", tokenId);
    dataForm.append("contract", contract);
    dataForm.append("signer", signer);
    dataForm.append("supply", supply);
    if (token?.standard === "ERC-1155") {
      dataForm.append("price_fixed", priceSell);
    } else {
      dataForm.append("price_fixed", token?.price_fixed);
    }
    fetch(`${process.env.REACT_APP_API_BACKEND}/signer-ipfs`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        updateTokenStatus(1);
        return response;
      })
      .catch((error) => {
        toast.error("Error in Signer NFT!");
        console.log(error);
      });
  };

  const deleteSigner = async () => {
    fetch(
      `${process.env.REACT_APP_API_BACKEND}/signer-ipfs/${token?.signer_id}`,
      {
        method: "delete",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const deleteSigner1155 = async (item) => {
    setLoading(true);
    const id = toast.loading("Canceling sale.");
    fetch(
      `${process.env.REACT_APP_API_BACKEND}/signer-ipfs/${item?.id}`,
      {
        method: "delete",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        addActivity({
          ipfs_id: tokenId,
          event: "Cancel List",
          price: item?.price_fixed,
          from: user?.address,
          to: process.env.REACT_APP_CONTRACT_MARKET,
          date: new Date().toISOString(),
          trx: "",
          network: "Polygon",
        });
        toast.update(id, {
          render: "Sale cancelled",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        console.log(response);
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
        toast.update(id, {
          render: "Error when trying to cancel",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.log(error);
      });
  };

  const buyTokenStatus = async () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_BACKEND}/ipfs-change/${tokenId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        status: 2,
        token_id: tokenId,
        address: user?.address,
        market_owner_id: user?.id,
        signer: signerInfo?.id,
        supply: buySupply
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Transaction Completed", response);
        //descomentar
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  const tokenBurn = async () => {
    fetch(`${process.env.REACT_APP_API_BACKEND}/ipfs/${tokenId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Transaction Completed", response);
        setLoading(false);
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.href = "/account/items";
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const tokenBurn1155 = async () => {
    var dataForm = new FormData();
    dataForm.append("token_id", token?.id);
    dataForm.append("supply", supplySell);
    fetch(`${process.env.REACT_APP_API_BACKEND}/ipfs-burn`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: dataForm,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        const timer = setTimeout(() => {
          localStorage.removeItem("keyAddress");
          window.location.href = "/account/items";
        }, 1000);
      })
      .catch((error) => {
        toast.error("Error in Signer NFT!");
        console.log(error);
      });
  };


  const handleDeletedContent = async () => {
    setLoading(true);
    if (deleteInfo.id === null) {
      return;
    }
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/ipfs/${deleteInfo.id}`
    );
    fetch(`${url}`, {
      method: "delete",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setLoading(false);
        setshowModalDelete(false);
        window.location.replace(`/author/${user?.id}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const acceptOffer = async (offerId) => {
    const id = toast.loading("Accepting the offer. 🤖");
    fetch(`${process.env.REACT_APP_API_BACKEND}/offer-accept/${offerId}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        getToken();

        setLoading(false);
        toast.update(id, {
          render: "Offer accepted, price updated. 😏",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        toast.error("Error in action!");
        console.log(error);
        setLoading(false);
      });
  };

  const rejectOffer = async (offerId) => {
    const id = toast.loading(" Rejecting the offer. 🤖");
    fetch(`${process.env.REACT_APP_API_BACKEND}/offer-reject/${offerId}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        getToken();
        setLoading(false);
        toast.update(id, {
          render: "Offer rejected. 🧐",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        toast.error("Error in action!");
        console.log(error);
        setLoading(false);
      });
  };

  const rejectOfferAuthor = async (offerId) => {
    const id = toast.loading("Cancelling your offer. 🤖");
    fetch(`${process.env.REACT_APP_API_BACKEND}/offer/${offerId}`, {
      method: "DELETE",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        getToken();
        setLoading(false);
        toast.update(id, {
          render: "Offer canceled. 🙃",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        toast.error("Error in action!");
        console.log(error);
        setLoading(false);
      });
  };

  const signerType = (supply) => {
    console.log(supply)
    console.log(signerInfo?.supply)
    console.log(signerInfo)
    setBuySupply(supply)
    if (Number(supply) > Number(signerInfo?.supply)) {
      toast.error("Error when trying to make the purchase, please try again.");
      return
    }
    postMessage("signBrowser");
    /*
    Mint
    Sell
    CancelList
    Buy
    */
  };

  const signerManual = (type) => {
    setSignerMethod(type);
    postMessage("signBrowser");
  };

  const openModal = (type, signerData) => {
    console.log("Signer Info", signerData);
    setSignerInfo(signerData);
    setSignerMethod(type);
    setshowModal(true);
  };
  const closeModal = () => {
    setshowModal(false);
  };

  const closeModalOffer = () => {
    setShowModalOffer(false);
  };

  const showDelete = (id) => {
    setDeleteInfo({
      type: "NFT",
      id: id,
    });
    setshowModalDelete(true);
  };

  const closeDelete = (id) => {
    setshowModalDelete(false);
    setshowModalLower(false);
    setShowModalDisclaimer(false);
    setshowModalSupply(false);
    setshowModalOwners(false);
    setShowModalBurn(false);
    getToken();
  };

  const signerSell = () => {
    console.log("Signer Sell");
    closeDelete();
    if (token?.supply > 1) {
      setshowModalSupply(true);
      console.log("Entro a Supply");
    } else {
      signerManual("Sell");
    }
  };

  const signerSellSupply = (qty, price) => {
    setSupplySell(qty);
    setPriceSell(price);
    closeDelete();
    signerManual("Sell");
  };


  const signerBurn = () => {
    signerManual("Burn");
    //tokenBurn();
  };

  const signerBurn1155 = (qty) => {
    setSupplySell(qty);
    closeDelete();
    signerManual("Burn1155");
    //tokenBurn();
  };

  const cancelListingGo = () => {
    console.log('debe buscar primero si sigue siendo dueño');
    signerManual("CancelList");
  }

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          data={token}
          signer={signerInfo}
          handleClose={closeModal}
          handleSigner={signerType}
          operation={signerMethod}
          loading={loading}
        />
      )}
      {showModalOffer && (
        <ModalOffer
          show={showModalOffer}
          data={token}
          handleClose={closeModalOffer}
          loading={loading}
        />
      )}
      {showModalDelete && (
        <ModalDelete
          show={showModalDelete}
          handleClose={closeDelete}
          handleDelete={handleDeletedContent}
          data={deleteInfo}
        />
      )}
      {showModalLower && (
        <ModalLower
          show={showModalLower}
          handleClose={closeDelete}
          data={token}
        />
      )}

      {showModalDisclaimer && (
        <ModalDisclaimer
          show={showModalDisclaimer}
          handleClose={closeDelete}
          handleSigner={signerSell}
        />
      )}

      {showModalSupply && ( // Si es 1155 Abre este Modal
        <ModalSupply
          show={showModalSupply}
          handleClose={closeDelete}
          handleSignerSupply={signerSellSupply}
          data={token}
          supply={isOwner?.supply}
          supplySell={isOwner?.sellTotals}
        />
      )}

      {showModalOwners && (
        <ModalOwners
          show={showModalOwners}
          handleClose={closeDelete}
          data={token}
        />
      )}

      {showModalBurn && (
        <ModalBurn1155
          show={showModalBurn}
          handleClose={closeDelete}
          handleBurnSupply={signerBurn1155}
          data={token}
          supply={isOwner?.supply}
          supplySell={isOwner?.sellTotals}
        />
      )}


      <main className="page-wrapper">
        <div className="page-title-overlap bg-darker pt-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to={"/"}>
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-nowrap">
                    <Link to={"/explorer"}>Marketplace</Link>
                  </li>
                  {token?.standard === "ERC-721" && (
                    <li className="breadcrumb-item text-nowrap">
                      <Link to={`/author/${token?.creator?.id}?creator=true`}>
                        @{token?.creator?.username}
                      </Link>
                    </li>
                  )}
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    {token?.name}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <section className="container pb-md-4">
          {loadingTotal ?
            <Loading />

            :
            <>
              <div className="bg-light shadow-lg rounded-3 px-4 py-lg-4 py-3 mb-5">
                {!loading && isOwner?.id && (
                  <div className="row row-cols-sm-12 row-cols-12 g-sm-4 g-3 mb-4">
                    {isOwner?.status === "0" && (
                      <>
                        <div className="col">
                          <button
                            type="button"
                            disabled={loading}
                            className="btn btn-lg btn-outline-info d-block w-100"
                            onClick={() => setShowModalDisclaimer(true)}
                          >
                            Sell
                          </button>
                        </div>
                        <div className="col">
                          <Link to={`/nft/edit/${token?.id}`}>
                            <button
                              type="button"
                              disabled={loading}
                              className="btn btn-lg btn-outline-accent d-block w-100"
                            >
                              Edit
                            </button>
                          </Link>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => showDelete(token?.id)}
                            className="btn btn-lg btn-outline-primary d-block w-100"
                          >
                            Delete
                          </button>
                        </div>
                      </>
                    )}
                    {isOwner?.status === "1" && token?.standard === "ERC-721" && (
                      <div className="col">
                        <button
                          type="button"
                          disabled={loading}
                          className="btn btn-lg btn-outline-accent d-block w-100"
                          onClick={() => cancelListingGo()}
                        >
                          Cancel Listing
                        </button>
                      </div>
                    )}
                    {isOwner?.status === "2" && token?.standard === "ERC-721" && (
                      <>
                        <div className="col">
                          <button
                            type="button"
                            disabled={loading}
                            className="btn btn-lg btn-outline-accent d-block w-100"
                            onClick={() => signerSell()}
                          >
                            Sell
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            disabled={loading}
                            className="btn btn-lg btn-outline-accent d-block w-100"
                            onClick={() => setshowModalLower(true)}
                          >
                            Change Price
                          </button>
                        </div>
                        <div className="col">
                          {token?.minting === "1" ? <button
                            type="button"
                            disabled={loading}
                            className="btn btn-lg btn-outline-primary d-block w-100"
                            onClick={() => signerBurn()}
                          >
                            Burn
                          </button> : <button
                            type="button"
                            disabled={loading}
                            onClick={() => showDelete(token?.id)}
                            className="btn btn-lg btn-outline-primary d-block w-100"
                          >
                            Delete
                          </button>}

                        </div>
                      </>
                    )}
                    {token?.standard === "ERC-1155" && isOwner?.status !== "0" && isOwner?.sellTotals < isOwner?.supply && (
                      <>
                        <div className="col">
                          <button
                            type="button"
                            disabled={loading}
                            className="btn btn-lg btn-outline-accent d-block w-100"
                            onClick={() => token?.minting === "1" ? signerSell() : setShowModalDisclaimer(true)}
                          >
                            List a sale
                          </button>
                        </div>
                        <div className="col">
                          {token?.minting === "1" ? <button
                            type="button"
                            disabled={loading}
                            className="btn btn-lg btn-outline-primary d-block w-100"
                            onClick={() => setShowModalBurn(true)}
                          >
                            Burn
                          </button> : <button
                            type="button"
                            disabled={loading}
                            onClick={() => showDelete(token?.id)}
                            className="btn btn-lg btn-outline-primary d-block w-100"
                          >
                            Delete
                          </button>}
                        </div>
                      </>
                    )}

                  </div>
                )}

                <div className="py-lg-3 py-2 px-lg-3">
                  <div className="row gy-4">
                    <div className="col-lg-6">
                      <div className="position-relative rounded-3 overflow-hidden mb-lg-4 mb-2">
                        {token?.multimedia && (
                          <ShowMultimediaDetail
                            file={token?.multimedia}
                            alt={token?.name}
                            type={token?.type_multimedia}
                          />
                        )}
                      </div>
                      <div className="pt-2 text-lg-start text-center">
                        {token?.multimedia && (
                          <FavoriteButton
                            isLike={token?.isLike}
                            tokenId={token?.id}
                            detail={true}
                          />
                        )}
                        <ShareButton
                          name={token?.name}
                          description={token?.description}
                        />
                        {/*
                  <button
                    className="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2"
                    type="button"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Flag"
                  >
                    <i className="ci-flag"></i>
                  </button>
*/}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="ps-xl-5 ps-lg-3">
                        <h2 className="h3 mb-3">{token?.name}</h2>
                        <div className="d-flex align-items-center flex-wrap text-nowrap mb-sm-4 mb-3 fs-sm">
                          <div className="mb-2 me-sm-3 me-2 text-muted">
                            Minted on {moment(token?.created).calendar()}
                          </div>
                          <div className="mb-2 me-sm-3 me-2 ps-sm-3 ps-2 border-start text-muted">
                            <i className="ci-eye me-1 fs-base mt-n1 align-middle"></i>
                            {token?.information?.views} views
                          </div>
                          <div className="mb-2 me-sm-3 me-2 ps-sm-3 ps-2 border-start text-muted">
                            <i className="ci-heart me-1 fs-base mt-n1 align-middle"></i>
                            {token?.information?.favorites} favorite
                          </div>
                        </div>
                        <div className="row row-cols-sm-2 row-cols-1 gy-3 gx-4 mb-4 pb-md-2">
                          <div className="col">
                            <div className="card position-relative h-100">
                              <div className="card-body p-3">
                                <h3 className="h6 mb-2 fs-sm text-muted">
                                  Creator
                                </h3>
                                <div className="d-flex align-items-center">
                                  <img
                                    className="rounded-circle me-2"
                                    src={
                                      token?.creator?.avatar
                                        ? token.creator.avatar
                                        : avatarDefault
                                    }
                                    width="32"
                                    alt={token?.creator?.username}
                                  />
                                  <Link
                                    className="nav-link-style stretched-link fs-sm"
                                    to={`/author/${token?.creator?.id}?creator=true`}
                                  >
                                    @{token?.creator?.username}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="card position-relative h-100">
                              <div className="card-body p-3">
                                <h3 className="h6 mb-2 fs-sm text-muted">
                                  Category
                                </h3>
                                <div className="d-flex align-items-center">
                                  <img
                                    className="rounded-circle me-2"
                                    src={avatarDefault}
                                    width="32"
                                    alt={token?.category}
                                  />
                                  <Link
                                    className="nav-link-style stretched-link fs-sm"
                                    to={`/explorer?category=${token?.category}`}
                                  >
                                    {token?.category}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          {token?.supply > 1 && (
                            <div className="col">
                              <div className="card position-relative h-100">
                                <div className="card-body p-3">
                                  <h3 className="h6 mb-2 fs-sm text-muted">
                                    Supply
                                  </h3>
                                  <div className="d-flex align-items-center">
                                    <img
                                      className="rounded-circle me-2"
                                      src={avatarDefault}
                                      width="32"
                                      alt={token?.category}
                                    />
                                    <strong>{token?.supplyMax} items</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <p
                          className="mb-4 pb-md-2 fs-sm"
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          {token?.description}
                        </p>
                        <div className="row row-cols-sm-2 row-cols-1 gy-3 mb-4 pb-md-2">
                          {token?.supply === "1" && (
                            <div className="col">
                              <h3 className="h6 mb-2 fs-sm text-muted">
                                Current {token?.type === "1" && "Price"}
                                {token?.type === "2" && "Minimum Bid"}
                                {token?.type === "3" && "Membership Suncription"}
                              </h3>
                              <h2 className="h3 mb-1">
                                {token?.type === "1" && token?.price_fixed}
                                {token?.type === "2" && token?.price_minium_bid}
                                {token?.type === "3" &&
                                  token?.price_membership_month}{" "}
                                {token?.money}
                              </h2>
                            </div>
                          )}
                          {token?.type === "2" && (
                            <div className="col">
                              <h3 className="h6 mb-2 pb-1 fs-sm text-muted">
                                Auction ends in
                              </h3>
                              <Countdown
                                model="full"
                                dateEnd={token?.bid_expiration_date}
                                dateStart={token?.bid_starting_date}
                              />
                            </div>
                          )}
                        </div>

                        {sellList.length >= 0 &&
                          (sellList.length > 0 && token?.standard === "ERC-1155" && (
                            <>
                              <div className="col">
                                <div className="card position-relative h-100">
                                  <div
                                    className="card-body p-3"
                                    style={{
                                      maxHeight: "40vh",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <h3 className="h6 mb-2 fs-sm text-muted">
                                      Listings
                                    </h3>
                                    <div className="row">
                                      <div className="col-3">Price</div>
                                      <div className="col-3">Qty</div>
                                      <div className="col-4">From</div>
                                      <div className="col-2">-</div>
                                    </div>
                                    {sellList?.map((item, index) => (
                                      <div
                                        className="row"
                                        style={{ marginTop: "0.1vh" }}
                                        key={index}
                                      >
                                        <div className="col-3">
                                          {item?.price_fixed} - {token?.money}
                                        </div>
                                        <div className="col-3">
                                          {item?.supply}
                                        </div>
                                        <div className="col-4">
                                          {item?.owner?.username}
                                        </div>
                                        <div className="col-2">

                                          {isOwner.id && item?.market_owner_id === user?.id ? <>  <button
                                            className="btn btn-sm btn-accent d-block w-100"
                                            onClick={() => deleteSigner1155(item)}
                                            disabled={loading}
                                          >
                                            x
                                          </button> </> : <>
                                            <button
                                              className="btn btn-sm btn-accent d-block w-100"
                                              onClick={() => openModal("Buy", item)}
                                              disabled={loading}
                                            >
                                              Buy
                                            </button>
                                          </>}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        {sellList.length === 1 && token?.standard === "ERC-721" && (
                          <div className="row row-cols-sm-2 row-cols-1 g-sm-4 g-3 mb-4">
                            <div className="col">
                              <button
                                className="btn btn-lg btn-accent d-block w-100"
                                onClick={() => openModal("Buy", sellList[0])}
                                disabled={isOwner.id ? true : false || loading}
                              >
                                Buy Now
                              </button>
                            </div>
                          </div>
                        )}
                        {sellList.length === 0 &&
                          token?.status !== "1" &&
                          token?.standard === "ERC-721" && (
                            <button
                              className="btn btn-lg btn-accent d-block w-100 mb-4"
                              onClick={() => setShowModalOffer(true)}
                              disabled={
                                token?.address === user?.address ||
                                !user ||
                                offerSend ||
                                loading
                              }
                            >
                              {offerSend ? "Offer sent. ✅🙂" : "Place a bid"}
                            </button>
                          )}
                        <div className="pt-3">
                          <div className="mb-4" style={{ overflowX: "auto" }}>
                            <ul
                              className="nav nav-tabs nav-fill flex-nowrap text-nowrap mb-1"
                              role="tablist"
                            >
                              <li className="nav-item">
                                <button
                                  className={`nav-link  ${activeTab === "details" ? "active" : ""
                                    } `}
                                  onClick={() => setActiveTab("details")}
                                  data-bs-toggle="tab"
                                  role="tab"
                                  disabled={loading}
                                >
                                  Details
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  className={`nav-link  ${activeTab === "properties" ? "active" : ""
                                    } `}
                                  onClick={() => setActiveTab("properties")}
                                  role="tab"
                                  disabled={loading}
                                >
                                  Properties
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  className={`nav-link  ${activeTab === "bids" ? "active" : ""
                                    } `}
                                  onClick={() => setActiveTab("bids")}
                                  role="tab"
                                  disabled={loading}
                                >
                                  Bid History
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  className={`nav-link  ${activeTab === "provenance" ? "active" : ""
                                    } `}
                                  onClick={() => setActiveTab("provenance")}
                                  data-bs-toggle="tab"
                                  role="tab"
                                  disabled={loading}
                                >
                                  Provenance
                                </button>
                              </li>
                            </ul>
                          </div>

                          <div className="tab-content">
                            <div
                              className={`tab-pane fade  ${activeTab === "details" ? "show active" : ""
                                } `}
                              id="details"
                              role="tabpanel"
                            >
                              <ul className="list-unstyled mb-0">
                                {token?.contact && (
                                  <li className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                                    <span>Contract Address</span>
                                    <div>
                                      <a
                                        className="text-decoration-none"
                                        target={"_blank"}
                                        href={`${process.env.REACT_APP_NETWORK_ADDRESS}/${contract}`}
                                        rel="noreferrer"
                                      >
                                        <span className="fw-medium text-body">
                                          {contract &&
                                            `${contract.slice(
                                              0,
                                              13
                                            )}...${contract.slice(
                                              contract.length - 3,
                                              contract.length
                                            )}`}
                                        </span>
                                        <i className="ci-external-link ms-3 text-accent"></i>
                                      </a>
                                    </div>
                                  </li>
                                )}
                                {token?.token_id && (
                                  <li className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                                    <span>Token ID</span>
                                    <div>
                                      <a
                                        className="text-decoration-none"
                                        target={"_blank"}
                                        href={`${process.env.REACT_APP_NETWORK_ADDRESS}/${contract}/${tokenId}`}
                                        rel="noreferrer"
                                      >
                                        <span className="text-body">
                                          {token?.token
                                            ? token?.token.slice(0, 10)
                                            : token?.id.slice(0, 10)}
                                        </span>
                                        <i className="ci-copy ms-3 text-accent"></i>
                                      </a>
                                    </div>
                                  </li>
                                )}
                                <li className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                                  <span>Token Standard</span>
                                  <div>
                                    <span className="text-body">
                                      {token?.standard}
                                    </span>
                                  </div>
                                </li>
                                <li className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                                  <span>Blockchain</span>
                                  <div>
                                    <span className="text-body">Polygon</span>
                                  </div>
                                </li>
                                <li className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                                  <span>Owner</span>
                                  <div>
                                    <span className="text-body">
                                      {token?.owners?.length > 1 ? (
                                        <strong
                                          role="button"
                                          onClick={() => setshowModalOwners(true)}
                                        >
                                          {token?.owners?.length + " Owners"}
                                        </strong>
                                      ) : (
                                        "@" + token?.owners[0]?.username
                                      )}
                                    </span>
                                  </div>
                                </li>
                                {isOwner?.supply && (
                                  <li className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                                    <span>You Own</span>
                                    <div>
                                      <span className="text-body">
                                        <strong>
                                          {isOwner?.supply} / {token?.supplyMax}
                                        </strong>
                                      </span>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div
                              className={`tab-pane fade  ${activeTab === "properties" ? "show active" : ""
                                } `}
                              id="properties"
                              role="tabpanel"
                            >
                              <div className="row row-cols-md-3 row-cols-2 g-3">
                                {token?.commercialUse === "1" && (
                                  <div className="col">
                                    <div className="card h-100">
                                      <div className="card-body p-3">
                                        <h6 className="mb-1 fs-sm fw-normal text-muted">
                                          Type
                                        </h6>
                                        <h5 className="mb-1 fs-sm">
                                          Commercial use
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {token?.metadata.map((item, index) => (
                                  <div className="col" key={index}>
                                    <div className="card h-100">
                                      <div className="card-body p-3">
                                        <h6 className="mb-1 fs-sm fw-normal text-muted">
                                          {item.name}
                                        </h6>
                                        <h5 className="mb-1 fs-sm">{item.value}</h5>
                                        {/* 
                                  <span className="fs-xs text-muted">
                                    0% rarity
                                  </span>
                                  */}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div
                              className={`tab-pane fade  ${activeTab === "bids" ? "show active" : ""
                                } `}
                              id="bids"
                              role="tabpanel"
                            >
                              <ul className="list-unstyled mb-0">
                                {offerList?.length > 0 &&
                                  offerList.map((item, index) => (
                                    <li
                                      className="d-flex align-items-sm-center align-items-start w-100 mb-3 pb-3 border-bottom"
                                      key={index}
                                    >
                                      <img
                                        className="rounded-circle me-2"
                                        src={
                                          item?.owner?.avatar
                                            ? item?.owner?.avatar
                                            : avatarDefault
                                        }
                                        width="32"
                                        alt={item?.owner?.name}
                                      />
                                      <div className="d-sm-flex align-items-sm-center w-100">
                                        <div className="mb-sm-0 mb-2">
                                          <h6 className="mb-1 fs-sm">
                                            <Link
                                              className="text-decoration-none text-accent"
                                              to={`/author/${item?.owner?.id}`}
                                            >
                                              @{item?.owner?.username}
                                            </Link>{" "}
                                            placed a bid{" "}
                                            {item?.status === "2" && (
                                              <span className="fs-sm fw-normal  text-danger">
                                                Reject
                                              </span>
                                            )}
                                            {item?.status === "1" && (
                                              <span className="fs-sm fw-normal  text-success">
                                                Approved
                                              </span>
                                            )}
                                          </h6>

                                          <span className="fs-sm fw-normal text-muted">
                                            {moment(item?.created).calendar()}
                                          </span>
                                          <p className="fs-sm fw-normal text-muted">
                                            Expired:{" "}
                                            {moment(item?.expiration).calendar()}
                                          </p>

                                          {item?.status === "0" &&
                                            token?.address === user?.address && (
                                              <div>
                                                <button
                                                  className="btn btn-sm btn-accent me-2"
                                                  disabled={loading}
                                                  onClick={() =>
                                                    acceptOffer(item?.id)
                                                  }
                                                >
                                                  Accept
                                                </button>
                                                <button
                                                  className="btn btn-sm btn-primary"
                                                  disabled={loading}
                                                  onClick={() =>
                                                    rejectOffer(item?.id)
                                                  }
                                                >
                                                  Decline
                                                </button>
                                              </div>
                                            )}
                                          {item?.status === "0" &&
                                            item?.owner?.id === user?.id && (
                                              <div>
                                                <button
                                                  className="btn btn-sm btn-primary"
                                                  disabled={loading}
                                                  onClick={() =>
                                                    rejectOfferAuthor(item?.id)
                                                  }
                                                >
                                                  Cancel offer.
                                                </button>
                                              </div>
                                            )}
                                        </div>
                                        <div className="ms-sm-auto text-nowrap">
                                          <h6 className="mb-0 fs-lg fw-medium text-darker">
                                            {item?.price} {item?.money}
                                          </h6>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div
                              className={`tab-pane fade  ${activeTab === "provenance" ? "show active" : ""
                                } `}
                              id="provenance"
                              role="tabpanel"
                            >
                              <ul className="list-unstyled mb-4">
                                {token?.activity.map((item, index) => (
                                  <li
                                    className="position-relative mb-0 ps-4 pb-4 border-start"
                                    key={index}
                                  >
                                    <span
                                      className="d-inline-block position-absolute start-0 top-0 mt-1 p-1 bg-primary rounded-circle"
                                      style={{ transform: "translateX(-50%)" }}
                                    ></span>
                                    <h6 className="mb-1 fs-sm">
                                      {item.event} by{" "}
                                      <span className="text-decoration-none text-accent">
                                        {item.to}
                                      </span>
                                    </h6>
                                    <span className="fs-sm text-muted">
                                      {moment(item?.created).calendar()}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                              <button
                                className="btn btn-outline-accent d-block w-100"
                                disabled={loading}
                              >
                                <i className="ci-loading me-2"></i>Load more
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </section>
      </main>
    </>
  );
}
