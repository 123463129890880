import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useWallet } from "../../hooks/useWallet";
import "react-toastify/dist/ReactToastify.css";
import { useForm, useFieldArray } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

export default function CollectionEdit() {
  let { tokenId } = useParams();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [typeFiles, setTypeFiles] = useState(undefined);
  const [multimedia, setMultimedia] = useState("");
  const [tab, setSelectTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const { account, user, signin } = useWallet();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "metadata",
  });
  const watchAllFields = watch();

  useEffect(() => {
    if (user) {
      console.log(user);
      console.log("NFT " + user.approvedNFT);
      console.log("RLC " + user.approvedRLC);
      if (user.approvedNFT !== "1") {
        //window.location.replace("/account/approved");
      }
    }
    getCollection();
  }, [user]);

  const getCollection = () => {
    setLoading(true);
    let userId = JSON.parse(localStorage.getItem("user"));
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/collection-public/${tokenId}`
    );
    url.searchParams.append("userId", userId?.id ? userId?.id : undefined);
    fetch(`${decodeURIComponent(url)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        reset(response.collection);
        setMultimedia(response?.collection?.banner);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const selectFile = async (event) => {
    setTypeFiles(event.target.files[0].type);
    setLoading(true);
    let uploadFile = new FormData();
    uploadFile.append("folder", "collection");
    uploadFile.append("address", account);
    uploadFile.append("file", event.target.files[0]);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/s3-upload`, {
      method: "POST",
      body: uploadFile,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        console.log(response.data);
        setMultimedia(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = async (data) => {
    const id = toast.loading("Please wait...");
    var formData = new FormData();
    if (typeFiles) {
      formData.append("banner", multimedia);
    }
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("url", data.url);
    formData.append("website", data.website);
    formData.append("discord", data.discord);
    formData.append("twitter", data.twitter);
    formData.append("telegram", data.telegram);
    formData.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${process.env.REACT_APP_API_BACKEND}/collection/${tokenId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(Object.fromEntries(formData)),
    })
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        toast.update(id, {
          render: "Collection updated. 😇",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        window.location.replace(`/account/collection`);
      })
      .catch((error) => {
        setLoading(false);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };
  return (
    <>
      <main className="page-wrapper">
        <div className="bg-accent mb-4 py-4">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/explorer">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-nowrap">
                    <Link to={"/explorer"}>NFT Marketplace</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    Edit a Collection
                  </li>
                </ol>
              </nav>
            </div>
            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
              <h1 className="h3 text-light mb-0">Edit a Collection</h1>
            </div>
          </div>
        </div>

        <section className="container mb-5 pt-3 pb-md-5 pb-1">
          <div className="row gy-5">
            <form
              className="needs-validation col-md-8"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <h2 className="h5 mb-2 pb-1">Banner image</h2>
                <p className="mb-4 fs-sm">
                  This image will appear at the top of your collection page.
                  Avoid including too much text in this banner image, as the
                  dimensions change on different devices. 1400 x 350
                  recommended.
                </p>
                <p className="mb-4 fs-sm">
                  File types supported: PNG, JPG, GIF, size: 100mb.
                </p>

                <div className="file-drop-area">
                  <>
                    <img
                      src={multimedia}
                      alt="Preview"
                      className="img-thumbnail"
                      width={200}
                      height={200}
                      style={{ display: "block", margin: "auto" }}
                    />
                  </>
                  <input
                    className="file-drop-input"
                    type="file"
                    onChange={selectFile}
                    id="file-upload"
                    accept="image/png, image/jpeg, image/gif"
                    disabled={loading}
                  />

                  <label
                    htmlFor="file-upload"
                    className="file-drop-btn btn btn-outline-accent"
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    ) : (
                      <i className="ci-loading me-2"></i>
                    )}
                    Or select file
                  </label>
                </div>
              </div>

              <div className="mb-md-5 mb-4 pb-md-0 pb-2">
                <h2 className="h5 mb-3">Collection Details</h2>
                <div className="row gy-md-4 gy-3">
                  <div className="col-12">
                    <label className="form-label" htmlFor="details-title">
                      Title<span className="ms-1 text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="details-title"
                      type="Text"
                      placeholder="Example: RedLine Collection in Space"
                      {...register("name", { required: true })}
                      required
                      maxLength={500}
                    />
                    <div className="invalid-feedback">
                      Please, enter the title.
                    </div>
                    <span className="form-text">
                      Maximum 500 characters. No HTML or emoji allowed.
                    </span>
                  </div>
                  <div className="col-12">
                    <label className="form-label" htmlFor="details-description">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="details-description"
                      rows="4"
                      placeholder="Enter a short description of your item"
                      {...register("description")}
                    ></textarea>
                    <span className="form-text">0 of 500 characters used.</span>
                  </div>
                  {/* 
                  <div className="col-12">
                    <label className="form-label" htmlFor="details-description">
                      Url
                    </label>
                    <br />
                    <span className="form-text">
                      Customize your URL on RedLine. Must only contain lowercase
                      letters, numbers, and hyphens. Example:
                      https://nft.redlineblockchain.com/collection/{" "}
                      <strong>URL</strong>
                    </span>
                    <input
                      className="form-control"
                      id="details-title"
                      type="Text"
                      placeholder="redlineinspace"
                      {...register("url")}
                    />
                  </div>
                    */}
                </div>
              </div>

              <div>
                <h2 className="h5 mb-4 pb-md-2">Links</h2>
                <div className="row gy-md-4 gy-3 mb-md-5 mb-4 pb-md-0 pb-2">
                  <div className="col-sm-6">
                    <label className="form-label" htmlFor="website-description">
                      <i className="ci-globe me-2"></i>
                      Website
                    </label>
                    <input
                      className="form-control"
                      id="website-title"
                      type="Text"
                      {...register("website")}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label" htmlFor="discord-description">
                      <i className="ci-discord me-2"></i>
                      Discord
                    </label>
                    <input
                      className="form-control"
                      id="discord-title"
                      type="Text"
                      {...register("discord")}
                    />
                  </div>
                </div>
                <div className="row gy-md-4 gy-3 mb-md-5 mb-4 pb-md-0 pb-2">
                  <div className="col-sm-6">
                    <label className="form-label" htmlFor="twitter-description">
                      <i className="ci-twitter me-2"></i>
                      Twitter
                    </label>
                    <input
                      className="form-control"
                      id="twitter-title"
                      type="Text"
                      {...register("twitter")}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label
                      className="form-label"
                      htmlFor="telegram-description"
                    >
                      <i className="ci-telegram me-2"></i>
                      Telegram
                    </label>
                    <input
                      className="form-control"
                      id="telegram-title"
                      type="Text"
                      {...register("telegram")}
                    />
                  </div>
                </div>
                <div className="pt-2">
                  <button
                    className="btn btn-lg btn-accent d-block w-100"
                    type="submit"
                    disabled={loading}
                  >
                    Update Collection
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  );
}
