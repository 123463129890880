import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWallet } from "../../hooks/useWallet";
import { toast } from "react-toastify";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import ImgBlock from "../../assets/img/block.png";
import PreviewMultimedia from "../../components/PreviewMultimedia";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import useAutoComplete from "../../hooks/useAutocompleted";
import ModalAudience from "../../components/ModalAudience";
import ModalDelete from "../../components/ModalDelete";
import ModalRich from "../../components/ModalRich"
import SidebarMenuAccount from "../../components/SidebarMenuAccount";
import RichTextEditor from 'react-rte';
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
} from "emoji-picker-react";

export default function Feed() {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [typeFiles, setTypeFiles] = useState(undefined);
  const [radioPointer, setRadioPointer] = useState(["Public"]);
  const [tabSelect, setTabSelect] = useState("You");
  const [mySubscriptions, setMySubscriptions] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [myFollow, setMyFollow] = useState([]);
  const [feedList, setFeedList] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [showModalDelete, setshowModalDelete] = useState(false);
  const [showModalRich, setshowModalRich] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({
    type: "post",
    id: null,
  });
  const [enableFeed, setEnableFeed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonEmoji, setButtonEmoji] = useState(null);
  const [textareaMessage, setTextareaMessage] = useState("");
  const [tipsTooltip, setTipsTooltip] = useState(null);
  const [richeditor, setRicheditor] = useState(RichTextEditor.createEmptyValue());
  const { account, user, signin } = useWallet();
  const [query, setQuery] = useState({
    page: 0,
    limit: 30,
    hashtags: "",
  });
  const {
    bindInput,
    bindOptions,
    bindOption,
    isBusy,
    suggestions,
    selectedIndex,
  } = useAutoComplete({
    onChange: (value) => console.log(value),
    source: async (search) => {
      try {
        let url = new URL(`${process.env.REACT_APP_API_BACKEND}/search-feed`);
        url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
        url.searchParams.append("search", search);
        const res = await fetch(`${url}`, {
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        });
        const data = await res.json();
        console.log(data?.data?.items);
        const prov = data?.data?.items;
        return prov.map((d) => ({ value: d.id, label: d.username }));
      } catch (e) {
        return [];
      }
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    getAllSubscriptions();
    getMySubscriptionsFollow();
    getAllHashtags();
    const inToolTipTips = setTimeout(() => {
      setTipsTooltip(true);
    }, 3000);
    return () => clearTimeout(inToolTipTips);
  }, []);

  useEffect(() => {
    if (tabSelect === "Explorer") {
      getAllExplorer();
    } else if (tabSelect === "My") {
      getAllMyFeed();
    } else {
      getAllSubscriptions();
    }
  }, [tabSelect]);

  useEffect(() => {
    if (tabSelect === "Explorer") {
      getAllExplorer();
    } else if (tabSelect === "My") {
      getAllMyFeed();
    } else {
      getAllSubscriptions();
    }
  }, [query]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const selectFile = async (event) => {
    setSelectedFiles(event.target.files[0]);
    setTypeFiles(event.target.files[0].type);
    setEnableFeed(true);
  };


  const onSubmitRich = () => {
    setshowModalRich(false);
    onSubmit();
  }



  const onSubmitMultimedia = async (data) => {
    if (!selectedFiles) {
      toast.error("Please select a file");
      return;
    }
    setLoading(true);
    const id = toast.loading("Please wait...");
    let uploadFile = new FormData();
    uploadFile.append("folder", "feed");
    uploadFile.append("address", account);
    uploadFile.append("file", selectedFiles);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/s3-upload`, {
      method: "POST",
      body: uploadFile,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Image uploaded to S3", response);
        return response;
      })
      .then((response) => {
        var formData = new FormData();
        formData.append("description", textareaMessage);
        formData.append("multimedia", response.data);
        formData.append("type_multimedia", typeFiles);
        formData.append("type", JSON.stringify(radioPointer));
        formData.append("network", process.env.REACT_APP_NETWORK_TYPE);
        fetch(`${process.env.REACT_APP_API_BACKEND}/subscriptions`, {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            setLoading(false);
            resetPost();
            toast.update(id, {
              render: "Post published! 🤠",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            /*
            window.location.replace(`/author/${user.id}`);
            */
          });
      })
      .catch((error) => {
        setLoading(false);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };
  const onSubmit = async () => {
    if (selectedFiles) {
      onSubmitMultimedia();
      return;
    }
    setLoading(true);
    const id = toast.loading("Please wait...");
    var formData = new FormData();
    formData.append("description", textareaMessage);
    formData.append("type", JSON.stringify(radioPointer));
    formData.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${process.env.REACT_APP_API_BACKEND}/subscriptions`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        toast.update(id, {
          render: "Post published! 🤠",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        resetPost();
        // window.location.replace(`/author/${user.id}`);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.update(id, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const getAllHashtags = async () => {
    setLoading(true);
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/hashtags`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    fetch(`${url}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setHashtags(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAllSubscriptions = async () => {
    setLoading(true);
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/subscriptions`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("limit", query.limit);
    url.searchParams.append("page", query.page);
    url.searchParams.append("hashtags", query.hashtags);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setFeedList(response.data.items);
        //setListToken(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAllExplorer = async () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/subscriptions-explorer`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("limit", query.limit);
    url.searchParams.append("page", query.page);
    url.searchParams.append("hashtags", query.hashtags);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setFeedList(response.data.items);
        //setListToken(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAllMyFeed = async () => {
    setLoading(true);
    let url = new URL(`${process.env.REACT_APP_API_BACKEND}/subscriptions-my`);
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("limit", query.limit);
    url.searchParams.append("page", query.page);
    url.searchParams.append("hashtags", query.hashtags);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setFeedList(response.data.items);
        //setListToken(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMySubscriptionsFollow = async () => {
    setLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/my-subscriptions-and-follow`
    );
    url.searchParams.append("network", process.env.REACT_APP_NETWORK_TYPE);
    url.searchParams.append("limit", query.limit);
    url.searchParams.append("page", query.page);
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setMySubscriptions(response.subscriptions);
        setMyFollow(response.follows);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDeletedContent = async () => {
    setLoading(true);
    if (deleteInfo.id === null) {
      return;
    }
    let url = new URL(
      `${process.env.REACT_APP_API_BACKEND}/subscriptions/${deleteInfo.id}`
    );
    fetch(`${url}`, {
      method: "delete",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setLoading(false);
        getAllSubscriptions();
        setshowModalDelete(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleRich = (newValue) => {
    setRicheditor(newValue);
    setTextareaMessage(newValue.toString('html'))
  };

  const handleChange = (newState) => {
    setshowModal(false);
    setRadioPointer([...newState]);
  };

  const onEmojiClick = (emojiData, event) => {
    setTextareaMessage(textareaMessage + " " + emojiData.emoji);
  };

  const closeModal = () => {
    setshowModal(false);
  };

  const showDelete = (id) => {
    setDeleteInfo({
      type: "Post",
      id: id,
    });
    setshowModalDelete(true);
  };

  const closeDelete = (id) => {
    setshowModalDelete(false);
    setshowModalRich(false);
  };

  const resetPost = () => {
    setRicheditor(RichTextEditor.createEmptyValue())
    setTextareaMessage("");
    setRadioPointer(["Public"]);
    setSelectedFiles(undefined);
    getAllSubscriptions();
  };

  const queryHashtags = (hashtags) => {
    setQuery({
      page: 0,
      limit: 30,
      hashtags: hashtags,
    });
  };

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  return (
    <>
      {showModal && (
        <ModalAudience
          show={showModal}
          handleClose={closeModal}
          onChangeAudience={handleChange}
          userId={user.id}
        />
      )}
      {showModalDelete && (
        <ModalDelete
          show={showModalDelete}
          handleClose={closeDelete}
          handleDelete={handleDeletedContent}
          data={deleteInfo}
        />
      )}
      {showModalRich && (
        <ModalRich
          show={showModalRich}
          handleClose={closeDelete}
          onSubmitRich={onSubmitRich}
          setTextareaMessage={setTextareaMessage}
        />
      )}
      <main className="page-wrapper">
        <div className="bg-accent mb-4 ">
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/feed">
                      <i className="ci-home"></i>Home
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    Feed
                  </li>
                </ol>
              </nav>
            </div>
            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
              <h1 className="h3 text-light mb-0">Feed</h1>
            </div>
          </div>
        </div>
        {width <= "980" && <SidebarMenuAccount />}
        {width <= "980" && (
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div
              role="button"
              className={`nav-link-style d-flex align-items-center px-4 py-3 ${tabSelect === "My" ? "active" : ""
                } `}
              onClick={() => setTabSelect("My")}
            >
              <strong>My Feed</strong>
            </div>
            <div
              role="button"
              className={`nav-link-style d-flex align-items-center px-4 py-3 ${tabSelect === "You" ? "active" : ""
                } `}
              onClick={() => setTabSelect("You")}
            >
              <strong> For You</strong>
            </div>
            <div
              role="button"
              className={`nav-link-style d-flex align-items-center px-4 py-3 ${tabSelect === "Explorer" ? "active" : ""
                } `}
              onClick={() => setTabSelect("Explorer")}
            >
              <strong>Feed Explorer</strong>
            </div>
          </div>
        )}
        <section className="container mb-5 pt-3 pb-md-5 pb-1">
          <div className="row ">
            <aside className="col-lg-3 col-md-4 position-relative">
              <div className="sticky-top" style={{ top: "100px" }}>
                <div className="d-lg-block collapse" id="account-menu">
                  <ul className="list-unstyled mb-0">
                    <li className="border-bottom mb-0">
                      <div
                        role="button"
                        className={`nav-link-style d-flex align-items-center px-4 py-3 ${tabSelect === "My" ? "active" : ""
                          } `}
                        onClick={() => setTabSelect("My")}
                      >
                        <i className="ci-user opacity-60 me-2"></i>My Feed
                      </div>
                    </li>
                    <li className="border-bottom mb-0">
                      <div
                        role="button"
                        className={`nav-link-style d-flex align-items-center px-4 py-3 ${tabSelect === "You" ? "active" : ""
                          } `}
                        onClick={() => setTabSelect("You")}
                      >
                        <i className="ci-settings opacity-60 me-2"></i>For You
                      </div>
                    </li>
                    <li className="border-bottom mb-0">
                      <div
                        role="button"
                        className={`nav-link-style d-flex align-items-center px-4 py-3 ${tabSelect === "Explorer" ? "active" : ""
                          } `}
                        onClick={() => setTabSelect("Explorer")}
                      >
                        <i className="ci-image opacity-60 me-2"></i> Feed
                        Explorer
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="d-lg-block collapse border-bottom py-4">
                  <span className="px-4 " style={{ fontStyle: "oblique" }}>
                    Subscriptions
                  </span>
                  <ul className="list-unstyled mb-0 px-4">
                    {mySubscriptions.length > 0 &&
                      mySubscriptions?.map((item, index) => (
                        <li className="border-bottom mb-0" key={index}>
                          <div className="d-flex align-items-center position-relative mb-1 py-3 border-top">
                            <img
                              className="me-2 rounded-circle"
                              src={
                                item?.owner?.avatar
                                  ? item?.owner?.avatar
                                  : avatarDefault
                              }
                              width="32"
                              alt={item?.owner?.name}
                            />
                            <Link
                              className="nav-link-style fs-sm stretched-link no-traslate"
                              to={`/author/${item?.owner?.id}`}
                            >
                              @{item?.owner?.username}
                            </Link>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </aside>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="card py-1 border-0 shadow">
                <div className="card-body py-md-4 py-3 px-4 text-center" style={{ fontFamily: 'inherit' }}>
                  <RichTextEditor
                    value={richeditor}
                    onChange={handleRich}
                    toolbarConfig={toolbarConfig}

                  />
                  {selectedFiles && (
                    <PreviewMultimedia
                      file={selectedFiles}
                      type={typeFiles}
                      classHerence="MaxHeightWidthPreview"
                    />
                  )}
                  <div style={{ textAlign: "left" }}>
                    <small className="label label-default">
                      Available for: {radioPointer?.map((item) => `${item} - `)}
                    </small>
                  </div>
                  <div>
                    <div className="pt-2 d-flex flex-sm-row flex-column justify-content-between">
                      <div>
                        {/*
                        <button
                          className="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          data-bs-original-title="Add to Favorites"
                          aria-label="Add to Favorites"
                          onClick={() => setButtonEmoji(!buttonEmoji)}
                        >
                          <i className="ci-smile"></i>
                        </button>
                  */}
                        {buttonEmoji && (
                          <div
                            style={{ position: "absolute" }}
                            onMouseLeave={() => setButtonEmoji(!buttonEmoji)}
                          >
                            <EmojiPicker
                              onEmojiClick={onEmojiClick}
                              autoFocusSearch={false}
                            />
                          </div>
                        )}
                        <input
                          type="file"
                          id="file-upload"
                          onChange={selectFile}
                          style={{ display: "none" }}
                          disabled={loading}
                          accept="image/png, image/jpeg, video/*"
                        />
                        <label
                          htmlFor="file-upload"
                          className="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          data-bs-original-title="Add to Favorites"
                          aria-label="Add to Favorites"
                        >
                          <i className="ci-image"></i>
                        </label>

                        <input
                          type="file"
                          id="file-audio"
                          onChange={selectFile}
                          style={{ display: "none" }}
                          disabled={loading}
                          accept="audio/*"
                        />
                        <label
                          htmlFor="file-audio"
                          className="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          data-bs-original-title="Add to Favorites"
                          aria-label="Add to Favorites"
                        >
                          <i className="ci-earphones"></i>
                        </label>
                        {/*  
                        <label
                          className="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2"
                          type="button"
                          onClick={() => setshowModalRich(true)}
                        >
                          <i className="ci-edit"></i>
                        </label>
                        */}
                        <button
                          className="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2 tooltipNFT bottom"
                          type="button"
                          onMouseLeave={() => setTipsTooltip(false)}
                          onClick={() => setshowModal(true)}
                        >
                          <div
                            className={`tiptext ${tipsTooltip ? "headShake_me visible" : ""
                              } `}
                          >
                            Add exclusive content
                          </div>
                          <i className="ci-locked"></i>
                        </button>
                      </div>
                      <div style={{ marginTop: "1vh" }}>
                        <button
                          className="btn btn-accent d-block w-100"
                          type="submit"
                          onClick={() => onSubmit()}
                          disabled={!enableFeed && textareaMessage.length <= 1}
                        >
                          Create post
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                {feedList.length > 0 &&
                  feedList?.map((item, index) => (
                    <div className="col-md-12" key={index}>
                      <article>
                        <div className="card mb-3">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div className="d-inline-flex align-items-center position-relative">
                                <img
                                  className="rounded-circle me-2"
                                  width="32"
                                  src={
                                    item?.owner?.avatar
                                      ? item?.owner?.avatar
                                      : avatarDefault
                                  }
                                  alt={item?.owner?.name}
                                />
                                <div className="fs-sm">
                                  <Link
                                    className="nav-link-style fs-sm stretched-link"
                                    to={`/author/${item?.owner?.id}`}
                                  >
                                    @{item?.owner?.username}
                                  </Link>
                                  <br />
                                  {moment(item?.created).calendar()}
                                </div>
                              </div>
                              <div className="d-none d-md-block">
                                <p>
                                  Content: {JSON.parse(item?.type).join(", ")}
                                </p>
                              </div>
                              <div className="d-flex my-2 justify-content-end">
                                {item?.owner?.id === user?.id && (
                                  <>
                                    <Link to={`/feed/edit/${item.id}`}>
                                      <button
                                        className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="left"
                                        title="Edit"
                                      >
                                        <i className="ci-edit"></i>
                                      </button>
                                    </Link>
                                    <button
                                      className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                      type="button"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="left"
                                      title="Deleted"
                                      onClick={() => showDelete(item.id)}
                                    >
                                      <i className="ci-trash"></i>
                                    </button>
                                  </>
                                )}
                                {item?.owner?.id !== user?.id && (
                                  <>
                                    {item?.multimedia === "Private" && (
                                      <>
                                        <Link
                                          to={`/author/${item?.owner?.id}?plans=true`}
                                        >
                                          <button
                                            className="btn-wishlist btn-sm flex-shrink-0 ms-3"
                                            type="button"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="left"
                                            title="Subscribe"
                                          >
                                            <i className="ci-locked"></i>
                                          </button>
                                        </Link>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div style={{ whiteSpace: "break-spaces" }}>
                              <p
                                style={{ marginLeft: "2rem" }}
                                className="no-traslate"
                              >
                                <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                              </p>

                            </div>
                            <div className="row ">
                              <div className="col">
                                {item.multimedia !== "Private" ? (
                                  <>
                                    {item?.multimedia && (
                                      <PreviewMultimedia
                                        classHerence="rounded-1 center-block MaxHeightWidthFeed"
                                        file={item?.multimedia}
                                        type={item?.type_multimedia}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <img
                                      className="rounded-1"
                                      alt="Private Content"
                                      src={ImgBlock}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))}
              </div>
            </div>
            <aside className="col-lg-3 col-md-4 position-relative">
              <div className="sticky-top" style={{ top: "100px" }}>
                <div className="d-lg-block collapse" id="account-menu">
                  <div className="input-group">
                    <i className="ci-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                    <input
                      className="form-control rounded-start"
                      type="text"
                      placeholder="Find a Redliner"
                      {...bindInput}
                    />
                  </div>
                  {suggestions.length > 0 && (
                    <ul
                      {...bindOptions}
                      className="card card-body  position-absolute overflow-auto w-100"
                      style={{ listStyleType: "none" }}
                    >
                      {suggestions.map((_, index) => (
                        <li
                          className={
                            `flex items-center h-[40px] p-1 hover:bg-slate-300 ` +
                            (selectedIndex === index && "bg-slate-300")
                          }
                          key={index}
                          {...bindOption}
                        >
                          <div className="flex items-center space-x-1">
                            <div>{suggestions[index].label}</div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {/*
                <div className="d-lg-block collapse border-bottom py-4">
                  <span className="px-4" style={{ fontStyle: "oblique" }}>
                    Trends
                  </span>
                  <div className="px-4">
                    {hashtags.length > 0 && (
                      <>
                        {hashtags.map((item, index) => (
                          <span
                            key={index}
                            role="button"
                            className="fs-sm text-muted mx-1"
                            style={{ display: "inline-block" }}
                            onClick={() => queryHashtags(item.hashtag)}
                          >
                            #{item.hashtag}
                          </span>
                        ))}
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </aside>
          </div>
        </section>
      </main>
    </>
  );
}
