import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BgImg from "../../assets/img/nft/home/hero-bg.png";
import avatarDefault from "../../assets/img/nft/vendor/avatar-sm.png";
import avatarDefaultTwo from "../../assets/img/nft/vendor/avatar.png";
import avatarDefaultSquare from "../../assets/img/nft/vendor/avatar-square.jpg";
import { useWallet } from "../../hooks/useWallet";
import AppContext from "../../context/AppContex";
import { ToastContainer, toast } from "react-toastify";
import { useLocalStorage } from "../../hooks/useLocalState";
import SidebarMenuAccount from "../../components/SidebarMenuAccount";
import HeaderMenuAccount from "../../components/HeaderMenuAccount";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";

export default function Setting() {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [activeSlide] = useState(true);
  const [loading, setLoading] = useState(false);
  const { account, user, signin } = useWallet();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const watchAllFields = watch();

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user]);

  const selectFile = async (event) => {
    setSelectedFiles(event.target.files[0]);
    await onFileUpload(event.target.files[0]);
  };

  const onFileUpload = async (file) => {
    var data = new FormData();
    setLoading(true);
    data.append("folder", "avatar");
    data.append("address", account);
    data.append("file", file);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/s3-upload`, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        let update = { ...user, avatar: response.data };
        signin(update);
        return response;
      })
      .then((response) => {
        let dataUpdate = new FormData();
        dataUpdate.append("avatar", response.data);
        fetch(`${process.env.REACT_APP_API_BACKEND}/avatar-update/${user.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ avatar: response.data }),
        })
          .then((response) => response.json())
          .then(() => {
            setLoading(false);
            toast.success("Avatar updated! 😀");
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = (data) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_BACKEND}/users/${user.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        let update = { ...user, user: data };
        console.log(update);
        signin(data);
        setLoading(false);
        toast.success("Information updated! 😀");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <main className="page-wrapper">
        <HeaderMenuAccount />
        <div className="container mb-5 pb-3">
          <div className="bg-light shadow-lg rounded-3 overflow-hidden">
            <div className="row">
              <SidebarMenuAccount />
              <section className="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                  <h1 className="h3 mb-4 pt-2 text-center text-sm-start">
                    Profile Settings
                  </h1>
                  <div className="bg-secondary rounded-3 p-4 mb-4 text-center text-sm-start">
                    <div className="d-flex flex-sm-row flex-column align-items-sm-start align-items-center">
                      <img
                        className="rounded mb-sm-0 mb-3"
                        src={user?.avatar ? user.avatar : avatarDefaultTwo}
                        width={"90px"}
                        height={"90px"}
                        alt={
                          user?.username
                            ? user.username
                            : "RedLine Blockchain User"
                        }
                      />
                      <div className="ms-n2 ps-sm-4">
                        <label
                          htmlFor="file-upload"
                          className="btn btn-outline-accent mb-2 ms-2"
                        >
                          {loading ? (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                          ) : (
                            <i className="ci-loading me-2"></i>
                          )}
                          Change{" "}
                          <span className="d-none d-sm-inline">avatar</span>
                        </label>
                        <input
                          type="file"
                          id="file-upload"
                          onChange={selectFile}
                          style={{ display: "none" }}
                          disabled={loading}
                          accept="image/png, image/jpeg, image/gif"
                        />
                        <button
                          className="btn btn-outline-danger mb-2 ms-2"
                          type="button"
                          disabled={loading}
                        >
                          <i className="ci-trash me-2"></i>Delete
                        </button>
                        <div className="pt-1 mb-0 fs-sm text-muted">
                          We recommend an image of at least 400x400. Gifs work
                          too :)
                        </div>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row gy-3 mb-4 pb-md-3 mb-2">
                      <div className="col-sm-6">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-name"
                        >
                          Full name
                        </label>
                        <input
                          className="form-control"
                          id="profile-name"
                          type="text"
                          {...register("name", { required: true })}
                        />
                        <input
                          className="form-check-input mx-2"
                          id="price-fix-wallet"
                          type="checkbox"
                          {...register("name_hidden")}
                        />
                        <small>Hide name from public</small>
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-username"
                        >
                          Username
                        </label>
                        <input
                          className="form-control"
                          id="profile-username"
                          type="text"
                          placeholder="@enter_name"
                          maxLength={20}
                          {...register("username", { required: true })}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-uid"
                        >
                          Account Address
                        </label>
                        <input
                          className="form-control"
                          id="profile-uid"
                          type="text"
                          disabled
                          {...register("address", { required: true })}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-email"
                        >
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="profile-email"
                          type="email"
                          {...register("email", { required: true })}
                        />
                      </div>
                      <div className="col-12">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-bio"
                        >
                          Short bio
                        </label>
                        <textarea
                          className="form-control"
                          id="profile-bio"
                          rows="4"
                          placeholder="Tell about yourself in few words"
                          {...register("short")}
                          maxLength={2500}
                        ></textarea>
                        <span className="form-text ">
                          {watchAllFields?.short?.length} of 2500 characters
                          used.
                        </span>
                      </div>
                    </div>
                    <h3 className="h5 mb-3 no-traslate">
                      Social media profiles
                    </h3>
                    <div className="row gy-3 mb-4 pb-2">
                      <div className="col-sm-6">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-website"
                        >
                          Website
                        </label>
                        <input
                          className="form-control"
                          id="profile-website"
                          type="url"
                          placeholder="Enter URL"
                          {...register("website")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-twitter"
                        >
                          Twitter
                        </label>
                        <input
                          className="form-control"
                          id="profile-twitter"
                          type="text"
                          placeholder="Enter URL"
                          {...register("twitter")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-facebook"
                        >
                          Facebook
                        </label>
                        <input
                          className="form-control"
                          id="profile-facebook"
                          type="text"
                          placeholder="Enter URL"
                          {...register("facebook")}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label
                          className="form-label no-traslate"
                          htmlFor="profile-instagram"
                        >
                          Instagram
                        </label>
                        <input
                          className="form-control"
                          id="profile-instagram"
                          type="text"
                          placeholder="Enter URL"
                          {...register("instagram")}
                        />
                      </div>
                      {/* }
                      <div className="col-12">
                        <a href="#">
                          <i className="ci-add me-2"></i>
                          <ins>Add more</ins>
                        </a>
                      </div>
                  */}
                    </div>
                    <div className="d-flex flex-sm-row flex-column">
                      <button
                        className="btn btn-accent"
                        type="submit"
                        disabled={loading}
                      >
                        Update profile
                      </button>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
