import { Button, Show, Hide } from "@chakra-ui/react";
import { NavLink, Link } from "react-router-dom";
import avatarDefault from "../assets/img/nft/vendor/avatar-sm.png";
import { useWallet } from "../hooks/useWallet";
import { useEffect, useState, useContext } from "react";
import { useLocalStorage } from "../hooks/useLocalState";
import AppContext from "../context/AppContex";
import ModalCreate from "./ModalCreate";
import Notifications from "./Notifications";
type Props = {
  handleOpenModal: any;
};
export default function ConnectButton() {
  const { account, user, addtoken, signin, signout } = useWallet();
  const [balance, setBalance] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [accountInfo, setAccountInfo] = useState("");

  useEffect(() => {
    getBalanceWallet();
    console.log("Cambio en Wallet");
  }, [account]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getBalanceWallet = async () => {
    console.log(account);
    if (account) {
      console.log("Esta conectado");
      const accountInfoTmp = window.localStorage.getItem("user");
      if (!accountInfoTmp) {
        loginFetch();
      }
      //const etherBalance = await getBalanceRLC(account);
      //console.log(etherBalance);
      //setBalance(etherBalance);
    }
  };

  const disconnectWallet = () => {
    console.log("Disconnect Wallet");
    localStorage.removeItem("accountAddress");
    signout();
    window.location.reload();
  };

  const loginFetch = async () => {
    var data = new FormData();
    data.append("address", account);
    //data.append("address", "0xb0ef558375df6267f13f37ef018382d2e880d4e8");
    console.log(process.env.REACT_APP_API_BACKEND);
    await fetch(`${process.env.REACT_APP_API_BACKEND}/login`, {
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then(function (data) {
        console.log(data);
        console.log("inicio sesión");
        signin(data.user);
        addtoken(data.token);
        window.location.replace("/feed");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = () => {
    setshowModal(true);
  };
  const closeModal = () => {
    setshowModal(false);
  };
  return account ? (
    <>
      <div className="navbar-toolbar dropdown d-flex align-items-center order-lg-3">
        <div className="navbar-tool dropdown ms-lg-3 ms-2 ">
          <Notifications />
        </div>

        <div className="navbar-tool dropdown ms-lg-3 ms-2">
          <Link
            className="navbar-tool-icon-box d-inline-block w-auto h-auto dropdown-toggle mt-n1"
            to={`/author/${user?.id}`}
          >
            <img
              src={user?.avatar ? user.avatar : avatarDefault}
              width="36"
              className="rounded-circle"
              alt={
                user?.username != undefined
                  ? user.username
                  : `${account.slice(0, 10)}...${account.slice(
                      account.length - 4,
                      account.length
                    )}`
              }
            />
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            <div style={{ minWidth: "14rem" }}>
              <Link to={`/author/${user?.id}`}>
                <h6 className="dropdown-header">
                  {user?.username != undefined
                    ? user.username
                    : `${account.slice(0, 10)}...${account.slice(
                        account.length - 4,
                        account.length
                      )}`}
                </h6>
              </Link>
              <Link
                className="dropdown-item d-flex align-items-center"
                to="/account/setting"
              >
                <i className="ci-settings opacity-60 me-2"></i>Profile Settings
              </Link>
              <Link
                className="dropdown-item d-flex align-items-center"
                to={"/feed?page=my"}
              >
                <i className="ci-document opacity-60 me-2"></i> My Feed
              </Link>
              <Link
                className="dropdown-item d-flex align-items-center"
                to={"/account/items"}
              >
                <i className="ci-image opacity-60 me-2"></i> My NFTs
              </Link>
              <Link
                className="dropdown-item d-flex align-items-center"
                to={"/account/collection"}
              >
                <i className="ci-view-list opacity-60 me-2"></i>My Collections
              </Link>
              <Link
                className="dropdown-item d-flex align-items-center"
                to={"/account/subscriptions"}
              >
                <i className="ci-user opacity-60 me-2"></i>My Subscriptions
              </Link>
              <Link
                className="dropdown-item d-flex align-items-center"
                to={"/account/packages"}
              >
                <i className="ci-coins opacity-60 me-2"></i>My Packages
              </Link>
              <Link
                className="dropdown-item d-flex align-items-center"
                to={"/account/favorites"}
              >
                <i className="ci-heart opacity-60 me-2"></i>Favorites
              </Link>

              <Link
                className="dropdown-item d-flex align-items-center"
                to={"/account/notifications"}
              >
                <i className="ci-bell opacity-60 me-2"></i>Notifications
              </Link>

              <Link
                className="dropdown-item d-flex align-items-center"
                onClick={disconnectWallet}
                to="#"
              >
                <i className="ci-sign-out opacity-60 me-2"></i>Sign out
              </Link>
            </div>
          </div>
        </div>
        <button
          className="btn btn-sm btn-accent rounded-1 ms-lg-4 ms-3"
          onClick={() => openModal()}
        >
          Create
        </button>
      </div>
      {showModal && <ModalCreate show={showModal} handleClose={closeModal} />}
    </>
  ) : (
    <>
      {width >= 980 && (
        <Button
          id="redline_getBrowser"
          display={{ base: "none" }}
          bg="#373f50"
          color="white"
          padding=".425rem 1rem"
          borderRadius="0.4375rem"
          borderColor="#373f50"
          fontSize="0.8125rem"
          _hover={{
            backgroundColor: "#1f293d",
            color: "white",
          }}
        >
          Connect your RED WALLET
        </Button>
      )}
    </>
  );
}
